import { mixpanelEvent } from '@/lib/mixpanels/mixpanel'
import { Button } from '@bookips/solvook-ui-library'
import { Typography, styled, useTheme } from '@mui/material'
import Link from 'next/link'
import Icon from '../icon/Icon'
import { NoticeIcon, Sizes } from '../icon/IconList'

const NotFoundEbook = () => {
	const theme = useTheme()
	return (
		<Box className="_not_found">
			<Typography
				variant="h6"
				sx={{ display: 'flex', flexDirection: 'column', gap: '8px', alignItems: 'center' }}>
				찾고 있는 교재가 없나요?
				<Typography variant="body2" color={theme.palette.text.secondary}>
					희망교재를 남겨주시면 입점시 알려드릴게요.
				</Typography>
			</Typography>
			<Link
				href={`https://docs.google.com/forms/d/e/1FAIpQLScde9dMB7vfNU4J4wPkkQ7XajPrydxzfi2Hzn4x2H8INs1AzQ/viewform`}
				target="_blank"
				onClick={() => {
					//mx-145 희망교재 신청하기 클릭시
					mixpanelEvent('[Univ] Request Button Clicked', {
						Position: '굿노트 대학교재 검색결과 리스트',
						URL: 'https://goodnotes.com/deeplink/marketplace',
						'Button Name': '희망교재 신청하기',
						'Button URL':
							'https://docs.google.com/forms/d/e/1FAIpQLScde9dMB7vfNU4J4wPkkQ7XajPrydxzfi2Hzn4x2H8INs1AzQ/viewform',
					})
				}}>
				<Button
					variant="solid"
					label={
						<>
							<Icon icon={NoticeIcon} size={Sizes.smallHalf} />
							{` 희망교재 신청하기`}
						</>
					}
				/>
			</Link>
		</Box>
	)
}

const Box = styled('li')({
	display: 'flex',
	flexDirection: 'column',
	alignItems: 'center',
	gap: '28px',
	justifyContent: 'center',
	height: '100%',
	width: '100%',
	padding: '32px',
})

export default NotFoundEbook
