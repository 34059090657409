import { AdBannerEmpty, useAdBannerQuery } from '@/components/Banner/AdBanner'
import { SideSheetStep, SideSheetSteps } from '@/components/SideSheets/GNBSideSheet'
import { BOOKS_LANDING, EBOOKS_LANDING, EBOOKS_UNIVERSITY_LANDING } from '@/settings/constant'
import { Divider, Typography } from '@mui/material'
import Link from 'next/link'
import { useCallback } from 'react'
import styled from 'styled-components'
import {
	MenuListContainer,
	SideSheetContentsContainer,
	SideSheetHeader,
	SideSheetUtils,
	StepDivider,
} from '.'
import { AdBanner } from '../Banner/AdBanner/AdBanner'
import { MenuColumn } from '../Category'
import { DepthItem } from '../Category/hooks/useCategoryPanelData'
import useCategorySideDepth from '../Category/hooks/useCategorySideDepth'
import {
	Box,
	BoxContainer,
	BoxTitle,
	ButtonBox,
	MenuLinkBox,
	RowContainer,
} from '../Category/index.style'
import Icon from '../icon/Icon'
import { GoodnoteIcon, Sizes } from '../icon/IconList'
import { useSideCategoryMenuStore } from './store/useCategoryMenuStore'

type StepProps = {
	setCurrentMenu: React.Dispatch<React.SetStateAction<DepthItem>>
	goToNextStep: (step: SideSheetStep) => void
	onClose: () => void
}

//SideSheetSteps.default
export const SideSheetDefaultStep = ({ goToNextStep, onClose }: StepProps) => {
	const {
		currentOneDepth,

		depthData,
	} = useCategorySideDepth()
	const { setState } = useSideCategoryMenuStore((state) => ({
		setState: state.setState,
	}))
	//카테고리 닫아주기 위한 이벤트
	const linkIsNewTab = (link: string) => {
		return link.includes('https://solvook.com')
	}
	const onClickMenu = useCallback(
		(menuId: string) => {
			setState('twoDepthId', menuId)
			goToNextStep(SideSheetSteps.menuList)
		},
		[setState, goToNextStep],
	)

	return (
		<SideSheetContentsContainer>
			<SideSheetContent>
				<SideSheetHeader
					title={'메뉴'}
					onClose={onClose}
					currentStep={SideSheetSteps.default}
				/>
				<SideSheetUtils />
				<AdBanner position={'moSide'} shape={'fullWidth'} />
				{depthData && (
					<MenuListContainer>
						<ColumnBox>
							<MenuLinkBox className="link-box-mobile">
								<RowContainer className="container-mobile">
									<BoxContainer>
										{depthData.origin?.depthItems.map((menu) => (
											<MenuColumn
												key={menu.id}
												data={menu}
												onColumnClick={() => {
													setState('currentDepth', menu.id)
													// setOneDepthId(menu.id)
												}}
												// isActive={oneDepthId === menu.id}
												isActive={currentOneDepth === menu.id}
											/>
										))}
									</BoxContainer>
									<Box>
										{/* 원뎁스 상대경로 링크 */}
										<ButtonBox className={'active'}>
											<Typography
												variant="subtitle2"
												sx={{
													display: 'flex',
													flexDirection: 'column',
													gap: '4px',
													alignItems: 'center',
													marginBottom: '8px',
													whiteSpace: 'nowrap',
												}}>
												<Icon icon={GoodnoteIcon} size={Sizes.small} />
												굿노트6 eBook
											</Typography>
											<Link href={EBOOKS_UNIVERSITY_LANDING}>
												<button>대학 교재</button>
											</Link>
										</ButtonBox>
										<ButtonBox>
											<Link href={EBOOKS_LANDING}>
												<button>중고등 교재</button>
											</Link>
										</ButtonBox>
										<Divider />
										<ButtonBox>
											<Link href={BOOKS_LANDING}>
												<button>배송 도서</button>
											</Link>
										</ButtonBox>
									</Box>
								</RowContainer>
							</MenuLinkBox>
							{depthData.two && ( //ex. 고등, 중등, 참고서, ebs
								<MenuLinkBox>
									<BoxTitle>{depthData.two?.name || ''}</BoxTitle>
									<RowContainer className="depth-one-container">
										<BoxContainer>
											{depthData.two.items.map((menu, idx) => (
												<MenuColumn
													key={menu.id}
													data={menu}
													onColumnClick={() => {
														onClickMenu(menu.id)
													}}
													isActive={false}
													suffix
												/>
											))}
										</BoxContainer>
									</RowContainer>
								</MenuLinkBox>
							)}
						</ColumnBox>
					</MenuListContainer>
				)}
			</SideSheetContent>
		</SideSheetContentsContainer>
	)
}

//SideSheetSteps.menuList
export const SideSheetMenuListStep = ({
	goToPrevStep,
	onClose,
}: {
	menu: DepthItem
	goToPrevStep: () => void
	onClose: () => void
}) => {
	const { depthData } = useCategorySideDepth()
	const { threeDepthIdInStore, setState, closeSideMenu } = useSideCategoryMenuStore((state) => ({
		threeDepthIdInStore: state.threeDepthId,
		setState: state.setState,
		closeSideMenu: state.closeSideMenu,
	}))

	return (
		<>
			<SideSheetHeader
				title={`${depthData.two?.name} - ${depthData.three.name}` || ''}
				onClose={onClose}
				currentStep={SideSheetSteps.menuList}
				goToPrevStep={goToPrevStep}
			/>
			<StepDivider />
			<MenuListContainer className="depth-two-container">
				<ColumnBox>
					<MenuLinkBox className="link-box-mobile _depth-two">
						<RowContainer className="container-mobile">
							<BoxContainer>
								{depthData.three?.items.map((item) => (
									<MenuColumn
										key={item.id}
										data={item}
										onColumnClick={() => {
											setState('threeDepthId', item.id)
										}}
										isActive={item.id === threeDepthIdInStore}
									/>
								))}
							</BoxContainer>
						</RowContainer>
					</MenuLinkBox>
					{depthData && ( //ex. 고등, 중등, 참고서, ebs
						<MenuLinkBox>
							<Link href={`/categories?id=${depthData.four?.id}`}>
								<BoxTitle>{depthData.four?.name || ''}</BoxTitle>
							</Link>
							<RowContainer className="depth-two-row-container">
								<BoxContainer>
									{depthData.four &&
										depthData.four.items.map((menu, idx) => (
											<Link key={menu.id} href={`/categories?id=${menu.id}`}>
												<MenuColumn
													key={menu.id}
													data={menu}
													onColumnClick={() => {
														// onClickMenu(menu.id)
													}}
													isActive={false}
													suffix
												/>
											</Link>
										))}
								</BoxContainer>
							</RowContainer>
						</MenuLinkBox>
					)}
				</ColumnBox>
			</MenuListContainer>
		</>
	)
}

//SideSheetSteps.adList
export const SideSheetAdListStep = ({ goToPrevStep, onClose }) => {
	const { noAdItems } = useAdBannerQuery({ position: 'globalMenu', subject: null })
	return (
		<>
			<SideSheetHeader
				title={'쏠북 소식'}
				onClose={onClose}
				currentStep={SideSheetSteps.adList}
				goToPrevStep={goToPrevStep}
			/>
			<StepDivider />
			<SideSheetAdListContainer>
				{noAdItems ? <AdBannerEmpty /> : <AdBanner position={'globalMenu'} />}
			</SideSheetAdListContainer>
		</>
	)
}

const SideSheetAdListContainer = styled.div`
	padding: 20px;
	height: calc(100% - 60px);
`

const ColumnBox = styled.div`
	display: flex;
	height: 100%;
`
const SideSheetContent = styled.div`
	display: flex;
	flex-direction: column;
	height: 100%;
`
