import React from 'react'
import styled from 'styled-components'
import Image from 'next/image'
import themeGet from '@styled-system/theme-get'

export interface SearchFilteredProductEmptyProps {}

export const SearchFilteredProductEmpty: React.FC<SearchFilteredProductEmptyProps> = ({}) => {
	return (
		<SearchFilteredProductEmptyWrapper>
			<Image
				src={'/static/assets/img-construction-01.svg'}
				alt={'icon'}
				width={90}
				height={90}
			/>
			<p>필터와 일치하는 상품을 찾을 수 없어요</p>
		</SearchFilteredProductEmptyWrapper>
	)
}
const SearchFilteredProductEmptyWrapper = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	background-color: #ffffff;
	height: 240px;
	width: 100%;
	p {
		color: ${themeGet('text.2', '#000000')};
		margin-top: 16px;
		font-size: 16px;
	}
`
