import { IRestfulApiService } from '@/lib/apiService'
import {
	GetLabByAuthorIdDTO,
	GetLabByAuthorIdResponseDTO,
	IQueryCommand,
} from '@bookips/solvook-api-shared'

export class GetLabByAuthorIdCommand
	implements IQueryCommand<GetLabByAuthorIdDTO, GetLabByAuthorIdResponseDTO>
{
	private apiService: IRestfulApiService

	constructor(apiService: IRestfulApiService) {
		this.apiService = apiService
	}

	execute(dto: GetLabByAuthorIdDTO): Promise<GetLabByAuthorIdResponseDTO> {
		const { author_id } = dto
		const url = `/labs/author/${author_id}`

		return this.apiService
			.fetch<GetLabByAuthorIdResponseDTO>(url, {
				method: 'GET',
			})
			.then((res) => res.data)
	}
}
