import React from 'react'
import styled from 'styled-components'
import Image from 'next/image'
import { themeGet } from '@styled-system/theme-get'
import { useRouter } from 'next/router'

export interface SearchProductEmptyProps {}

export const SearchProductEmpty: React.FC<SearchProductEmptyProps> = ({}) => {
	const { query } = useRouter()
	const { q: searchKeyword } = query
	const desc = [
		'띄어쓰기나 철자 등 입력하신 검색어를 다시 한 번 확인해주세요.',
		'찾으려는 교재의 특정 단어를 입력하면 검색결과의 정확도가 올라가요.',
		'특수문자는 제외하는 것이 좋아요.',
	]
	return (
		<SearchProductEmptyWrapper>
			<EmptyTitle smallType={false}>
				<span className={'keyword'}>"{searchKeyword}"</span> 에 대한
				<br /> 검색결과 <span className={'count'}>0</span>건
			</EmptyTitle>
			<EmptyIcon smallType={false}>
				<Image
					src="/static/assets/img-empty-new.svg"
					alt={'no data'}
					width={192}
					height={120}
				/>
			</EmptyIcon>
			<EmptyDesc>
				{desc.map((text, index) => {
					return (
						<li key={index}>
							<Image
								src={`/static/assets/ico-check-blue.svg`}
								alt={'check'}
								width={20}
								height={20}
							/>
							{text}
						</li>
					)
				})}
			</EmptyDesc>
		</SearchProductEmptyWrapper>
	)
}
const SearchProductEmptyWrapper = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	min-height: 240px;
	padding: 0;
	padding-top: 120px;
	padding-bottom: 100px;
	border-radius: 0;
	background-color: ${themeGet('background.1', '#F5F8FB')};
	@media (max-width: 1200px) {
		background-color: #fff;
		padding-top: 28px;
	}
`
const EmptyIcon = styled.div<{ smallType: boolean }>`
	img {
		width: ${({ smallType }) => (smallType ? '40px' : '192px')};
		@media (max-width: 1200px) {
			width: 128px;
		}
	}
`
const EmptyTitle = styled.p<{ smallType: boolean }>`
	font-size: ${({ smallType }) => (smallType ? '14px' : '24px')};
	font-weight: bold;
	color: #5f5f69;
	margin: 8px 0 0;
	line-height: 1.5;
	text-align: center;
	margin-bottom: 40px;
	@media (max-width: 1200px) {
		font-size: 16px;
		margin-bottom: 23px;
	}

	.keyword {
		color: ${themeGet('text.0', '#1B1B29')};
	}

	.count {
		color: ${themeGet('primary.0', '#1B1B29')};
	}
`
const EmptyDesc = styled.ul`
	display: flex;
	flex-direction: column;
	gap: 12px;
	margin-top: 40px;
	@media (max-width: 1200px) {
		margin-top: 16px;
		gap: 20px;
	}

	li {
		font-size: 16px;
		color: ${themeGet('text.2', '#1B1B29')};
		line-height: 1.5;
		display: flex;
		align-items: flex-start;
		@media (max-width: 1200px) {
			gap: 6px;
			flex-direction: column;
			align-items: center;
			justify-content: center;
			max-width: 210px;
			font-size: 14px;
			text-align: center;
		}

		img {
			margin-right: 10px;
			@media (max-width: 1200px) {
				margin-right: 0;
			}
		}
	}
`
