import { mixpanelEvent } from '@/lib/mixpanels/mixpanel'
import { styled } from '@mui/material'
import { useQuery } from '@tanstack/react-query'
import Image from 'next/image'
import Link from 'next/link'
import { useCallback } from 'react'
import { UniversityBannerItem, getUniversityAdBanner } from 'src/api/ebook'
import { Navigation } from 'swiper'
import 'swiper/css/navigation'
import { SwiperSlide } from 'swiper/react'
import { PaginationStyledSwiper } from '../Landing/MainBanner'

const BannerSlider = () => {
	//mixpanel 이벤트를 위한 함수
	const handleClick = useCallback((props: UniversityBannerItem) => {
		//mx-147 대학교재 홈 배너 클릭 시
		mixpanelEvent('[Univ] Banner Clicked', {
			Position: '대학교재 홈',
			'Banner Url': props.link,
		})
	}, [])

	const { data: bannerData } = useQuery(['universityHomeBanner'], () => getUniversityAdBanner())

	return (
		<AdBannerSection>
			<PaginationStyledSwiper
				navigation={true}
				scrollbar={false}
				modules={[Navigation]}
				// pagination={{ type: 'fraction' }}
				autoplay={{ delay: 4000 }}
				loop={true}>
				{bannerData?.items?.map((banner, idx) => {
					return (
						<SwiperSlide key={`item-${idx}`}>
							<AdBannerBgBox bgColor={banner.bgColor}>
								<Link
									href={banner.link}
									target={'_blank'}
									onClick={() => handleClick(banner)}>
									<Image
										width={1200}
										height={72}
										className={'show_w'}
										src={
											banner.imageUrlPc ||
											'/static/assets/university_mock_banner.png'
										}
										alt={`${idx}-img`}
									/>
									<Image
										width={1200}
										height={72}
										className={'show_t'}
										src={
											banner.imageUrlTablet ||
											'/static/assets/university_mock_banner_t.png'
										}
										alt={`${idx}-img`}
									/>
									<Image
										width={800}
										height={72}
										className={'show_m'}
										src={
											banner.imageUrlMobile ||
											'/static/assets/university_mock_banner_m.png'
										}
										alt={`${idx}-img`}
									/>
								</Link>
							</AdBannerBgBox>
						</SwiperSlide>
					)
				})}
			</PaginationStyledSwiper>
		</AdBannerSection>
	)
}

const AdBannerSection = styled('section')`
	.show_m {
		display: none;
		flex-shrink: 0;
	}

	.show_t {
		display: none;
		flex-shrink: 0;
	}

	.swiper-button-prev {
		top: 53%;
		left: calc((100% - 1200px) / 2);
		padding-right: 3px;
		box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.08), 0px 1px 4px 0px rgba(0, 0, 0, 0.04);
	}
	.swiper-button-next {
		top: 53%;
		right: calc((100% - 1200px) / 2);
		padding-left: 3px;
		box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.08), 0px 1px 4px 0px rgba(0, 0, 0, 0.04);
	}
	.swiper-pagination {
		display: none;
	}

	@media (max-width: 1200px) {
		.show_w {
			display: none;
		}

		.show_t {
			display: block;
		}
	}
	@media (max-width: 768px) {
		.show_m {
			display: block;
		}

		.show_t {
			display: none;
		}
	}
`

const AdBannerBgBox = styled('div')<{ bgColor: string }>(({ bgColor, theme }) => ({
	backgroundColor: bgColor,
	width: '100%',
	textAlign: 'center',
	padding: 0,
	img: {
		maxWidth: '1200px',
		margin: '0 auto',
	},

	'@media (max-width: 1200px)': {
		img: {
			maxWidth: '760px',
		},
	},

	'@media (max-width: 768px)': {
		display: 'flex',
		justifyContent: 'center',

		img: {
			maxWidth: '360px',
		},
	},
}))

export default BannerSlider
