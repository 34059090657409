import Icon from '@/components/icon/Icon'
import {
	ArrowDownOutlined,
	ArrowUp,
	Cart,
	Hamburger,
	Library,
	Sizes,
	Studio,
	User,
} from '@/components/icon/IconList'
import { useSideCategoryMenuStore } from '@/components/SideSheets/store/useCategoryMenuStore'
import { GuidTooltip } from '@/components/tooltip/GuidTooltip'
import apiService from '@/lib/apiService/apiService'
import { useAuthStore } from '@/lib/auth/AuthStore'
import { mixpanelEvent } from '@/lib/mixpanels/mixpanel'
import { MyClickedProperty } from '@/lib/mixpanels/mixpanelType'
import { redirect } from '@/lib/next-utils'
import { LogoutCommand } from '@/modules/accounts/commands/logoutCommand'
import { GetLabByAuthorIdCommand } from '@/modules/lab/commands/getLabByAuthorIdCommand'
import {
	HOME_PAGE,
	LIBRARIES_INDEX_PAGE,
	MYPAGE_PAGE,
	SHOP_CART_PAGE,
	SOLVOOK_WRITE_STUDIO, STUDIO_TITLE,
} from '@/settings/constant'
import { Dropdown, MenuProps } from 'antd'
import Link from 'next/link'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useSavedUserSourceStore } from 'src/store/main'
import { isInWebview } from 'src/utils/misc'
import { useTheme } from 'styled-components'
import { useLocalStorage } from 'usehooks-ts'
import { utilItems_default } from './categoryItems'
import { LoginMenuWrapper, MenuHam, MypageMenu } from './GNB.style'

interface LoginMenuProps {
	cartItemsCount: number | undefined
	children?: React.ReactNode
}

const LoginMenu: React.FC<LoginMenuProps> = ({ cartItemsCount }) => {
	const theme = useTheme()
	const authStore = useAuthStore()
	const [isVisibleTooltip, setVisibleTooltip] = useLocalStorage(`studio-tooltip`, false)
	const [isAuthor, setIsAuthor] = useState(false)
	const [isMypageDropdown, setIsMypageDropdown] = useState(false)
	const [labName, setLabName] = useState(null)

	const { updateUserMeta } = useSavedUserSourceStore(({ updateUserMeta }) => ({
		updateUserMeta,
	}))
	const { closeSideMenu, openMobileSideMenu } = useSideCategoryMenuStore()
	const { logoutCommand, isUseFooter } = useMemo(
		() => ({
			logoutCommand: new LogoutCommand(apiService),
			isUseFooter: !isInWebview(),
		}),
		[apiService],
	)
	const utilItems = useMemo(
		() => [
			...(isAuthor
				? [
						{
							key: '3',
							label: (
								<Link
									onClick={() =>
										mixpanelEvent('MyClicked', {
											'Menu Category': 'authorHome',
										} as MyClickedProperty)
									}
									href={`/@${labName}`}>
									나의 연구소
								</Link>
							),
						},
				  ]
				: []),

			...utilItems_default,
		],
		[isAuthor, labName, utilItems_default],
	)
	//저자홈
	const getLabByAuthorIdCommand = useMemo(
		() => new GetLabByAuthorIdCommand(apiService),
		[apiService],
	)

	const { postProcessForKakao } = useSignout()
	const onLogout = useCallback(async () => {
		if (authStore.user.providerId === 'kakao') {
			postProcessForKakao()
		}

		authStore.logout()
		await logoutCommand.execute()

		redirect(HOME_PAGE)
	}, [authStore, logoutCommand, postProcessForKakao])
	const handleLogout = () => {
		updateUserMeta({
			mainSubject: null,
			job: null,
		})
		closeSideMenu()
		onLogout()
	}
	const handleMenuClick: MenuProps['onClick'] = useCallback(
		(e) => {
			mixpanelEvent('GNB Button Clicked', {
				//mx-16 gnb 버튼 클릭 시
				'Button Name': '마이페이지',
			})
			if (e.key === 'logout') {
				mixpanelEvent('GNB Button Clicked', {
					//mx-16 gnb 버튼 클릭 시
					'Button Name': '로그아웃',
				})
				handleLogout()
			}
			// handleDimClick()
		},
		[onLogout],
	)
	const fetchLabData = async () => {
		try {
			const response = await getLabByAuthorIdCommand.execute({
				author_id: authStore.user.id,
			})
			setLabName(response.lab.lab_name)
			setIsAuthor(true)
		} catch (e) {}
	}

	useEffect(() => {
		if (authStore.isLoggedIn && authStore?.user?.flags?.is_use_lab) {
			fetchLabData()
		} else setIsAuthor(false)
	}, [authStore.isLoggedIn, authStore.user])

	return (
		<LoginMenuWrapper count={cartItemsCount} className={'gnb-login-menu'}>
			{authStore.isLoggedIn && (
				<>
					<Link
						href={LIBRARIES_INDEX_PAGE}
						onClick={() => {
							mixpanelEvent('GNB Button Clicked', {
								'Button Name': '자료 보관함',
							})
						}}
						passHref
						className="link-to-libraries">
						<Icon
							icon={Library}
							size={Sizes.medium}
							color={theme.new.colors.sol_gray_700}
						/>
						<p>자료 보관함</p>
					</Link>
					<Link
						onClick={() => {
							mixpanelEvent('GNB Button Clicked', {
								'Button Name': '스튜디오',
							})
							setVisibleTooltip(true)
						}}
						className={'studio-link'}
						href={SOLVOOK_WRITE_STUDIO}
						passHref>
						<Icon
							icon={Studio}
							size={Sizes.medium}
							color={theme.new.colors.sol_gray_700}
						/>
						<p>{STUDIO_TITLE}</p>
						<GuidTooltip isVisibleTooltip={!isVisibleTooltip} placement="bottom">
							자료 편집 3분컷 체험
						</GuidTooltip>
					</Link>
					<Link
						onClick={() => {
							mixpanelEvent('GNB Button Clicked', {
								//mx-16 gnb 버튼 클릭 시
								'Button Name': '장바구니',
							})
						}}
						className={'cart-link'}
						href={SHOP_CART_PAGE}
						passHref>
						<Icon
							icon={Cart}
							size={Sizes.medium}
							color={theme.new.colors.sol_gray_700}
						/>
						<p>장바구니</p>
					</Link>
					<MypageMenu>
						<Dropdown
							onOpenChange={(open) => setIsMypageDropdown(open)}
							trigger={['click']}
							menu={{
								items: utilItems,
								onClick: handleMenuClick,
							}}
							overlayClassName={'dropdown util_dropdown'}
							placement={'bottom'}>
							<Link
								href={MYPAGE_PAGE}
								passHref
								onClick={(e) => {
									e.preventDefault()
								}}>
								<div className="stack-row">
									<div className="stack-centered">
										<Icon
											icon={User}
											size={Sizes.medium}
											color={theme.new.colors.sol_gray_800}
										/>
										<p>마이페이지</p>
									</div>
									{isMypageDropdown ? (
										<Icon icon={ArrowUp} size={Sizes.small} />
									) : (
										<Icon icon={ArrowDownOutlined} size={Sizes.small} />
									)}
								</div>
							</Link>
						</Dropdown>
					</MypageMenu>
				</>
			)}

			<MenuHam>
				<button onClick={() => openMobileSideMenu()} className={'mobile-menu-icon'}>
					<Icon
						icon={Hamburger}
						size={Sizes.medium}
						color={theme.new.colors.sol_gray_900}
					/>
					<p>메뉴</p>
				</button>
			</MenuHam>
			{/* {children} */}
		</LoginMenuWrapper>
	)
}

export default LoginMenu

export const useSignout = () => {
	const postProcessForKakao = useCallback(() => {
		const searchParams = new URLSearchParams()
		searchParams.set('client_id', process.env.NEXT_PUBLIC_KAKAO_REST_API_KEY)
		searchParams.set(
			'logout_redirect_uri',
			document.location.origin.concat('/login/kakao-logout'),
		)

		window.open(
			'https://kauth.kakao.com/oauth/logout?'.concat(searchParams.toString()),
			'solvook-kakao-logout',
			'width=500, height=700, scrollbars=no, resizable=no',
		)
	}, [])

	return { postProcessForKakao }
}
