import { useBooksQuery } from '@/hooks/useBooksQuery'
import { mixpanelEvent } from '@/lib/mixpanels/mixpanel'
import { styled, Typography, useTheme } from '@mui/material'
import Image from 'next/image'
import { useRouter } from 'next/router'
import MainHeaderImage from 'public/static/images/main-university.jpg'
import { useState } from 'react'
import { SearchTextField } from '../common/TextField/searchTextField'

const SearchBook = () => {
	const theme = useTheme()
	const { handleSearch, keyword, searchParams } = useBooksQuery()
	const [value, setValue] = useState(keyword)

	const { push, pathname } = useRouter()
	const onSubmit = () => {
		handleSearch(value)

		searchParams.set('q', value)
		const page = searchParams.get('page')
		if (page) {
			searchParams.delete('page')
			push({ pathname, query: searchParams.toString() }, undefined, {
				shallow: true,
				scroll: true,
			})
		}

		//mx-141 검색어 입력
		mixpanelEvent('[Univ] Searched from Search-bar', {
			'Search Term': value,
		})
	}
	const onClear = () => {
		setValue('')
		handleSearch('')
	}
	const onChange = (e) => {
		setValue(e.target.value)
	}

	return (
		<SearchHeader>
			<div className="image-area">
				<Image
					src={MainHeaderImage}
					fill
					style={{ objectFit: 'cover' }}
					alt="굿노트 eBook 공식 교재 찾기 이미지"
				/>
			</div>
			<div className="typo-area">
				<SearchTextTitle>굿노트 eBook 공식 교재 찾기</SearchTextTitle>
				<SearchTextField
					onSearchClick={() => {
						onSubmit()
					}}
					onClear={onClear}
					placeholder={'교재명을 입력해주세요.'}
					value={value}
					onChange={onChange}
					onSubmit={(e) => {
						onSubmit()
					}}
				/>
			</div>
		</SearchHeader>
	)
}

const SearchHeader = styled('section')(({ theme }) => ({
	position: 'relative',
	'.image-area': {
		position: 'relative',
		width: '100%',
		height: '240px',

		'&::after': {
			content: '""',
			position: 'absolute',
			width: '100%',
			height: '240px',
			top: 0,
			left: 0,
			background:
				'linear-gradient(0deg, rgba(28, 31, 46, 0.60) 0%, rgba(28, 31, 46, 0.75) 100%)',

			[theme.breakpoints.down('sm')]: {
				height: '200px',
			},
		},
		[theme.breakpoints.down('sm')]: {
			height: '200px',
		},
	},
	'.typo-area': {
		position: 'absolute',
		top: '50%',
		left: '50%',
		transform: 'translate(-50%, -50%)',
		display: 'flex',
		flexDirection: 'column',
		gap: '24px',
		width: '100%',
		maxWidth: '480px',
		textAlign: 'center',
		padding: '0 20px',
		'.search-request-form:not(.active)': {
			backgroundColor: 'rgba(255, 255, 255, 0.90)',
		},
	},
}))

const SearchTextTitle = styled(Typography)(({ theme }) => ({
	...theme.typography.h4,
	color: theme.palette.blackAndWhite.white,

	[theme.breakpoints.down('sm')]: {
		...theme.typography.h5,
	},
}))
export default SearchBook
