import { useQuery } from '@tanstack/react-query'
import { otherApiClient } from '.'

// 상품 정보
export interface BooksCartProduct {
	id: string // 상품 ID
	sourceId: string // 상품과 연결된 Source id
	sourceType: string // 상품과 연결된 Source type
	title: string // 상품명
	thumbImg: string // 썸네일 이미지
	author: string // 저자
	price: string // 출판사
	originPrice?: number // 정가
	subject: string // 과목
	isbn13: string // isbn (알라딘 상품인 경우에만 내려줌), 13자리
}

// 상품의 요금제 정보
export interface BooksProductVariant {
	id: string // 상품의 요금제 ID
	productId: string // 상품 ID
	description: string // 상품의 요금제 설명
	sku: string // 상품의 요금제 일련번호..?
	plan: string // 상품의 요금제 이름
	price: number // 상품의 요금제 가격
}

export interface BooksCartItem {
	cartId: string // 카트 ID
	id: string // 장바구니 안의 각각의 아이템 ID
	productId: string // 아이템과 연결된 상품 ID
	productVariantId: string // 아이템과 연결된 상품의 금액(요금제) ID - 주로 cartType 이 separate인 경우에만 유효한 값
	product: BooksCartProduct
	productVariant?: BooksProductVariant
	quantity: number
	subtotal?: number
}

// 카트 타입 - separate: 단건결제 / aladinbook : 알라딘 / ebook : 굿노트 / relation : 연관교재
export type BooksCartType = 'separate' | 'aladinbook' | 'ebook' | 'relation'

export interface GetBooksCartRequest {
	cartType: BooksCartType
}
export interface GetBooksCartResponse {
	id: string // 카트 ID
	cartType: BooksCartType
	uid: string // 카트 UID
	lineItemsCount: number // 카트의 아이템 총 갯수
	userId: number // 카트를 가지고 있는 userId
	lineItems: Array<BooksCartItem>
}
export const fetchBooksCart = async (params: GetBooksCartRequest) => {
	const { data } = await otherApiClient.market<GetBooksCartResponse>(`/carts/current`, { params })
	return data
}
export const fetchBooksCartAllForCounting = async () => {
	const caryTypes: Array<BooksCartType> = ['aladinbook', 'ebook']
	const results = await Promise.all(
		caryTypes.map((cartType) =>
			otherApiClient.market<GetBooksCartResponse>(`/carts/current`, { params: { cartType } }),
		),
	)
	return results.reduce((acc, cur) => acc + cur.data.lineItems.length, 0)
}

export interface PostBooksCartRequest extends GetBooksCartRequest {
	cartItems: Array<
		Partial<Pick<BooksCartItem, 'productVariantId' | 'quantity'>> &
			Pick<BooksCartItem, 'productId'>
	>
}
export const postBooksCartItem = async (params: PostBooksCartRequest) => {
	const { data } = await otherApiClient.market.post<GetBooksCartResponse>(
		`/carts/current/items`,
		params,
	)
	return data
}

export interface DeleteBooksCartRequest {
	lineItemId: BooksCartItem['id']
}
export const deleteBooksCart = async ({ lineItemId }: DeleteBooksCartRequest) => {
	const { data } = await otherApiClient.market.delete<GetBooksCartResponse>(
		`/carts/current/items/${lineItemId}`,
	)
	return data
}

export interface PutBooksCartRequest extends DeleteBooksCartRequest {
	quantity: BooksCartItem['quantity']
}
export const putBooksCart = async ({ lineItemId, ...params }: PutBooksCartRequest) => {
	const { data } = await otherApiClient.market.put<GetBooksCartResponse>(
		`/carts/current/items/${lineItemId}`,
		params,
	)
	return data
}
export const getCurrentCartQuery = ({
	enabled,
	params,
}: {
	enabled: boolean
	params: GetBooksCartRequest
}) => {
	return useQuery(['currentCart'], () => fetchBooksCart(params), { enabled })
}
