import { useEffect, useState } from 'react'
import { isBrowser } from '../next-utils'

function getWindowDimensions() {
	if (!isBrowser || !window) return
	const { innerWidth: width, innerHeight: height } = window
	return {
		width,
		height,
	}
}

export default function useWindowDimensions() {
	const [windowDimensions, setWindowDimensions] = useState({ width: 0, height: 0 })

	useEffect(() => {
		function handleResize() {
			setWindowDimensions(getWindowDimensions())
		}

		if (isBrowser) {
			handleResize() // call once to set initial dimensions
			window.addEventListener('resize', handleResize)
		}

		return () => {
			if (isBrowser) {
				window.removeEventListener('resize', handleResize)
			}
		}
	}, [])

	return {
		...windowDimensions,
		isMobile: windowDimensions.width < 769,
		isTablet: windowDimensions.width < 1201,
	}
}
