import useLoginRedirect, { REDIRECT_QUERY } from '@/hooks/useLoginRedirect'
import { REGISTRATION_PAGE } from '@/settings/constant'
import { Button, ConfirmDialog } from '@bookips/solvook-ui-library'
import { useRouter } from 'next/router'
import { useCallback } from 'react'
import styled from 'styled-components'
import LoginDialog from '../../store/loginDialog'

type CheckLoginDialogProps = {
	open: boolean
	onClose: () => void
}

const CheckLoginDialog = ({ open, onClose }: CheckLoginDialogProps) => {
	const router = useRouter()
	const { loginPath } = useLoginRedirect()
	const { closeLoginDialog } = LoginDialog()
	const currentPathname = router.asPath

	const redirectLoginPage = useCallback(() => {
		closeLoginDialog()
		router.push(loginPath)
	}, [router, loginPath])

	const redirectSignUpPage = useCallback(() => {
		closeLoginDialog()
		router.push(`${REGISTRATION_PAGE}?${REDIRECT_QUERY}=${currentPathname}`)
	}, [router, currentPathname])

	return (
		<ConfirmDialog
			open={open}
			closeDialog={onClose}
			title="로그인이 필요해요."
			content={<p>로그인 혹은 회원가입을 진행해주세요.</p>}
			footer={
				<ButtonBox>
					<Button variant="soft" label="로그인" onClick={redirectLoginPage}></Button>
					<Button variant="solid" label="회원가입" onClick={redirectSignUpPage}></Button>
				</ButtonBox>
			}
		/>
	)
}

const ButtonBox = styled.div`
	display: flex;
	gap: 8px;
	align-items: center;
	justify-content: end;
	margin-top: 28px;

	> button {
		font-size: 16px;
		padding: 12px 20px;
		line-height: 1.5;
	}
`

export default CheckLoginDialog
