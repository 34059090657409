import { PassCategory, UseTicketProductInfo } from 'src/api/membership'
import { CurriculumsWithString, SubjectsWithString } from 'src/api/product'

export interface SubscribeInformationProps extends UseTicketProductInfo {
	expiredAt?: string
}

export const usePassInfoFormatting = (passInfo: SubscribeInformationProps) => {
	const subjectList = passInfo.availableSubjects
		.map((item) => SubjectsWithString[item])
		.join(', ')
	const periodDescription = () => {
		return `${passInfo.period}일`
	}
	const curriculumList = passInfo.availableCurriculums
		.map((item) => CurriculumsWithString[item])
		.join(', ')
	const hasUnavailableAuthor = !!passInfo.unavailableAuthorNames
	const authorList = hasUnavailableAuthor ? '' : passInfo.availableAuthorNames.join(', ')
	const unavailableAuthorList = hasUnavailableAuthor
		? passInfo.unavailableAuthorNames.join(', ')
		: ''
	const hasUnavailablePublisher = !!passInfo.unavailablePublisherNames
	const publisherList = hasUnavailablePublisher ? '' : passInfo.availablePublisherNames.join(', ')

	const unavailablePublisherList = hasUnavailablePublisher
		? passInfo.unavailablePublisherNames.join(', ')
		: ''
	const descriptionList = passInfo.description ? passInfo.description.split('\n') : []
	const isSolvookplus = passInfo.category === PassCategory.Solvookplus

	return {
		id: passInfo.id,
		title: passInfo.title,
		subjectCode: passInfo.subjectCode,
		price: passInfo.price,
		period: passInfo.period,
		// remainDay: pass.userPassInfo ? pass.userPassInfo.remainDay : null,
		expiredAt: passInfo.expiredAt,
		// startedAt: startedAt(),
		availableMinPrice: passInfo.availableMinPrice,
		availableMaxPrice: passInfo.availableMaxPrice,
		availableCount: passInfo.availableCount,
		subjectList,
		periodDescription: periodDescription(),
		curriculumList,
		authorList,
		publisherList,
		descriptionList,
		// remainCount: pass.userPassInfo ? pass.userPassInfo.remainCount : null,
		isSolvookplus,
		isNoPriceLimit: !!!passInfo.availableMaxPrice,
		isNoCountLimit: passInfo.availableCount === 999999 || !!!passInfo.availableCount,
		hasUnavailablePublisher,
		hasUnavailableAuthor,
		unavailableAuthorList,
		unavailablePublisherList,
		category: passInfo.category,
	}
}
