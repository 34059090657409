import Icon from '@/components/icon/Icon'
import { Close, Sizes } from '@/components/icon/IconList'
import Modal from '@/components/modal/Modal'
import { useAuthStore } from '@/lib/auth/AuthStore'
import { mixpanelEvent } from '@/lib/mixpanels/mixpanel'
import { numberFormatter } from '@/lib/utils'
import useWindowDimensions from '@/lib/web-ui/useWindowDimensions'
import { PASSES_LANDING, PASSES_ORDER } from '@/settings/constant'
import { Divider } from 'antd'
import { useRouter } from 'next/router'
import Sheet from 'react-modal-sheet'
import styled from 'styled-components'
import { SourceTypeWithString } from '../../api/product'
import LoginDialog from '../../store/loginDialog'
import usePassStore from '../../store/pass'
import FilledButton from '../common/Button/FilledButton'

const PassInfoSheet = styled(Sheet)<{ isOpen: boolean }>`
	background-color: rgba(28, 31, 46, 0.5);
	${(props) => !props.isOpen && 'background-color: transparent;'}
`
export const PassInfoModal = () => {
	const { isMobile } = useWindowDimensions()
	const isPassInfoModal = usePassStore((state) => state.isPassInfoModal)
	const closePassInfoModal = usePassStore((state) => state.closePassInfoModal)

	//모바일의 경우 바텀시트
	if (!isMobile)
		return (
			<PassInfoModalWrapper
				depth={1008}
				open={isPassInfoModal}
				onClose={closePassInfoModal}
				disableOutsideClick={false}>
				<PassInfoModalContents />
			</PassInfoModalWrapper>
		)
	return (
		<PassInfoSheet isOpen={isPassInfoModal} onClose={closePassInfoModal} disableDrag={true}>
			<Sheet.Container>
				<Sheet.Content>
					<PassInfoModalContents />
				</Sheet.Content>
			</Sheet.Container>
		</PassInfoSheet>
	)
}
const PassInfoModalContents = () => {
	const router = useRouter()
	const { isLoggedIn } = useAuthStore()
	const { openLoginDialog } = LoginDialog()

	const setIsPassInfoModal = usePassStore((state) => state.closePassInfoModal)
	const selectedPass = usePassStore((state) => state.selectedPass)
	const purchasePass = usePassStore((state) => state.purchasePass)

	const isOrderPage = router.pathname.startsWith(PASSES_LANDING) //구매페이지 일경우
	const isPassPage = router.pathname === PASSES_LANDING //이용권 페이지 일경우

	const onPurchase = () => {
		mixpanelEvent('Purchase Button Clicked', {
			'Product Categories': 'pass',
			'Product ID': purchasePass.id,
			'Product Title': purchasePass.title,
			'Product Price': purchasePass.price,
		})
		if (!isLoggedIn) {
			openLoginDialog()
			setIsPassInfoModal()
			return
		}
		router.push(`${PASSES_ORDER}?id=${purchasePass.id}`)
		setIsPassInfoModal()
	}
	if (!selectedPass) return null
	return (
		<PassInfoModalContainer>
			<CloseButton onClick={setIsPassInfoModal} />
			<main>
				<h4>{selectedPass.title}</h4>
				<div className={'pass-summary'}>
					{isOrderPage ? (
						<>
							<dl>
								<dt>총 사용 가능 횟수 :</dt>
								{selectedPass.isNoCountLimit ? (
									<dd>조건 내 무제한 사용</dd>
								) : (
									<dd>{selectedPass.availableCount}회</dd>
								)}
							</dl>
							<dl>
								<dt>사용 기간 :</dt>
								<dd>
									결제일로부터 {selectedPass.periodDescription}간
									{/*({selectedPass.period}일)*/}
								</dd>
							</dl>
						</>
					) : (
						<>
							<dl>
								<dt>
									<b>남은 사용 횟수 :</b>
								</dt>
								<dd>
									{selectedPass.isNoCountLimit ? (
										<b>조건 내 무제한 사용</b>
									) : (
										<b> {selectedPass.remainCount ?? 0}회</b>
									)}
								</dd>
							</dl>
							{!selectedPass.isNoCountLimit && (
								<dl>
									<dt>총 사용 가능 횟수 :</dt>
									<dd>{selectedPass.availableCount}회</dd>
								</dl>
							)}
							<dl>
								{/*<dt>남은 사용 기간 :</dt>*/}
								<dd>
									남은 사용 기간 : {selectedPass.remainDay}일 (
									{selectedPass.startedAt} ~ {selectedPass.expiredAt})
								</dd>
							</dl>
						</>
					)}
				</div>
				<Divider />
				<div className={'pass-range'}>
					<p>이용권 사용범위</p>
					<dl>
						<dt>과목</dt>
						<dd>{selectedPass.subjectList}</dd>
					</dl>
					<dl>
						<dt>과정</dt>
						<dd>{selectedPass.curriculumList}</dd>
					</dl>
					<dl>
						{selectedPass.hasUnavailableAuthor ? (
							<>
								<dt>제외 저자</dt>
								<dd>{selectedPass.unavailableAuthorList}</dd>
							</>
						) : (
							<>
								<dt>저자</dt>
								<dd>{selectedPass.authorList}</dd>
							</>
						)}
					</dl>
					<dl>
						{selectedPass.hasUnavailablePublisher ? (
							<>
								<dt>제외 출판사</dt>
								<dd>{selectedPass.unavailablePublisherList}</dd>
							</>
						) : (
							<>
								<dt>출판사</dt>
								<dd>{selectedPass.publisherList}</dd>
							</>
						)}
					</dl>
					<dl>
						<dt>가격</dt>
						{selectedPass.isNoPriceLimit ? (
							<dd>없음</dd>
						) : (
							<dd>
								{numberFormatter(selectedPass.availableMinPrice)}원~
								{numberFormatter(selectedPass.availableMaxPrice)}원 사이의 수업자료
							</dd>
						)}
					</dl>
					<dl>
						<dt>상품 종류</dt>
						<dd>{SourceTypeWithString['handout']}</dd>
					</dl>
				</div>
				<Divider />
				<div className={'pass-detail'}>
					<p>이용권 상세정보</p>
					<ul>
						{selectedPass.descriptionList.map((des, idx) => (
							<li key={'description-' + idx}>{des}</li>
						))}
					</ul>
				</div>
			</main>
			<FooterContainer>
				<ModalFilledButton
					onClick={() => {
						mixpanelEvent('Guide Button Clicked', {
							'Guide Categories': 'Product Detail',
							'Product ID': selectedPass.id,
							'Product Title': selectedPass.title,
							'Guide URL':
								'https://solvookguide.oopy.io/78b924d5-140f-4475-bd9b-d13d99bedb2b',
						})
						window.open(
							'https://solvookguide.oopy.io/78b924d5-140f-4475-bd9b-d13d99bedb2b',
							'_blank',
						)
					}}
					type="text"
					variant="outlined">
					이용방법 보기
				</ModalFilledButton>
				{isPassPage ? (
					<ModalFilledButton onClick={onPurchase} type="text" variant="solid">
						구매하기
					</ModalFilledButton>
				) : (
					<ModalFilledButton onClick={setIsPassInfoModal} type="text" variant="solid">
						확인
					</ModalFilledButton>
				)}
			</FooterContainer>
		</PassInfoModalContainer>
	)
}
const CloseButton = ({ onClick }: { onClick: () => void }) => {
	return (
		<CloseButtonContainer onClick={onClick}>
			<Icon icon={Close} size={Sizes.medium} />
		</CloseButtonContainer>
	)
}
const CloseButtonContainer = styled.button`
	all: unset;
	position: absolute;
	border: none;
	display: block;
	background: transparent;
	top: 28px;
	right: 28px;
	font-size: 0;
	width: 24px;
	height: 24px;
	cursor: pointer;
`
const PassInfoModalWrapper = styled(Modal)`
	padding: 72px 0;

	> div {
		border-radius: 8px;
		display: flex;
	}
`
export const PassInfoModalContainer = styled.div`
	main {
		&::-webkit-scrollbar {
			width: 5px;
			background-color: transparent;
		}

		&::-webkit-scrollbar-thumb {
			border-radius: 32px;
			background: ${(props) => props.theme.new.colors.sol_gray_300};
		}

		height: auto;
		flex-grow: 1;
		padding: 28px;
		overflow-y: auto;
		overflow-x: hidden;
		width: 520px;

		h4 {
			font-size: 20px;
			font-weight: 700;
			line-height: 1.5;
			letter-spacing: -0.2px;
			margin-bottom: 8px;
		}

		p {
			color: ${(props) => props.theme.new.colors.sol_gray_700};
			font-size: 16px;
			font-weight: 600;
			line-height: 1.5;
			letter-spacing: -0.16px;
			margin-bottom: 12px;
		}

		dl {
			display: flex;
			flex-wrap: wrap;
			margin-bottom: 8px;

			dt,
			dd {
				font-size: 16px;
				line-height: 1.5;
				letter-spacing: -0.16px;
				margin-bottom: 0;

				b {
					color: ${(props) => props.theme.new.colors.sol_blue_500};
				}
			}
		}

		.pass-summary {
			dl {
				dd {
					margin-left: 4px;
				}

				dt,
				dd {
					color: ${(props) => props.theme.new.colors.sol_gray_500};
				}
			}
		}

		.pass-range {
			dl {
				dt {
					color: ${(props) => props.theme.new.colors.sol_gray_400};
					width: 100px;
				}

				dd {
					display: flex;
					flex: 1;
					color: ${(props) => props.theme.new.colors.sol_gray_600};
				}
			}
		}

		.pass-detail {
			ul {
				li {
					color: ${(props) => props.theme.new.colors.sol_gray_500};
					font-size: 16px;
					line-height: 1.5;
					letter-spacing: -0.16px;
					list-style: disc;
					margin-inline-start: 18px;
					margin-bottom: 4px;
					white-space: break-spaces;
				}
			}
		}
	}

	.ant-divider {
		margin: 28px 0;
	}

	@media (max-width: 768px) {
		main {
			padding: 28px 20px;
			max-height: calc(var(--vh, 1vh) * 100 - 88px - 38px);
			width: unset;

			.pass-detail {
				ul {
					li {
						font-size: 14px;
					}
				}
			}
		}
	}
`
const FooterContainer = styled.div`
	display: flex;
	position: sticky;
	bottom: 0;
	background-color: ${(props) => props.theme.new.colors.white};
	padding: 20px 28px;
	gap: 8px;
	border-radius: 8px;

	.ant-btn {
		width: 100%;
	}

	@media (max-width: 768px) {
		padding: 20px;
	}
`

const ModalFilledButton = styled(FilledButton)`
	padding: 12px 20px;
	font-size: 16px;
`
const ModalFooter = styled.div`
	display: flex;
	gap: 8px;
	@media (max-width: 768px) {
		gap: 8px;
		.ant-btn {
			margin-inline-start: 0px !important;
		}
	}
`
