import { mixpanelEvent } from '@/lib/mixpanels/mixpanel'
import { Typography, styled } from '@mui/material'
import Link from 'next/link'
import Icon from '../icon/Icon'
import { GoodnoteLogoAndText, Sizes } from '../icon/IconList'

export const GoodnotesOfficialLogo = ({ className }: { className?: string }) => {
	return (
		<Link
			href={`https://goodnotes.com/deeplink/marketplace`}
			target="_blank"
			rel="noopener noreferrer"
			onClick={() => {
				//mx-144 logo 클릭시
				mixpanelEvent('Logo Clicked', {
					Type: 'Goodnotes',
					URL: 'https://goodnotes.com/deeplink/marketplace',
				})
			}}>
			<LogoBox className={className}>
				<Icon
					icon={GoodnoteLogoAndText}
					size={className ? Sizes.xmedium : Sizes.mediumLarge}
				/>
				<OfficialText>공식파트너샵</OfficialText>
			</LogoBox>
		</Link>
	)
}

export const GoodnotesOfficialMobileLogo = () => {
	return (
		<OffialLogoBox>
			<GoodnotesOfficialLogo className="official-mobile-top" />
		</OffialLogoBox>
	)
}

// 모바일에서는 gnb 로고 h1 안에서 위로 올라가야하기 때문에, 정의한 스타일 입니다.
export const OffialLogoBox = styled('div')(({ theme }) => ({
	display: 'none',

	[theme.breakpoints.down('sm')]: {
		display: 'flex',
		width: '100%',
		padding: '8px 10px',
		justifyContent: 'center',
		backgroundColor: theme.palette.blue[0],

		'.official-mobile-top': {
			display: 'flex',
			alignItems: 'center',

			svg: {
				width: '101px',
			},
		},
	},
}))

const LogoBox = styled('div')(({ theme }) => ({
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center',
	gap: '8px',

	[theme.breakpoints.down('sm')]: {
		display: 'none',
	},
}))

const OfficialText = styled(Typography)(({ theme }) => ({
	...theme.typography.subtitle1,
	color: theme.palette.blue[400],

	[theme.breakpoints.down('sm')]: {
		...theme.typography.label,
	},
}))
