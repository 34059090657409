import camelize from 'camelize'
import { SetStateAction, useCallback, useEffect, useRef, useState } from 'react'
import snakeize from 'snakeize'
//스네이크 쿼리스트링
export const toQueryString = (obj: object | null, withQuerySymbol = true, transSnake = true) => {
	if (obj === null) {
		return ''
	}

	const snakeObj = transSnake ? snakeize(obj) : obj
	const query = Object.keys(snakeObj)
		.filter((key) => key !== '' && snakeObj[key] !== undefined && snakeObj[key] !== '')
		.map((key) => `${encodeURIComponent(key)}=${encodeURIComponent(snakeObj[key])}`)
		.join('&')

	if (!query) {
		return ''
	}

	return withQuerySymbol ? `?${query}` : query
}
//카멜 쿼리스트링
export const toQueryStringCamel = (
	obj: object | null,
	withQuerySymbol = true,
	transSnake = true,
) => {
	if (obj === null) {
		return ''
	}
	const query = Object.keys(obj)
		.filter(
			(key) => key !== '' && obj[key] !== undefined && obj[key] !== '' && obj[key] !== null,
		)
		.map((key) => {
			if (Array.isArray(obj[key])) {
				return obj[key]
					.map((value) => `${encodeURIComponent(key)}=${encodeURIComponent(value)}`)
					.join('&')
			}
			return `${encodeURIComponent(key)}=${encodeURIComponent(obj[key])}`
		})
		.join('&')
	if (!query) {
		return ''
	}
	return withQuerySymbol ? `?${query}` : query
}
export const getParams = (query) => {
	if (!query) {
		return {}
	}

	return camelize(
		(/^[?#]/.test(query) ? query.slice(1) : query)
			.split('&')
			.filter((key) => key !== '')
			.reduce((params, param) => {
				const pair = param.split('=')
				const key = pair[0]
				let value = pair[1]
				// value = value.indexOf(',') > -1 ? value.split(',') : value;
				value = value ? decodeURIComponent(value.replace(/\+/g, ' ')) : ''
				params[key] = value // eslint-disable-line
				return params
			}, {}),
	)
}

export interface CSSProps {
	className?: string
}

export type OnClickProps<T extends string = 'onClick', E = Element> = Partial<
	Record<T, (e: React.SyntheticEvent<E>) => void>
>

export function useMounted() {
	const [mounted, setMounted] = useState(false)

	useEffect(() => {
		setMounted(true)
	}, [])

	return mounted
}

export function useDelayedState<T>(
	initialValue: T,
	delay = 200,
): [T, React.Dispatch<SetStateAction<T>>, (value: T) => void] {
	const [value, setValue] = useState<T>(initialValue)
	const timeout = useRef(0)

	const setDelayValue = useCallback(
		(value: T) => {
			timeout.current = window.setTimeout(() => {
				setValue(value)
			}, delay)
		},
		[delay],
	)

	useEffect(() => {
		return () => {
			clearTimeout(timeout.current)
		}
	}, [])

	return [value, setValue, setDelayValue]
}

export const lockScroll = () => {
	document.body.style.overflow = 'hidden'
	document.body.style.paddingRight = '15px'
}

export const unlockScroll = () => {
	document.body.style.overflow = 'auto'
	document.body.style.paddingRight = '0px'
}

export const isInWebview = () => typeof window !== 'undefined' && !!window['mobileWebview']
