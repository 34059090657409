import { ReactNode } from 'react'
import styled from 'styled-components'
import Link from 'next/link'
import { useQuery } from '@tanstack/react-query'
import { AxiosError } from 'axios'
import {
	AdBannerPosition,
	getAdBanner,
	getAdBannerItemResponse,
	getAdBannerRequest,
	IAdBannerItem,
} from 'src/api/adBanner'
import { mixpanelEvent } from '@/lib/mixpanels/mixpanel'

export type AdBannerShape = 'fullWidth' | 'default'

export interface AdBannerItemProps extends IAdBannerItem {
	shape?: AdBannerShape
}

export const useAdBannerQuery = ({ position, subject }: getAdBannerRequest) => {
	const {
		isLoading: isAdBannerLoading,
		isError: isAdBannerError,
		data: adBannerResponse,
		isSuccess: isAdBannerSuccess,
	} = useQuery<getAdBannerItemResponse, AxiosError>(
		['adBanner', position, subject],
		() => getAdBanner({ position, subject }),
		{
			refetchOnWindowFocus: false,
			refetchOnReconnect: false,
		},
	)
	return {
		isAdBannerLoading,
		isAdBannerError,
		adBannerResponse,
		isAdBannerSuccess,
		noAdItems: !!!adBannerResponse || adBannerResponse.items.length === 0,
	}
}
export const AdBannerItem: React.FC<AdBannerItemProps> = ({
	shape = 'default',
	link,
	imageUrl,
	id,
}) => {
	//외부 url은 새창으로 열기
	const isCurrentTab = link?.includes('https://solvook.com')
	return (
		<AdBannerItemContainer
			onClick={() =>
				mixpanelEvent('AD Banner Clicked', {
					ID: id,
					'Button URL': link,
				})
			}
			className={`${shape} adbanner-item`}
			href={link}
			target={!isCurrentTab ? '_blank' : '_self'}>
			<img src={imageUrl} alt="광고 이미지" />
		</AdBannerItemContainer>
	)
}

export const AdBannerLayout = ({
	children,
	position,
}: {
	children: ReactNode
	position: AdBannerPosition
}) => {
	return (
		<AdBannerLayoutContainer className={`adbanner-container ${position}`}>
			{children}
		</AdBannerLayoutContainer>
	)
}
//moside empty
export const AdBannerEmpty = () => {
	return (
		<AdBannerEmptyContainer>
			<p>진행중인 이벤트가 없습니다.</p>
		</AdBannerEmptyContainer>
	)
}
export const AdBannerList = Object.assign(AdBannerLayout, {
	Item: AdBannerItem,
	Empty: AdBannerEmpty,
})
const AdBannerEmptyContainer = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	height: 100%;

	p {
		color: ${(props) => props.theme.new.colors.sol_gray_500};
		text-align: center;
		font-size: 16px;
		line-height: 1.5;
		letter-spacing: -0.16px;
	}
`

export const AdBannerItemContainer = styled(Link)`
	background-color: rgba(169, 175, 199, 0.2);
	display: flex;
	align-items: center;
	width: 100%;
	justify-content: center;
	cursor: pointer;
	height: 80px;
	transition: all 0.3s;

	img {
		width: 316px;
		height: 80px;
	}

	:hover {
		background-color: rgba(169, 175, 199, 0.35);
	}

	&.fullWidth {
		border-radius: 0;
	}

	&.default {
		border-radius: 6px;
	}
`
const AdBannerLayoutContainer = styled.div`
	display: flex;
	gap: 20px;

	&.productDetail {
		margin-top: 48px;
		@media (max-width: 768px) {
			margin-top: 32px;
			flex-direction: column;
		}
	}

	&.search {
		margin-top: 36px;
		@media (max-width: 768px) {
			margin: 0 -16px;
			a {
				border-radius: 0;
			}
		}
	}

	&.moSide {
	}

	&.globalMenu {
		flex-direction: column;
	}

	&.solvookPlus {
		margin-top: 20px;

		a {
			border-radius: 12px;
			border: 1px solid ${(props) => props.theme.new.colors.sol_gray_50};
			box-shadow: 3px 3px 12px 0px rgba(28, 31, 46, 0.06);
			background-color: #fff;
			transition: all 0.3s;
			height: 100%;

			img {
				border-radius: 12px;
				height: 100%;
				max-width: 340px;
				width: 100%;
				aspect-ratio: 340 / 110;
			}

			:hover {
				border: 1px solid ${(props) => props.theme.new.colors.sol_gray_200};
			}
		}
	}
`
