import { useBooksQuery } from '@/hooks/useBooksQuery'
import { mixpanelEvent } from '@/lib/mixpanels/mixpanel'
import { Checkbox } from '@bookips/solvook-ui-library'
import { Divider, Typography, styled, useTheme } from '@mui/material'
import { useQuery } from '@tanstack/react-query'
import { useRouter } from 'next/router'
import { useCallback, useMemo } from 'react'
import { EbookFilterTypeToQueryKey, getBooksProductsFilters } from 'src/api/booksProducts'

export const enum EbookUniversityFilter {
	DEPARTMENT = 'department',
	TYPE = 'type',
}

const filterTypeToString = {
	department: '전공/계열',
	type: '도서유형',
}

const FilterBook = () => {
	const theme = useTheme()
	const majorFilter = EbookUniversityFilter.DEPARTMENT
	const typeFilter = EbookUniversityFilter.TYPE

	const { push, pathname } = useRouter()
	const { handleDetailFilter, query, searchParams } = useBooksQuery()

	const { data: ebookFilter, isLoading: isLoadingFilterData } = useQuery(
		// 필터
		['universityEbookFilter'],
		() =>
			getBooksProductsFilters({
				productType: 'ebookUniv',
				fields: ['department', 'type'], //전공계열 도서유형
			}),
		{
			staleTime: Infinity,
		},
	)

	// filter api response 타입이 기존 도서와 중고등 ebook과 대학생 ebook 유니온 타입을 사용하기에, 타입 좁히기용 값
	const ebookResponseFilterType = useMemo(() => {
		if (!ebookFilter) return null

		if (EbookUniversityFilter.DEPARTMENT in ebookFilter) {
			return ebookFilter
		}
	}, [ebookFilter])

	const handleMixpanelEvent = useCallback(
		(props: { filter: string; checked: boolean; major: string }) => {
			//mx-142 검색 필터를 클릭했을 때,
			if (props.checked) {
				mixpanelEvent('[Univ] Search Filtered', {
					'Filter Type': props.filter,
					'Filter Name': props.major,
				})
			}
		},
		[],
	)
	const searchDeletePage = useCallback(() => {
		const page = searchParams.get('page')
		if (page) {
			searchParams.delete('page')
			push({ pathname, query: searchParams.toString() }, undefined, {
				shallow: true,
				scroll: true,
			})
		}
	}, [searchParams, push, pathname])

	//스켈레톤 필요할 수도

	return (
		<SearchHeader>
			{query['q'] && (
				<SearchTextRow>
					<Typography variant="h6" color={theme.palette.primary.main}>
						{`'${query['q']}'`}
					</Typography>
					{`검색결과`}
				</SearchTextRow>
			)}
			<FilterBox>
				<RowBox>
					<Typography variant="semibold16">{filterTypeToString.department}</Typography>
					<RowGrid>
						{ebookResponseFilterType &&
							ebookResponseFilterType.department.map((major, idx) => {
								const isChecked =
									query[EbookFilterTypeToQueryKey[majorFilter]]?.includes(
										major,
									) || false

								return (
									<Checkbox
										key={idx}
										label={major}
										checked={isChecked}
										onChange={(e) => {
											handleDetailFilter(
												EbookFilterTypeToQueryKey[majorFilter],
												major,
												major,
											)
											handleMixpanelEvent({
												filter: filterTypeToString.department,
												checked: e.target.checked,
												major: major,
											})
											searchDeletePage()
										}}
									/>
								)
							})}
					</RowGrid>
				</RowBox>
				<Divider />
				<RowBox>
					<Typography variant="semibold16">{filterTypeToString.type}</Typography>
					<RowGrid>
						{ebookResponseFilterType &&
							ebookResponseFilterType.type.map((itemType, idx) => {
								const isChecked =
									query[EbookFilterTypeToQueryKey[typeFilter]]?.includes(
										itemType,
									) || false
								return (
									<Checkbox
										key={idx}
										label={itemType}
										checked={isChecked}
										onChange={(e) => {
											handleDetailFilter(
												EbookFilterTypeToQueryKey[typeFilter],
												itemType,
												itemType,
											)
											handleMixpanelEvent({
												filter: filterTypeToString.type,
												checked: e.target.checked,
												major: itemType,
											})
											searchDeletePage()
										}}
									/>
								)
							})}
					</RowGrid>
				</RowBox>
			</FilterBox>
		</SearchHeader>
	)
}

const SearchHeader = styled('section')(({ theme }) => ({
	display: 'flex',
	flexDirection: 'column',
	gap: '32px',

	[theme.breakpoints.down('sm')]: {
		gap: 0,
	},
}))
const FilterBox = styled('section')(({ theme }) => ({
	display: 'flex',
	flexDirection: 'column',
	gap: '16px',

	padding: '24px',
	backgroundColor: theme.palette.blackAndWhite.white,
	borderRadius: '6px',
	boxShadow: '3px 3px 12px 0px rgba(28, 31, 46, 0.06)',

	hr: {
		// margin: '16px 0',
	},
}))
const RowBox = styled('div')({
	display: 'flex',
	gap: '16px',
	'> span': {
		flexShrink: 0,
		paddingTop: '9px',
	},
})

const RowGrid = styled('div')({
	width: '100%',
	display: 'flex',
	flexWrap: 'wrap',
	gap: '0',

	label: {
		flexShrink: 0,
		margin: '0',
	},
})

const SearchTextRow = styled('div')(({ theme }) => ({
	display: 'flex',
	gap: '8px',
	...theme.typography.h6,
	color: theme.palette.text.title,

	[theme.breakpoints.down('sm')]: {
		padding: '12px 20px',
		backgroundColor: theme.palette.blackAndWhite.white,
		borderBottom: `1px solid ${theme.palette.grey[50]}`,
	},
}))
export default FilterBook
