import { CheckboxProps, Menu, MenuItem, styled } from '@mui/material'
import * as React from 'react'
import { ReactNode } from 'react'
import useWindowDimensions from '@/lib/web-ui/useWindowDimensions'
import Icon from '@/components/icon/Icon'
import { ArrowDown, Sizes } from '@/components/icon/IconList'
import { Checkbox } from 'antd'
import { ButtonWithDropdownMenu } from '@/components/Categories/Menu/index'
import { useDropdownMenu } from '@/hooks/useDropdownMenu'
import { MobileFilterSideSheet } from '@/components/Search/MobileFilterSideSheet'
import SelectedFilterWrapper from './SelectedFilter'
import { BooksMobileFilterListWrapper } from '@/components/Books/List/BookListMobileFilterList'
import { MobileFilterListItem } from '@/components/Search/MobileFilterListItem'
import { FilterQueryHandlerType } from '@/hooks/useCategoryFilterHandler'
import { mixpanelEvent } from '@/lib/mixpanels/mixpanel'
import { CheckboxChangeEvent } from 'antd/lib/checkbox'
import { MobileSliderArea } from '@/components/Search'

export const DetailFilterButtonList = ({
	filterItems,
	handleMobileSlider,
	isCheckFilterItem,
	isCheckFilterCount,
	selectedFilterList,
	mobileFilterSideSheet,
	filterQueryHandler,
}) => {
	return (
		<DetailFilterButtonListContainer>
			{filterItems.map((filter, idx) => {
				if (filter.items.length > 0)
					return (
						<CustomMenuButton
							key={idx}
							filterName={filter.name}
							items={filter.items}
							mobileFilterOpen={handleMobileSlider}
							isCheckFilterItem={isCheckFilterItem}
							isCheckFilterCount={isCheckFilterCount}>
							<CustomMenuButton.MenuItemListLayout>
								{filter.items.map((item, idx) => {
									if (!item) return
									return (
										<CustomMenuButton.MenuItem
											key={filter + idx + item}
											checked={isCheckFilterItem(filter.name, item)}
											onChange={() => {
												filterQueryHandler({
													filterType: FilterQueryHandlerType.DETAILFILTER,
													filterName: filter.name,
													value: item,
												})
												//mx-47
												mixpanelEvent('category Filtered', {
													'Filter Type': filter.name,
													'Filter Option Selected': item,
												})
											}}>
											{item}
										</CustomMenuButton.MenuItem>
									)
								})}
							</CustomMenuButton.MenuItemListLayout>
						</CustomMenuButton>
					)
			})}
			<MobileSliderArea>
				<MobileFilterSideSheet
					title={'상세 필터'}
					sliderOpen={mobileFilterSideSheet}
					openSlider={handleMobileSlider}>
					{selectedFilterList.length > 0 && (
						<SelectedFilterWrapper
							position={'sideSheet'}
							button={
								<SelectedFilterWrapper.ResetButton
									handleResetFilter={() =>
										filterQueryHandler({
											filterType: FilterQueryHandlerType.RESET,
										})
									}
								/>
							}>
							{selectedFilterList.map((item, idx) => {
								return (
									<SelectedFilterWrapper.SelectedItem
										key={'selectedItem' + idx}
										label={item.value}
										onRemove={() =>
											filterQueryHandler({
												filterType: FilterQueryHandlerType.DETAILFILTER,
												filterName: item.key,
												value: item.value,
											})
										}
									/>
								)
							})}
						</SelectedFilterWrapper>
					)}
					<BooksMobileFilterListWrapper>
						<ul>
							{filterItems.map((key, idx) => {
								const newFilters = key.items.map((item) => {
									return {
										id: item,
										value: item,
									}
								})
								if (key.items.length > 0)
									return (
										<li key={idx}>
											<MobileFilterListItem
												title={key.name}
												filterItem={newFilters}
												handleFilter={(filterType, id, value) => {
													filterQueryHandler({
														filterType:
															FilterQueryHandlerType.DETAILFILTER,
														filterName: filterType,
														value: id.toString(),
													})
													//mx-47
													mixpanelEvent('Category Filtered', {
														'Filter Type': filterType,
														'Filter Option Selected': id,
													})
												}}
												selectedFilters={selectedFilterList}
												openSlider={handleMobileSlider}
												sliderOpen={mobileFilterSideSheet}
											/>
										</li>
									)
							})}
						</ul>
					</BooksMobileFilterListWrapper>
				</MobileFilterSideSheet>
			</MobileSliderArea>
		</DetailFilterButtonListContainer>
	)
}

export interface FilerMenuButtonItemProps {
	filterName: string
	items: string[]
	mobileFilterOpen: (isOpen: boolean, value: string) => void
	isCheckFilterItem: (filterName: string, value: string) => boolean
	isCheckFilterCount: (filterName: string) => number
	children?: ReactNode
}

export const CustomMenuButton = ({
	filterName,
	mobileFilterOpen,
	isCheckFilterCount,
	children,
}: FilerMenuButtonItemProps) => {
	const { handleOpen, handleClose, open, anchorEl } = useDropdownMenu()
	const { isMobile } = useWindowDimensions()
	const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
		if (isMobile) {
			mobileFilterOpen(true, filterName)
			return
		}
		handleOpen(event)
	}
	return (
		<>
			<ButtonWithDropdownMenu
				id="filter-button"
				className={isCheckFilterCount(filterName) ? 'selected' : ''}
				aria-controls={open ? 'filter-menu' : undefined}
				aria-haspopup="true"
				aria-expanded={open ? 'true' : undefined}
				onClick={handleClick}>
				{filterName}
				{isCheckFilterCount(filterName) ? ` ${isCheckFilterCount(filterName)}` : ''}
				<Icon icon={ArrowDown} size={Sizes.small} color={'rgba(106, 112, 138, 1)'} />
			</ButtonWithDropdownMenu>
			<CustomMenu
				className="filter-menu"
				id="filter-menu"
				anchorEl={anchorEl}
				open={open}
				onClose={handleClose}
				MenuListProps={{
					'aria-labelledby': 'basic-button',
				}}>
				{children}
			</CustomMenu>
		</>
	)
}
export const CheckboxMenuItem = ({
	checked,
	onChange,
	children,
	props,
}: {
	checked: boolean
	onChange: (e: CheckboxChangeEvent) => void
	children: ReactNode
	props?: CheckboxProps
}) => {
	return (
		<CustomMenuItem>
			<Checkbox checked={checked} onChange={onChange}>
				<TypographyText>{children}</TypographyText>
			</Checkbox>
		</CustomMenuItem>
	)
}
export const MenuListContainer = styled('div')`
	display: flex;
	width: 100%;
	flex-wrap: wrap;

	&::-webkit-scrollbar {
		width: 3px;
		height: 140px;
	}

	&::-webkit-scrollbar-thumb {
		border-radius: 2px;
		background: rgba(206, 210, 224, 1);
	}
`
CustomMenuButton.MenuItemListLayout = MenuListContainer
CustomMenuButton.MenuItem = CheckboxMenuItem
export const CustomMenu = styled(Menu)`
	&.filter-menu {
		margin-top: 8px;
		padding: 12px;

		.MuiPaper-root {
			width: 450px;
			max-height: 400px;
			padding: 0px 4px;
			overflow: auto;
			border-radius: 6px;

			&::-webkit-scrollbar {
				width: 3px;
				height: 140px;
			}

			&::-webkit-scrollbar-thumb {
				border-radius: 2px;
				background: rgba(206, 210, 224, 1);
			}
		}
	}
`
export const TypographyText = styled('p')`
	font-size: 16px;
	color: rgb(95, 95, 105);
	font-weight: normal;
	line-height: 1.5;
	padding: 0px 0px 0px 12px;
`

export const CustomMenuItem = styled(MenuItem)`
	width: 50%;
	padding: 0;
	text-wrap: wrap;
	display: flex;
	align-items: flex-start;
	min-height: unset;

	.ant-checkbox-wrapper {
		padding: 8px 12px;
		width: 100%;
	}
`
const DetailFilterButtonListContainer = styled('div')`
	display: flex;
	gap: 6px;
	position: sticky;
	top: 0;
	width: 100%;
	left: 0;
	z-index: 10;
	padding: 24px 0;
	background-color: rgba(255, 255, 255, 0.9);
	margin-bottom: -24px;
	@media (max-width: 768px) {
		margin-bottom: 0;
	}
`
