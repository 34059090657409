import { PriceSectionProps, PurchaseStatus } from '@/modules/products/components/PriceSection'

import Icon from '@/components/icon/Icon'
import { ArrowRight, CouponDownload, CouponFilled, Sizes } from '@/components/icon/IconList'
import { useAuthStore } from '@/lib/auth/AuthStore'
import { mixpanelEvent } from '@/lib/mixpanels/mixpanel'
import { numberFormatter } from '@/lib/utils'
import { LIBRARIES_INDEX_PAGE, PASSES_LANDING, PASSES_ORDER } from '@/settings/constant'
import { useMutation, useQuery } from '@tanstack/react-query'
import { AxiosError, AxiosResponse } from 'axios'
import { useRouter } from 'next/router'
import { ChangeEventHandler, useCallback, useEffect, useMemo, useState } from 'react'
import { getMemberships } from 'src/api/membership'
import styled, { useTheme } from 'styled-components'
import { getMembershipAvailable, getRelatedProducts, postUseMembership } from '../../../api/product'
import PassNotAvailable from './PassNotAvailable'
import { PassTab, useMembershipStore } from './store/passStore'
import useAddCart from './useAddCart'

import { AvailableRelatedProductsModal } from '@/components/Product/Handout/RelatedProducts/AvailableRelatedProductsModal'
import useCouponReceive from '@/components/Product/Handout/hooks/useCouponReceive'
import Dialog from '@/components/Reviews/Detail/Dialog'
import CheckLoginDialog from '@/components/modal/CheckLoginDialog'
import useHandoutProductStore from '../../../store/relatedProduct'
import { PassRadioField } from './PassRadioField'

const PassSection = ({
	type = 'default',
	product,
	className,
}: PriceSectionProps & { className?: string }) => {
	const theme = useTheme()
	const router = useRouter()

	const { isLoggedIn } = useAuthStore()
	const passStatus = useMembershipStore()

	const {
		handleAddCartClick,
		isModal,
		setIsModal,
		modalProps,
		setModalProps,
		purchaseStatus,
		statusMessage,
	} = useAddCart({
		product,
	})
	const UNLIMITED_COUNT = 999999
	const usePassMutation = useMutation(postUseMembership)
	const useMembershipAvailableMutation = useMutation(getMembershipAvailable)
	const { refetch: refetchRelatedProducts } = useQuery(['relatedProduct', product.id], () =>
		getRelatedProducts(product.id.toString()),
	)
	const { closePassSectionModal, membershipAvailable } = useHandoutProductStore((state) => ({
		isOpenPassSectionModal: state.isOpenPassSectionModal,
		openPassSectionModal: state.openPassSectionModal,
		closePassSectionModal: state.closePassSectionModal,
		membershipAvailable: state.membershipAvailable,
	}))
	const onChange: ChangeEventHandler<HTMLInputElement> = useCallback((e) => {
		passStatus.setState('selectedPass', Number(e.target.value))
	}, [])

	const { handoutCoupons, filteredHasCoupon } = useCouponReceive({
		id: product.id,
		price: product.price,
	})

	// 로그인 정보 체크를 위한 영역
	const [checkModal, setCheckModal] = useState(false)

	const checkLogin = useCallback(() => {
		if (!isLoggedIn) setCheckModal(true)
		else passStatus.setState('isCouponDialogOpen', !passStatus.isCouponDialogOpen)

		mixpanelEvent('Coupon Get Button Clicked', {
			'Button Name': '받지 않은 할인쿠폰이 있어요',
		})
	}, [isLoggedIn])

	const {
		data: ticketData,
		isSuccess,
		isError,
	} = useQuery(
		['ticketList', product.id],
		() => {
			return getMemberships({ productId: String(product.id) })
		},
		{ enabled: isLoggedIn, retry: 3 },
	)

	const availableTicketList = useMemo(() => {
		if (ticketData)
			return (
				ticketData.tickets.filter(
					(ticket, _) => ticket.usable && !!!ticket.expiredReason,
				) ?? []
			)
	}, [ticketData])

	const availableSolvookList = useMemo(() => {
		if (ticketData)
			return (
				ticketData.solvookpluses.filter(
					(pass, _) => pass.usable && !!!pass.expiredReason,
				) ?? []
			)
	}, [ticketData])

	//패스 empty 상태
	const passDisabled = useMemo(() => {
		//미로그인시
		if (!isLoggedIn) return isLoggedIn
		//이용권 미보유 유저
		if (isError) return false
		//active inactive 둘다 없을 경우
		if (isSuccess) {
			const notExpiredTicket = ticketData.tickets.filter((ticket) => !!!ticket.expiredReason)
			const notExpiredSolvookplus = ticketData.solvookpluses.filter(
				(pass) => !!!pass.expiredReason,
			)
			if (notExpiredTicket.length === 0 && notExpiredSolvookplus.length === 0) return false
		}
		return isLoggedIn && isSuccess
	}, [isLoggedIn, isSuccess, ticketData])
	//여기서 부터 연관교재
	const purchaseProductInfo = useHandoutProductStore((state) => state.purchaseProductsInfo)
	const updateMembershipAvailable = useHandoutProductStore(
		(state) => state.updateMembershipAvailable,
	)
	const hasAlreadyOwned = useHandoutProductStore((state) => state.hasAlreadyOwned)

	//단건구매
	const currentProductUsePass = useCallback(async () => {
		//2-1 보고있는 교재가 이미 소유한 자료인 경우
		if (hasAlreadyOwned) {
			setModalProps({
				title: '이미 이용 중이에요.',
				content: '해당 자료는 자료 보관함에서 확인할 수 있어요.',
				suffix: {
					variant: 'solid',
					label: '자료 보관함 이동',
					handleClick: () => {
						router.push(LIBRARIES_INDEX_PAGE)
					},
				},
			})
			setIsModal(true)
			return
		}
		//2-2. 이용권 사용하기
		setModalProps({
			title: (
				<>
					이용권 <Em>{purchaseProductInfo.totalCount}회</Em>가 차감돼요
				</>
			),
			content: `사용하기를 클릭시 선택한 이용권의 사용가능 횟수 ${purchaseProductInfo.totalCount}회가 차감 돼요.`,
			prefix: {
				variant: 'soft',
				label: '취소',
				handleClick: () => {
					setIsModal(false)
				},
			},
			suffix: {
				variant: 'solid',
				label: `${purchaseProductInfo.totalCount}회 사용하기`,
				handleClick: () => {
					usePassConfirmed()
				},
			},
		})
		setIsModal(true)
	}, [availableSolvookList, availableTicketList, passStatus, purchaseProductInfo])
	//연관교재
	const relatedProductUsePass = useCallback(async () => {
		//1. 선택교재 + 보고있는 교재가 이미 소유한 자료인 경우 -> 카운트 제거
		const [selectedPassId] = availableTicketList
			.concat(availableSolvookList)
			.filter((pass, idx) => passStatus.selectedPass === pass.membershipId)
		const productIds = purchaseProductInfo.selectedRelatedProducts.map((product) =>
			product.id.toString(),
		)
		const res = await useMembershipAvailableMutation.mutateAsync({
			membershipId: selectedPassId.membershipId.toString(),
			productIds,
		})
		const hasUnusableProduct: Array<string> = res.data
			.filter((product) => !product.usable)
			.map((product) => product.productId.toString())
		//2.. 카운트체크 Fixme 여기서 체크하는게 맞는시점인지 모르겟음 -> 논의 필요
		if (
			selectedPassId.remainCount < purchaseProductInfo.totalCount &&
			selectedPassId.productInfo.availableCount !== UNLIMITED_COUNT
		) {
			setModalProps({
				title: '이용권 사용횟수를 초과했어요.',
				content: '선택하신 상품의 갯수가 이용권의 남은 횟수를 초과 했어요.',
				severity: 'caution',
				prefix: null,
				suffix: {
					variant: 'solid',
					label: '확인',
					handleClick: () => {
						setIsModal(false)
						mixpanelEvent('Pass Unavailable', {
							'Popup Title': '이용권 사용횟수를 초과했어요.',
							'Button Name': '확인',
							'Button URL': null,
						})
					},
				},
			})
			setIsModal(true)
			return
		}
		//이미 소유한 자료가 있니
		if (hasAlreadyOwned) {
			//구매불가능한 상품이 있는경우 ->  나머지는 relatedProductUsePass에서 처리
			if (hasUnusableProduct.length > 0) {
				updateMembershipAvailable(hasUnusableProduct)
				setIsOpenAvailableRelatedProductsModal(true)
				closePassSectionModal()
				return
			}
			updateMembershipAvailable([])
			setIsOpenAvailableRelatedProductsModal(true)
			closePassSectionModal()
			return
		}
		if (hasUnusableProduct.length > 0) {
			//차감 안내 모달.
			//이미 구매한 애들만 추가
			updateMembershipAvailable(hasUnusableProduct)
			setIsOpenAvailableRelatedProductsModal(true)
			closePassSectionModal()
			return
		}
		setModalProps({
			title: (
				<>
					이용권 <Em>{purchaseProductInfo.totalCount}회</Em>가 차감돼요
				</>
			),
			content: `사용하기를 클릭시 선택한 이용권의 사용가능 횟수 ${purchaseProductInfo.totalCount}회가 차감 돼요.`,
			prefix: {
				variant: 'soft',
				label: '취소',
				handleClick: () => {
					setIsModal(false)
				},
			},
			suffix: {
				variant: 'solid',
				label: `${purchaseProductInfo.totalCount}회 사용하기`,
				handleClick: () => {
					usePassConfirmed()
				},
			},
		})
		setIsModal(true)
	}, [availableSolvookList, availableTicketList, passStatus, purchaseProductInfo])
	const handleUsePass = useCallback(async () => {
		//1. 사용가능한 이용권이 없을 경우(보험처리)
		if (availableSolvookList.length === 0 && availableTicketList.length === 0) return
		const [selectedPassId] = availableTicketList
			.concat(availableSolvookList)
			.filter((pass, idx) => passStatus.selectedPass === pass.membershipId)
		mixpanelEvent('Purchase Button Clicked', {
			PurchaseType: '이용권 사용',
			'Pass ID': passStatus.selectedPass,
			'Pass Title': selectedPassId.productInfo.title,
			'Product IDs': purchaseProductInfo.selectedRelatedProducts
				.map((product) => product.id)
				.concat([product.id.toString()]),
			'Product Titles': purchaseProductInfo.selectedRelatedProducts
				.map((product) => product.title)
				.concat([product.title]),
		})
		//2. 단건교재 사용
		if (purchaseProductInfo.selectedRelatedProducts.length === 0) {
			currentProductUsePass()
			return
		}
		//3. 연관교재 사용
		relatedProductUsePass()
	}, [availableSolvookList, availableTicketList, passStatus, purchaseProductInfo])

	const usePassConfirmed = useCallback(() => {
		const passId = availableTicketList
			.concat(availableSolvookList)
			.filter((pass, idx) => passStatus.selectedPass === pass.membershipId)

		const { purchaseProductsInfo } = useHandoutProductStore.getState()
		//보고 있는 상품이 갖고 있는 상품인 경우인
		const productIds = hasAlreadyOwned
			? purchaseProductsInfo.selectedRelatedProducts.map((product) => product.id.toString())
			: purchaseProductsInfo.selectedRelatedProducts
					.map((product) => product.id.toString())
					.concat(product.id.toString())

		usePassMutation.mutate(
			{ productIds: productIds, membershipId: passId[0].membershipId.toString() },
			{
				onSuccess: async (data: AxiosResponse) => {
					setIsModal(true)
					setModalProps({
						title: (
							<>
								이용권을 <Em>{productIds.length}회</Em> 사용했어요.
							</>
						),
						content: '해당 자료들은 자료 보관함에서 확인할 수 있어요.',
						severity: 'success',
						suffix: {
							variant: 'solid',
							label: '자료 보관함 이동',
							handleClick: () => {
								mixpanelEvent('Popup Button Clicked', {
									'Popup Type': 'Use Pass',
									'Popup Title': `이용권을 ${productIds.length}회 사용했어요.`,
									'Button Name': '자료 보관함 이동',
									'Button URL': LIBRARIES_INDEX_PAGE,
								})
								router.push(LIBRARIES_INDEX_PAGE)
							},
						},
						prefix: {
							variant: 'soft',
							label: '닫기',
							handleClick: () => {
								setIsModal(false)
								mixpanelEvent('Popup Button Clicked', {
									'Popup Type': 'Use Pass',
									'Popup Title': `이용권을 ${productIds.length}회 사용했어요.`,
									'Button Name': '닫기',
									'Button URL': null,
								})
							},
						},
					})
					await refetchRelatedProducts()
					mixpanelEvent('Pass Use Completed', {
						//이용권 사용 성공 mx-15
						'Pass ID': passId[0].membershipId,
						'Pass Category': passId[0].productInfo.category,
						'Pass Category Title': passId[0].productInfo.categoryTitle,
						'Pass Title': passId[0].productInfo.title,
						'Product ID': product.id,
						'Product Category': product.category,
						'Product Title': product.title,
						'Product Price': product.price,
						'Author ID': product.author,
						'Author Name': passId[0].productInfo.category,
					})
				},
				onError: async (error: AxiosError<{ message: string }>) => {
					//사용중지된 교재 출처교재 오류
					if (
						error.response.status === 409 &&
						error.response.data.message.includes(
							'could not purchase or use the product',
						)
					) {
						setIsModal(true)
						setModalProps({
							title: '해당 상품은 판매가 중단됐어요.',
							content:
								'저작권 이용기간이 만료된 상품이거나 판매가 중지된 상품이에요.',
							severity: 'caution',
							suffix: null,
						})
					}

					if (
						error.response.status === 409 &&
						error.response.data.message.includes('user already has a product')
					) {
						setIsModal(true)
						setModalProps({
							title: '이미 이용중인 자료가 있어요',
							content: '해당 자료를 제외한 자료만 이용권 차감돼요',
							severity: 'caution',
							suffix: {
								variant: 'solid',
								label: '자료 보관함 바로가기',
								handleClick: () => {
									router.push(LIBRARIES_INDEX_PAGE)
								},
							},
						})
						//어쨌든 구매가 된거임
						await refetchRelatedProducts()
					}
					if (error.response.status === 403) {
						// 방어 코드로 넣었어요.
						setIsModal(true)
						setModalProps({
							title: '이용권을 구매해주세요!',
							content: '해당 자료는 이용권으로 결제할 수 있어요.',
							severity: 'caution',
							suffix: {
								variant: 'solid',
								label: '이용권 구매하기',
								handleClick: () => {
									router.push(PASSES_ORDER)
								},
							},
						})
					}
				},
			},
		)
	}, [availableSolvookList, availableTicketList, passStatus, purchaseProductInfo])

	useEffect(() => {
		if (isSuccess) {
			passStatus.setState('tickets', ticketData.tickets)
			passStatus.setState('solvook', ticketData.solvookpluses)

			//available pass가 있을 경우만 탭변경
			if (availableTicketList.length > 0 || availableSolvookList.length > 0) {
				passStatus.setState('currentTab', PassTab.USETICKET)
				passStatus.setState(
					'selectedPass',
					availableTicketList.concat(availableSolvookList)[0].membershipId,
				)
			} else {
				passStatus.setState('selectedPass', null)
				passStatus.setState('currentTab', PassTab.BUYSINGLE)
			}
		}
	}, [isSuccess, product.id])

	useEffect(() => {
		return () => {
			passStatus.reset()
		}
	}, [])

	//이용권 버튼 비활성화
	const passPurchaseDisabled = useMemo(() => {
		//미로그인시
		if (!isLoggedIn) return false
		//패스가 없을 경우
		if (!passDisabled) return false
		//unavailable pass만 있을 경우
		if (availableTicketList?.length === 0 && availableSolvookList?.length === 0) return false
		// 구매 불가능 상태일 경우
		if (purchaseStatus !== PurchaseStatus.normal) return false
		return true
	}, [isLoggedIn, isSuccess, ticketData, purchaseStatus])
	const [isOpeAvailableRelatedProductsModal, setIsOpenAvailableRelatedProductsModal] =
		useState(false)

	return (
		<>
			<PassSectionContainer>
				<Dialog
					zIndex={1010}
					isOpen={isModal}
					title={<>이용권을 사용했어요.</>}
					onClose={() => setIsModal(false)}
					prefix={{
						label: '닫기',
						variant: 'soft',
						handleClick: () => setIsModal(false),
					}}
					suffix={{
						label: '자료 보관함 바로가기',
						variant: 'solid',
						handleClick: () => {
							mixpanelEvent('Popup Button Clicked', {
								//팝업 mx-9
								'Popup Type': 'Use Pass',
								'Popup Title': '이용권을 사용했어요.',
								'Button Name': '자료 보관함 바로가기',
								'Button URL': LIBRARIES_INDEX_PAGE,
							})
							router.push(LIBRARIES_INDEX_PAGE)
							setIsModal(false)
						},
					}}
					{...modalProps}
				/>
				<PassBox className={className ?? ''}>
					<PassToggleBox>
						<ToggleButton
							active={passStatus.currentTab === PassTab.USETICKET}
							onClick={() => passStatus.setState('currentTab', PassTab.USETICKET)}>
							이용권 사용
						</ToggleButton>
						<ToggleButton
							active={passStatus.currentTab === PassTab.BUYSINGLE}
							onClick={() => {
								passStatus.setState('currentTab', PassTab.BUYSINGLE)
								mixpanelEvent('Tab UI Clicked', {
									'Tab Type': 'Purchase Type',
									'Tab Name': '자료 구매 ',
								})
							}}>
							자료 구매
						</ToggleButton>
					</PassToggleBox>
					{passStatus.currentTab === PassTab.USETICKET && (
						<>
							{passDisabled === false || isError ? (
								<EmptyContiner>
									<p>등록된 이용권이 없습니다</p>
									<button
										onClick={() => {
											mixpanelEvent('Purchase Button Clicked', {
												//mx-3
												'Product ID': product.id,
												'Product Categories': product.category,
												'Product Title': product.title,
												'Product Price': product.price,
												'Button UI': 'Text',
											})
											router.push(PASSES_LANDING)
										}}>
										이용권 구매하기{' '}
										<Icon
											icon={ArrowRight}
											color={theme.new.colors.sol_indigo_500}
											size={Sizes.small}
										/>
									</button>
								</EmptyContiner>
							) : (
								<RowContainer>
									<RadioFieldSet>
										{availableTicketList?.concat(availableSolvookList) &&
											availableTicketList
												.concat(availableSolvookList)
												.map((pass, idx) => (
													<PassRadioField
														pass={pass}
														key={pass.membershipId}
														onChange={onChange}
														passStatus={passStatus}
													/>
												))}
									</RadioFieldSet>
									<RowBox>
										<PassNotAvailable />
									</RowBox>
								</RowContainer>
							)}
						</>
					)}
					<CountProductSection>
						<dl>
							<dt>상품 개수</dt>
							<dd>{purchaseProductInfo.totalCount}개</dd>
						</dl>
						{passStatus.currentTab === PassTab.USETICKET ? (
							<dl>
								<dt>이용권 차감 횟수</dt>
								<dd>
									<em>{purchaseProductInfo.totalCount}회</em>
								</dd>
							</dl>
						) : (
							<dl>
								<dt>합계 금액</dt>
								<dd>
									<b>{numberFormatter(purchaseProductInfo.totalPrice)}원</b>
								</dd>
							</dl>
						)}
					</CountProductSection>
					{passStatus.currentTab === PassTab.BUYSINGLE &&
						handoutCoupons &&
						(filteredHasCoupon()?.length === 0 ? (
							<CouponGuidText>할인쿠폰은 결제시 적용돼요.</CouponGuidText>
						) : (
							<NoticeCouponDownload role="button" onClick={checkLogin}>
								<div className="text-area">
									<Icon icon={CouponFilled} size={Sizes.small} />
									<p>받지 않은 할인쿠폰이 있어요.</p>
								</div>
								<div className="icon-area">
									<Icon
										icon={CouponDownload}
										size={Sizes.xmedium}
										color="#1C1F2E"
									/>
								</div>
							</NoticeCouponDownload>
						))}
					<PassCTA>
						{passStatus.currentTab === PassTab.USETICKET ? (
							<button
								disabled={!passPurchaseDisabled}
								onClick={() => {
									closePassSectionModal()
									//이용권 모달
									handleUsePass()
								}}
								className={
									purchaseStatus !== PurchaseStatus.normal ? 'warning' : ''
								}>
								{purchaseStatus === PurchaseStatus.unknownLicense
									? '구매제한이 있는 상품입니다.\n 로그인후 확인해주세요.'
									: purchaseStatus !== PurchaseStatus.normal
									? '판매 중단된 상품입니다.'
									: `이용권 사용하기`}
							</button>
						) : (
							<button
								className={
									purchaseStatus !== PurchaseStatus.normal ? 'warning' : ''
								}
								onClick={handleAddCartClick}
								disabled={purchaseStatus !== PurchaseStatus.normal}>
								{statusMessage}
							</button>
						)}
					</PassCTA>
				</PassBox>
				{isOpeAvailableRelatedProductsModal && (
					<AvailableRelatedProductsModal
						usePassConfirmed={usePassConfirmed}
						isOpen={isOpeAvailableRelatedProductsModal}
						onClose={() => setIsOpenAvailableRelatedProductsModal(false)}
					/>
				)}
				<CheckLoginDialog open={checkModal} onClose={() => setCheckModal(false)} />
			</PassSectionContainer>
		</>
	)
}

const ModalButtonBox = styled.div`
	align-items: center;
	display: flex;
	gap: 8px;

	button {
		padding: 12px 20px;
		margin: 0 !important;
	}

	@media (max-width: 768px) {
		flex-direction: column;
	}

	@media (max-width: 768px) {
		button:nth-of-type(1) {
			order: 2;
		}

		button:nth-of-type(2) {
			order: 1;
		}
	}
`
export const PassSectionContainer = styled.section`
	position: relative;
	width: 100%;
	display: flex;
	flex-direction: column;
	gap: 14px;
`

export const PassBox = styled.div`
	display: flex;
	flex-direction: column;

	max-height: calc(100vh - 219px);
	overflow-y: hidden;

	background-color: #fff;
	border-radius: 6px;
	box-shadow: 6px 6px 20px 0px rgba(28, 31, 46, 0.12);
	padding: 20px;
	//margin-bottom: 20px;

	.previewMode {
		margin-top: 80px;
	}

	&:nth-of-type(2) {
		margin-top: 40px;
	}

	@media (max-width: 1080px) {
		margin-bottom: 0;
	}
`
export const PassToggleBox = styled.div`
	width: 100%;
	display: flex;
	background: #f0f1f5;
	border-radius: 6px;
	padding: 2px;
	margin-bottom: 20px;
`
export const ToggleButton = styled.button<{ active?: boolean }>`
	all: unset;
	color: ${(props) =>
		props.active ? props.theme.new.colors.sol_gray_700 : props.theme.new.colors.sol_gray_500};
	font-size: 16px;
	font-style: normal;
	font-weight: 600;
	line-height: 24px; /* 150% */
	letter-spacing: -0.16px;

	width: 100%;
	text-align: center;
	padding: 10px 0;
	border-radius: 4px;

	box-shadow: ${(props) => (props.active ? '3px 3px 12px 0px rgba(28, 31, 46, 0.06)' : 'none')};
	background: ${(props) => (props.active ? '#fff' : 'transfarent')};
	box-sizing: border-box;

	cursor: pointer;
`
export const RowContainer = styled.div`
	display: flex;
	height: 100%;
	overflow: auto;

	flex-direction: column;
	gap: 20px;

	&::-webkit-scrollbar {
		width: 5px;
	}

	&::-webkit-scrollbar-thumb {
		width: 2px;
		background: ${(props) => props.theme.new.colors.sol_gray_300};
		border-radius: 10px;
	}
`
export const RowParents = styled.div`
	padding: 16px;
	border: 1px solid ${(props) => props.theme.new.colors.gray100};
	background-color: #fff;
	border-radius: 6px;
	display: flex;
	justify-content: space-between;
	align-items: center;
`

export const Row = styled(RowParents)`
	cursor: pointer;

	div {
		div {
			display: none;
		}
	}

	:hover {
		background-color: ${(props) => props.theme.new.colors.indigo50};
		border: 1px solid ${(props) => props.theme.new.colors.indigo500};
	}

	&.active {
		background-color: ${(props) => props.theme.new.colors.indigo50};
		border: 1px solid ${(props) => props.theme.new.colors.indigo500};

		div {
			div {
				display: flex;
			}
		}
	}
`

export const DisabledRow = styled.div`
	.disabled {
		border-radius: 6px;
		border: 1px solid #e1e4ed;
		background: #f7f8fa;
	}
`
const RowBox = styled(DisabledRow)`
	display: flex;
	flex-direction: column;
	gap: 12px;
`

export const Col = styled.div``

export const Font = styled.p`
	font-size: 16px;
	font-weight: 600;
	line-height: 24px;
`
export const ActiveTitle = styled(Font)`
	display: flex;
	align-items: center;
	flex: 1;
	gap: 4px;
	color: #222;
	word-break: keep-all;
`
export const InActiveTitle = styled(Font)`
	color: ${(props) => props.theme.new.colors.sol_gray_300};
`
export const InfoSubText = styled.div`
	/* color: #326ee5; */
	display: flex;
	align-items: center;
	gap: 16px;
`
export const InfoPointText = styled.p`
	font-size: 14px;
	font-style: normal;
	line-height: 22px; /* 157.143% */
	letter-spacing: -0.14px;
	color: ${(props) => props.theme.new.colors.sol_gray_500};
	position: relative;

	> span {
		color: #326ee5;
		font-weight: 600;
	}

	:after {
		content: '|';
		position: absolute;
		right: -10px;
		color: ${(props) => props.theme.new.colors.sol_gray_100};
	}
`
export const SubscriptionInfoText = styled.p`
	color: #326ee5;
	font-weight: 600;
	font-size: 14px;
	line-height: 22px; /* 157.143% */
	letter-spacing: -0.14px;
	position: relative;
`
export const InfoDefaultText = styled.p`
	font-size: 14px;
	color: #6a708a;
	font-weight: 400;
	line-height: 22px;
`
export const InfoWarningText = styled.div`
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	line-height: 22px; /* 157.143% */
	letter-spacing: -0.14px;
	color: ${(props) => props.theme.new.colors.sol_red_400};
	cursor: pointer;

	p {
		text-decoration: underline;
		color: inherit;
	}
`

export const RadioFieldSet = styled.fieldset`
	display: flex;
	flex-direction: column;
	gap: 12px;
`

export const ExpandButton = styled.button`
	all: unset;
	cursor: pointer;
`
export const PriceBox = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin: 20px 0;

	p {
		font-size: 16px;
		font-style: normal;
		font-weight: 400;
		line-height: 24px; /* 150% */
	}

	span {
		font-size: 24px;
		font-style: normal;
		font-weight: 700;
		line-height: 32px; /* 133.333% */
		text-decoration: line-through;
		color: ${(props) => props.theme.new.colors.sol_gray_300};
	}

	&.cart {
		span {
			text-decoration: none;
			color: ${(props) => props.theme.new.colors.sol_gray_900};
		}
	}
`
export const PassCTA = styled.div`
	width: 100%;
	display: flex;

	button {
		all: unset;
		width: 100%;

		background-color: ${(props) => props.theme.new.colors.indigo500};
		color: #fff;
		padding: 16px;
		text-align: center;
		font-weight: 600;
		font-size: 16px;

		border-radius: 6px;

		cursor: pointer;

		:hover {
			background-color: ${(props) => props.theme.new.colors.indigo600};
		}

		:disabled {
			background-color: ${(props) => props.theme.new.colors.sol_gray_100};
			color: ${(props) => props.theme.new.colors.sol_gray_300};
		}

		&.warning {
			background-color: transparent;
			color: ${(props) => props.theme.new.colors.sol_red_400};
			white-space: break-spaces;
		}
	}

	p {
		width: 100%;
		padding: 16px;
		color: ${(props) => props.theme.new.colors.sol_red_400};
		text-align: center;
	}
`

const EmptyContiner = styled.div`
	border-radius: 6px;
	background: ${(props) => props.theme.new.colors.sol_gray_0};

	font-size: 16px;
	line-height: 24px; /* 150% */
	letter-spacing: -0.16px;
	padding: 32px 0;
	margin-top: 16px;
	display: flex;
	flex-direction: column;
	gap: 12px;
	align-items: center;

	p {
		font-weight: 400;
		color: ${(props) => props.theme.new.colors.sol_gray_500};
	}

	a {
		font-weight: 600;
		color: ${(props) => props.theme.new.colors.sol_indigo_500};
		display: flex;
		align-items: center;
	}

	button {
		all: unset;
		font-weight: 600;
		color: ${(props) => props.theme.new.colors.sol_indigo_500};
		display: flex;
		align-items: center;
	}
`

const CountProductSection = styled.section`
	margin-bottom: 20px;
	margin-top: 20px;
	display: flex;
	flex-direction: column;
	gap: 12px;

	dl {
		display: flex;
		justify-content: space-between;
		margin: 0;

		dd,
		dt {
			color: ${(props) => props.theme.new.colors.sol_gray_500};
			font-size: 14px;
			line-height: 22px; /* 157.143% */
			letter-spacing: -0.14px;
			margin: 0px;

			em {
				font-weight: 700;
				color: ${(props) => props.theme.new.colors.sol_blue_500};
			}

			b {
				color: ${(props) => props.theme.new.colors.sol_gray_900};
				font-weight: 700;
			}
		}

		dd {
			font-size: 16px;
		}
	}
`
const Em = styled.em`
	color: ${(props) => props.theme.new.colors.sol_blue_500};
`

const NoticeCouponDownload = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 9px 12px;
	border: 1px solid ${(props) => props.theme.new.colors.sol_gray_200};
	border-radius: 6px;

	margin-bottom: 20px;
	cursor: pointer;

	:hover {
		background-color: ${(props) => props.theme.new.colors.sol_gray_50};
	}

	.text-area {
		display: flex;
		gap: 8px;
		align-items: center;

		p {
			font-size: 14px;
			font-style: normal;
			font-weight: 600;
			line-height: 22px; /* 157.143% */
			letter-spacing: -0.14px;
			color: ${(props) => props.theme.new.colors.sol_red_400};
		}
	}

	.icon-area {
		all: unset;
		display: flex;
		align-items: center;
		justify-content: center;
	}
`
const CouponGuidText = styled.p`
	text-align: center;
	padding-bottom: 20px;
	font-size: 14px;
	font-style: normal;
	font-weight: 600;
	line-height: 22px; /* 157.143% */
	letter-spacing: -0.14px;
	color: ${(props) => props.theme.new.colors.sol_blue_400};
`

export default PassSection
