import { useScroll } from 'framer-motion'
import { FC, PropsWithChildren, useCallback, useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import { useDebouncedCallback } from 'use-debounce'

interface Transparencies {
	opacityLeft: number
	opacityRight: number
}

const ShadowWrapper = styled.div<Transparencies>`
	overflow: hidden;
	position: relative;

	&:before,
	&:after {
		content: '';
		height: 100%;
		width: 10px;
		position: absolute;
		top: 0;
		transition: 0.2s opacity;
		pointer-events: none;
	}

	&:before {
		left: 0;
		background: linear-gradient(90deg, rgba(247, 248, 250, 0), transparent);
		opacity: ${(props) => props.opacityLeft};
		z-index: 1;
	}

	&:after {
		right: 0;
		background: linear-gradient(270deg, rgba(247, 248, 250, 1), transparent);
		opacity: ${(props) => props.opacityRight};
	}
`
const ShadowScroller = styled.div`
	overflow-x: auto;
	width: auto;
`
const ShadowSlideContainer: FC<PropsWithChildren<{}>> = ({ children }) => {
	const wrapperShadowRef = useRef<HTMLDivElement>()
	const { scrollXProgress } = useScroll({ container: wrapperShadowRef })

	const [transparencies, setTransparencies] = useState<Transparencies>({
		opacityLeft: 0,
		opacityRight: 0,
	})
	const setup = useCallback((progress: number) => {
		const { current: wrapperShadow } = wrapperShadowRef
		let newValues: Transparencies = { opacityLeft: 0, opacityRight: 0 }

		if (wrapperShadow && wrapperShadow.offsetWidth < wrapperShadow.scrollWidth) {
			newValues.opacityLeft = progress === 0 ? 0 : 1
			newValues.opacityRight = progress === 1 ? 0 : 1
			// newValues.opacityLeft = progress
			// newValues.opacityRight = 1 - progress
		}

		// console.log(
		// 	wrapperShadow.offsetWidth,
		// 	wrapperShadow.scrollWidth,
		// 	newValues.opacityLeft,
		// 	newValues.opacityRight,
		// )
		setTransparencies(newValues)
	}, [])
	const debouncedSetup = useDebouncedCallback(setup, 50)

	useEffect(() => {
		function handleChange() {
			debouncedSetup(scrollXProgress.get())
		}

		const unsubscribe = scrollXProgress.on('change', handleChange)
		handleChange()

		return () => {
			unsubscribe()
		}
	}, [debouncedSetup])

	return (
		<ShadowWrapper {...transparencies}>
			<ShadowScroller ref={wrapperShadowRef}>{children}</ShadowScroller>
		</ShadowWrapper>
	)
}

export default ShadowSlideContainer
