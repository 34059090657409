import Category from '@/components/Category'
import { GNBSideSheet } from '@/components/SideSheets/GNBSideSheet'
import { useSideCategoryMenuStore } from '@/components/SideSheets/store/useCategoryMenuStore'
import { UpdateTagWithIcon } from '@/components/Tags'
import { GoodnotesOfficialLogo, GoodnotesOfficialMobileLogo } from '@/components/UniversityShop'
import Icon from '@/components/icon/Icon'
import { ArrowDownOutlined, ArrowUp, Sizes, SolvookLogo } from '@/components/icon/IconList'
import { mixpanelEvent } from '@/lib/mixpanels/mixpanel'
import { EBOOKS_UNIVERSITY_LANDING } from '@/settings/constant'
import { Divider } from '@mui/material'
import Link from 'next/link'
import { useRouter } from 'next/router'
import React, { FC, PropsWithChildren, useMemo } from 'react'
import Headroom from 'react-headroom'
import { useInView } from 'react-intersection-observer'
import { getCurrentCartQuery } from 'src/api/booksCart'
import { useSavedUserSourceStore } from 'src/store/main'
import { CategoryButton, GNBWrapper, MobileSearchBar, StyledContainer } from './GNB.style'
import LoginMenu from './LoginMenu'
import LogoutMenu from './LogoutMenu'
import { SearchRequestForm } from './SearchRequestForm'

export interface GNBProps {
	isLogin: boolean
	onLogout: () => void
}

export const GNB: React.FC<GNBProps> = ({ isLogin, onLogout }) => {
	const { pathname } = useRouter()
	const [bottom, inView] = useInView()
	const { updateUserMeta } = useSavedUserSourceStore(({ updateUserMeta }) => ({
		updateUserMeta,
	}))
	const { isOpenSideMenu, openSideMenu, closeSideMenu, openMobileSideMenu } =
		useSideCategoryMenuStore()

	const handleLogout = () => {
		updateUserMeta({
			mainSubject: null,
			job: null,
		})
		closeSideMenu()
		onLogout()
	}
	//Fixme error시 onLogout
	const { data: cartCounter, isError } = getCurrentCartQuery({
		enabled: isLogin,
		params: {
			cartType: 'separate',
		},
	})

	//gnb가 또 공사가 될 예정일 수 있나 싶어서 분기를 쳐놨습니다..
	const isUniversityHome = useMemo(() => {
		if (pathname.includes(EBOOKS_UNIVERSITY_LANDING)) {
			return true
		}
		return false
	}, [pathname])

	return (
		<HeadroomWrapper>
			<GNBWrapper className="gnb">
				{isOpenSideMenu && <Category onClose={() => closeSideMenu()} />}
				{/* 대학생 굿노트홈을 위한 모바일 로고 배너*/}
				{isUniversityHome && <GoodnotesOfficialMobileLogo />}

				<StyledContainer className={'inner'}>
					<>
						<h1 className="logo">
							<Link
								href="/"
								onClick={() => {
									mixpanelEvent('Logo Clicked')
								}}>
								<Icon icon={SolvookLogo} />
							</Link>

							{/* desktop 카테고리 메뉴판 버튼은 대학생홈에서는 보이지 않습니다. 검색창도 그렇습니다.  */}
							{isUniversityHome ? (
								<>
									<Divider orientation="vertical" sx={{ height: '20px' }} />
									<GoodnotesOfficialLogo />
								</>
							) : (
								<CategoryButton
									className={`${isOpenSideMenu ? 'active' : ''}`}
									onClick={() => {
										if (isOpenSideMenu) closeSideMenu()
										else openSideMenu()

										mixpanelEvent('GNB Button Clicked', {
											//mx-16 gnb 버튼 클릭 시
											'Button Name': '카테고리',
										})
									}}>
									<UpdateTagWithIcon />
									카테고리
									{isOpenSideMenu ? (
										<Icon icon={ArrowUp} size={Sizes.small} color="#fff" />
									) : (
										<Icon icon={ArrowDownOutlined} size={Sizes.small} />
									)}
								</CategoryButton>
							)}
						</h1>
						{!isUniversityHome && <SearchRequestForm />}
					</>

					<LoginMenu cartItemsCount={cartCounter?.lineItemsCount}></LoginMenu>
					{!isLogin && <LogoutMenu />}
				</StyledContainer>
			</GNBWrapper>
			{<GNBSideSheet onLogout={handleLogout} />}

			{/* 대학생용 모바일 홈에서는 보여지지 않는 서치바 */}
			{!isUniversityHome && (
				<MobileSearchBar ref={bottom} className={'fixed'}>
					<SearchRequestForm />
				</MobileSearchBar>
			)}
		</HeadroomWrapper>
	)
}

const HeadroomWrapper: FC<PropsWithChildren<{}>> = ({ children }) => {
	const { pathname } = useRouter()
	const { isHidden } = useMemo(() => {
		return { isHidden: pathname === '/products/[id]' }
	}, [pathname])

	return isHidden ? (
		<Headroom disable={!isHidden} style={{ zIndex: '800' }}>
			{/* 컨텐츠 중간에 sticky 속성 레이어가 위에 있기에, 부모 요소에서 z-index를 넣어주었습니다. */}
			{children}
		</Headroom>
	) : (
		<>{children}</>
	)
	// return <>{children}</>
}
