import { Container } from '@/components'
import { themeGet } from '@styled-system/theme-get'
import styled from 'styled-components'

export const GNBHeight = 74
export const GNBHeightMobile = 110
export const GNBWrapper = styled.div`
	position: sticky;
	background-color: #fff;
	border-bottom: solid 1px #f3f3f3;
	left: 0;
	right: 0;
	top: 0;
	z-index: 800;
	@media (max-width: 768px) {
		border-bottom: none;
	}

	.inner {
		max-width: 1200px;
		position: relative;
		display: flex;
		justify-content: space-between;
		align-items: center;
		//gap: 40px;
		padding: 12px 0;
		height: ${GNBHeight}px;
		@media (max-width: 1200px) {
			padding: 0 32px;
		}
		@media (max-width: 768px) {
			/* flex-direction: column; */
			align-items: center;
			gap: 12px;
			padding: 12px 16px;
			height: 61px;
		}
	}

	.logo {
		/* margin: 0 20px 0 0; */
		display: flex;
		gap: 20px;
		margin: 0;
		align-items: center;
		/* width: 270px; */
		//flex: 1;

		a {
			display: block;
			/* padding: 8px; */
			display: flex;

			img {
				display: block;
			}
		}

		.mobile-menu-icon {
			all: unset;
			display: none;
			cursor: pointer;
		}

		@media (max-width: 1200px) {
			width: unset;
			.mobile-menu-icon {
				display: block;
			}
		}
		@media (max-width: 768px) {
			display: flex;
			align-items: center;
			justify-content: space-between;
			width: 100%;
			img {
				height: 24px;
				width: auto;
			}

			svg {
				height: 24px;
				width: 96px;
			}

			.mobile-menu-icon {
				display: block;

				p {
					display: none;
				}
			}
			hr {
				display: none;
			}
		}
	}

	.search-area {
		display: flex;
		justify-content: center;
		align-items: center;
		gap: 24px;

		&.hide {
			display: none;
		}

		@media (max-width: 1200px) {
			width: 100%;
			order: 4;

			.category {
				display: none;
			}
		}
		@media (max-width: 768px) {
		}

		.category {
			width: 84px;
			cursor: pointer;

			a {
				display: block;
				font-size: 16px;
				font-weight: bold;
				white-space: nowrap;
				transition: opacity 0.2s;
				z-index: 1;

				&,
				span svg {
					color: ${themeGet('text.2', '#5F5F69')};
					transition: color 0.2s;
				}

				span {
					display: inline-block;
					margin-right: 4px;
				}

				body:has(&) {
					.ant-dropdown {
						&.category_dropdown {
							padding-top: 16px;

							.ant-dropdown-menu {
								width: 160px;

								.ant-dropdown-menu-item {
									height: 40px;

									@media (hover: hover) {
										&:hover {
											color: ${themeGet('primary.0', '#002FA8')};
											background-color: #f5f8fb;
										}
									}
								}
							}
						}
					}

					.ant-select-dropdown.search_type_dropdown {
						padding: 4px 0;

						.ant-select-item {
							.ant-select-item-option-content {
								line-height: 22px;
							}

							&.ant-select-item-option-active {
								background-color: transparent;

								@media (hover: hover) {
									&:hover {
										background-color: ${themeGet('background.1', '#F5F8FB')};

										.ant-select-item-option-content {
											color: ${themeGet('primary.0', '#002FA8')};
										}
									}
								}
							}

							&.ant-select-item-option-selected {
								font-weight: bold;
								background-color: transparent;

								.ant-select-item-option-content {
									color: ${themeGet('primary.0', '#002FA8')};
								}
							}
						}

						&.search_type_dropdown {
							.ant-select-item {
								height: 37px;
								padding: 0 0 0 24px;

								.ant-select-item-option-content {
									line-height: 35px;
								}
							}
						}

						&.small-type-select {
							.ant-select-item {
								height: 32px;
								padding: 0 0 0 12px;

								.ant-select-item-option-content {
									line-height: 30px;
								}
							}
						}
					}

					.ant-dropdown.util_dropdown {
						.ant-dropdown-menu {
							.ant-dropdown-menu-item {
								@media (hover: hover) {
									&:hover {
										background-color: ${themeGet('background.1', '#F5F8FB')};

										.ant-dropdown-menu-title-content {
											color: ${themeGet('primary.0', '#002FA8')};
										}
									}
								}
							}
						}
					}

					.ant-modal-content {
						.ant-modal-close {
							top: 0;
							right: 0;

							&:hover {
								background-color: transparent;
							}
						}
					}

					.ant-pagination-options {
						display: none;
					}
				}

				&.ant-dropdown-open {
					&,
					span svg {
						color: ${themeGet('primary.0', '#002FA8')};
					}
				}
			}
		}
	}

	.util_login {
		display: flex;

		@media (max-width: 1200px) {
			/* display: none; */
			//justify-content: end;
			//order: 2;
			//width: calc(50% - 72px);
			//margin-top: -16px;
			//margin-right: -16px;
			//z-index: 9;
		}

		@media (max-width: 768px) {
		}

		& > a {
			display: flex;

			&:not(:first-child) {
				margin-left: 48px;

				@media (max-width: 1200px) {
					margin-left: 24px;
				}
			}
		}

		.cart-btn {
			width: 32px;
			height: 32px;
			padding: 0;
			border: none;
			transition: opacity 0.2s;

			@media (max-width: 768px) {
			}
			@media (max-width: 1200px) {
				order: 2;
				width: 24px;
				min-width: 24px;
				height: 24px;
				background-size: contain;
			}

			&:before {
				display: block;
				content: '';
				width: 100%;
				height: 100%;
				background: url('/static/assets/icon_shopping.svg') no-repeat 50% 50%;
				background-size: contain;
			}

			&:hover {
				opacity: 0.7;
			}
		}

		.link-to-libraries {
			position: relative;
		}

		.util_dropdown {
		}
	}

	.util_logout {
		display: flex;
		justify-content: flex-end;
		margin-right: -18px;

		a {
			display: block;
			font-size: 16px;
			padding: 18px;
			color: ${themeGet('text.0', '#1B1B29')};
			transition: opacity 0.2s;

			&:hover {
				opacity: 0.8;
			}
		}

		@media (max-width: 1200px) {
			display: none;
		}
		@media (max-width: 768px) {
		}
	}
`
export const MobileMenu = styled.div`
	display: none;
	order: 3;
	width: 24px;
	height: 24px;
	font-size: 0;

	.dim {
		position: fixed;
		top: 63px;
		left: 0;
		width: 100%;
		height: 100%;
		background-color: rgba(0, 0, 0, 0.5);
		z-index: 999;
	}

	.ant-btn {
		width: 100%;
		height: 100%;
		padding: 16px;
		border: none;

		img {
			display: block;
			width: 100%;
			height: 100%;
		}
	}

	@media (max-width: 1200px) {
		display: block;
		width: 56px;
		height: 56px;
		margin-top: -16px;
		margin-right: -16px;
	}
	@media (max-width: 768px) {
	}
`
export const mobileHeaderHeight = 110
export const FixedBox = styled.div`
	@media (max-width: 768px) {
		height: ${mobileHeaderHeight}px;
	}
	@media (max-width: 1200px) {
		//display: none;
		height: ${mobileHeaderHeight}px;
	}

	&.header-fixed {
		height: 85px;
	}
`
export const StyledContainer = styled(Container)`
	.fixed-gnb-wrapper {
		display: none;

		.mobile-search-button {
			display: none;
			all: unset;
			margin-right: 6px;
			cursor: pointer;
		}

		.gnb-login-menu {
			width: 270px;
			/* display: none; */
		}
	}

	@media (max-width: 1200px) {
		/* flex-wrap: wrap; */
	}
	@media (max-width: 768px) {
		.search-request-form {
			display: none;
		}

		.gnb-login-menu,
		.gnb-logout-menu {
			/* display: none; */
		}

		.fixed-gnb-wrapper {
			display: flex;
			gap: 8px;

			.mobile-search-button {
				display: block;
			}

			.gnb-login-menu {
				display: block;

				p {
					display: none;
				}

				.cart-link {
					svg {
						width: 24px;
						height: 24px;
					}
				}

				a:not(:nth-of-type(2)) {
					display: none;
				}

				a:before {
					display: none;
				}
			}
		}
	}
`
export const MobileSearchBar = styled.div`
	display: none;
	padding: 0px 16px 12px;
	position: relative;

	@media (max-width: 768px) {
		display: block;

		&.fixed {
			position: sticky;
			z-index: 200;
			top: 61px;
			background-color: white;
			left: 0;
			right: 0;
		}

		&.block {
			display: block;
		}
	}
`
export const MobileMenuDrawer = styled.div`
	padding: 24px 20px;

	.gnb-logout-menu {
		padding-bottom: 8px;

		> a {
			display: flex;
			width: 100%;
			align-items: center;
			justify-content: center;
		}
	}

	ul {
		li {
			padding: 13px 12px;
			color: ${(props) => props.theme.new.colors.sol_gray_700};
			font-size: 14px;
			line-height: 22px; /* 157.143% */
			cursor: pointer;
		}
	}

	.logout-btn {
		position: fixed;
		bottom: 0;
		border: none;
		width: 70%;
		text-align: left;
		padding: 13px 0;
		height: 48px;
		color: ${(props) => props.theme.new.colors.sol_gray_500};
		line-height: 22px; /* 157.143% */
		border-top: 1px solid ${(props) => props.theme.new.colors.sol_gray_100};
	}

	.gnb-login-menu {
		display: flex;
		width: 100%;
		gap: 4px;
		padding-bottom: 20px;

		> a {
			display: flex;
			border: 1px solid ${(props) => props.theme.new.colors.sol_gray_100};
			border-radius: 6px;
			padding: 9px 16px;
			width: 100%;
			align-items: center;
			flex-direction: column;
			gap: 2px;
			justify-content: center;
			font-weight: 600;
			letter-spacing: -0.14px;
			text-align: center;

			i {
				//margin-right: 4px;
			}
		}
	}
`
export const DrawerHeader = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	font-size: 20px;
	font-weight: 700;

	img {
		height: 24px;
		width: auto;
	}
`

const StTemp = styled.div`
	position: relative;
`
export const LoginMenuWrapper = styled.div<{ count: number }>`
	display: flex;
	gap: 8px;

	> a {
		padding: 4px;
		display: flex;
		flex-direction: column;
		align-items: center;
		border-radius: 6px;

		:hover {
			background-color: ${(props) => props.theme.new.colors.sol_gray_50};
		}
	}

	p {
		white-space: nowrap;
	}

	.studio-link {
		position: relative;
		@media (max-width: 1200px) {
			display: none;
		}
	}

	.link-to-libraries {
		position: relative;

		.guid-tooltip-wrapper {
			p {
				font-size: 12px;
			}
		}

		@media (max-width: 1200px) {
			display: none;
		}
	}

	.ant-dropdown-trigger {
		flex-direction: row;

		.stack-centered {
			display: flex;
			align-items: center;
			flex-direction: column;
		}

		p {
			margin-bottom: 0;
			font-size: 12px;
			font-weight: 600;
			line-height: 1.5;
			color: ${(props) => props.theme.new.colors.gray700};
		}

		.stack-row {
			display: flex;
			align-items: center;
			gap: 4px;
		}
	}

	> a > p {
		margin-bottom: 0;
		font-size: 12px;
		font-weight: 600;
		line-height: 1.5;
		color: ${(props) => props.theme.new.colors.gray700};
	}

	.cart-link {
		position: relative;

		::before {
			content: '${(props) => props.count}';
			position: absolute;
			top: -3px;
			padding: 1px 5px 0px;
			right: 6px;
			//width: 13px;
			//height: 15px;
			color: #fff;
			border-radius: 50%;
			font-size: 11px;
			font-weight: 600;
			text-align: center;
			line-height: 1.4;
			background-color: ${(props) => props.theme.new.colors.sol_red_400};
			z-index: 1;
		}

		@media (max-width: 768px) {
			::before {
				content: none;
			}

			p {
				display: none;
			}
		}
	}
`
export const LogoutMenuWrapper = styled.div`
	display: flex;
	gap: 8px;
	position: relative;
	width: 270px;
	//flex: 1;
	justify-content: flex-end;

	> a {
		line-height: 22px;
		padding: 8px 15px;
		border-radius: 6px;
		font-size: 14px;
		font-weight: 600;
		transition: all 0.2s;

		&:nth-child(2) {
			border: ${(props) => props.theme.new.colors.sol_gray_100} 1px solid;

			:hover,
			:active {
				background-color: ${(props) => props.theme.new.colors.sol_dim_gray};
			}
		}

		&:last-child {
			background-color: ${(props) => props.theme.new.colors.sol_indigo_100};
			color: ${(props) => props.theme.new.colors.sol_indigo_600};
			border: ${(props) => props.theme.new.colors.sol_indigo_100} 1px solid;

			:hover,
			:active {
				background-color: ${(props) => props.theme.new.colors.sol_indigo_200};
			}
		}
	}

	@media (max-width: 1200px) {
		display: none;
	}
`
export const StyledTooltip = styled.div<{ visible: boolean }>`
	position: absolute;
	top: calc(100% + 8px);
	left: 50%;
	transform: translateX(-50%);
	transition: opacity 0.2s;
	pointer-events: none;
	opacity: ${(props) => (props.visible ? 1 : 0)};

	p {
		white-space: nowrap;
		color: ${(props) => props.theme.new.colors.white};
		padding: 8px 12px;
		border-radius: 4px;
		box-shadow: 6px 6px 20px 0px rgba(28, 31, 46, 0.12);
		font-size: 14px;
		font-weight: 400;
		line-height: 22px;
		background: ${(props) => props.theme.new.colors.sol_blue_400};
		text-align: center;

		&::before {
			content: '';
			position: absolute;
			bottom: 100%;
			left: 50%;
			transform: translateX(-50%);
			border-width: 8px;
			border-style: solid;
			border-color: transparent transparent ${(props) => props.theme.new.colors.sol_blue_400}
				transparent;
		}
	}
`
export const CategoryButton = styled.button`
	display: flex;
	align-items: center;
	gap: 8px;
	position: relative;
	padding: 6px 16px;
	border-radius: 18px;
	border: 1px solid ${(props) => props.theme.new.colors.sol_gray_100};
	background-color: ${(props) => props.theme.new.colors.white};
	color: ${(props) => props.theme.new.colors.sol_gray_700};

	font-size: 16px;
	font-style: normal;
	font-weight: 600;
	line-height: 24px; /* 150% */
	letter-spacing: -0.16px;

	outline: none;

	:hover {
		background-color: ${(props) => props.theme.new.colors.sol_dim_gray};
	}

	&.active {
		color: ${(props) => props.theme.new.colors.white};
		background-color: ${(props) => props.theme.new.colors.sol_gray_700};
	}

	@media (max-width: 1200px) {
		display: none;
	}
`
export const MypageMenu = styled.div`
	display: flex;
	align-items: center;

	@media (max-width: 1200px) {
		display: none;
	}
`
export const MenuHam = styled.div`
	display: none;
	padding: 4px 13px;

	button {
		all: unset;
		display: flex;
		flex-direction: column;
		align-items: center;
	}

	p {
		font-size: 12px;
		font-style: normal;
		font-weight: 600;
		line-height: 18px; /* 150% */
	}

	@media (max-width: 1200px) {
		display: flex;
		align-items: center;
	}
	@media (max-width: 768px) {
		p {
			display: none;
		}

		padding: 0;
	}
`
