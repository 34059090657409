import { useMemo } from 'react'
import { useMembershipStore } from './store/passStore'

const usePassList = () => {
	const passStatus = useMembershipStore()
	//active 상태만 보여줘야함.
	const unavailableTicketList = useMemo(() => {
		if (passStatus.tickets)
			return (
				passStatus.tickets.filter(
					(ticket, _) => !ticket.usable && !!!ticket.expiredReason,
				) ?? []
			)
	}, [passStatus.tickets])
	const availableTicketList = useMemo(() => {
		if (passStatus.tickets)
			return (
				passStatus.tickets.filter(
					(ticket, _) => ticket.usable && !!!ticket.expiredReason,
				) ?? []
			)
	}, [passStatus.tickets])
	const unavailableSolvookList = useMemo(() => {
		if (passStatus.solvook)
			return (
				passStatus.solvook.filter((pass, _) => !pass.usable && !!!pass.expiredReason) ?? []
			)
	}, [passStatus.solvook])
	const availableSolvookList = useMemo(() => {
		if (passStatus.solvook)
			return (
				passStatus.solvook.filter((pass, _) => pass.usable && !!!pass.expiredReason) ?? []
			)
	}, [passStatus.solvook])
	return {
		unavailable: unavailableTicketList.concat(unavailableSolvookList),
		availablePass: availableTicketList.concat(availableSolvookList),
	} as const
}

export default usePassList
