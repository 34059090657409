import {
	EXTERNAL_B2B_WEBFLOW_PAGE,
	EXTERNAL_CAREER_PAGE,
	EXTERNAL_CHANNEL_TALK,
	EXTERNAL_COMPANY_INTRODUCE,
	EXTERNAL_INTRODUCE_LIBRARY,
	EXTERNAL_PROMOTION_AUTHOR_PAGE,
	EXTERNAL_PROTECTION_GUIDE,
	EXTERNAL_SOLVOOK_BLOG,
	EXTERNAL_SOLVOOK_GUIDE,
	EXTERNAL_SOLVOOK_POST,
	INTRODUCE_PAGE,
	PRIVACY_PAGE,
	RNUMBER_PAGE,
	SOLVOOK_WRITE_STUDIO,
	TERMS_OF_REFUND_PAGE,
	TERMS_OF_USE_PAGE,
	UCI_PAGE,
} from '@/settings/constant'
import Link from 'next/link'
import React from 'react'
import styled, { useTheme } from 'styled-components'

import Icon from '@/components/icon/Icon'
import { ArrowDown, NaverPost, Sizes } from '@/components/icon/IconList'
import { mixpanelEvent } from '@/lib/mixpanels/mixpanel'
import { Button, Collapse } from 'antd'
import Image from 'next/image'
import { useRouter } from 'next/router'

const { Panel } = Collapse

export interface FooterViewProps {}

const categoryLinkList = [
	{
		title: '소개',
		links: [
			{
				title: '회사 소개',
				link: EXTERNAL_COMPANY_INTRODUCE,
			},
			{
				title: '서비스 소개',
				link: INTRODUCE_PAGE,
			},
			{
				title: '채용',
				link: EXTERNAL_CAREER_PAGE,
			},
		],
	},
	{
		title: '솔루션',
		links: [
			{
				title: '쏠북 마켓',
				link: '/',
			},
			{
				title: '쏠북 스튜디오',
				link: SOLVOOK_WRITE_STUDIO,
			},
			{
				title: '자료 보관함',
				link: EXTERNAL_INTRODUCE_LIBRARY,
			},
		],
	},
	{
		title: '저작권보호센터',
		links: [
			{
				title: '저작권 안내',
				link: EXTERNAL_PROTECTION_GUIDE,
			},
			{
				title: 'R인증',
				link: RNUMBER_PAGE,
			},
			{
				title: 'UCI 코드',
				link: UCI_PAGE,
			},
		],
	},
	{
		title: '문의',
		links: [
			{
				title: '사업 제휴',
				link: EXTERNAL_B2B_WEBFLOW_PAGE,
			},
			{
				title: '저자 신청',
				link: EXTERNAL_PROMOTION_AUTHOR_PAGE,
			},
		],
	},
]
const policyLinkList = [
	{
		title: '서비스 이용약관',
		link: TERMS_OF_USE_PAGE,
	},
	{
		title: '개인정보처리방침',
		link: PRIVACY_PAGE,
	},
	{
		title: '취소/환불정책',
		link: TERMS_OF_REFUND_PAGE,
	},
]
export const FooterView: React.FC<FooterViewProps> = (props) => {
	const SolvookAddress = '서울 서초구 법원로4길 11-6 (서초동,스매치 서초사옥)'
	const theme = useTheme()
	const router = useRouter()
	const openBlank = (link: string) => {
		return link.includes('https') ? '_blank' : '_self'
	}
	const onClickLink = (link: string, name: string) => {
		mixpanelEvent('Footer Section Clicked', {
			buttonName: name,
			buttonUrl: link,
		})
	}
	return (
		<FooterViewWrapper>
			<div>
				<FooterTop>
					<HelpCenter>
						<p>고객만족센터</p>
						<ul>
							<li>
								<Button
									type="primary"
									ghost
									onClick={() => {
										onClickLink(EXTERNAL_CHANNEL_TALK, '채널톡 문의')
										router.push(EXTERNAL_CHANNEL_TALK)
									}}>
									채널톡 문의
								</Button>
								<p>
									채널톡으로 문의하시면
									<br />
									가장 빠른 처리가 가능합니다.
								</p>
							</li>
							<li>
								<Button
									type="primary"
									ghost
									onClick={() => {
										onClickLink(EXTERNAL_SOLVOOK_GUIDE, '쏠북 이용 가이드')
										router.push(EXTERNAL_SOLVOOK_GUIDE)
									}}>
									쏠북 이용 가이드
								</Button>
								<p></p>
							</li>
						</ul>
					</HelpCenter>
					<CategoryLinkList>
						{categoryLinkList.map((category, idx) => (
							<div key={`category-link-list-${idx}`}>
								<p>{category.title}</p>
								<ul>
									{category.links.map((linkItem, idx) => (
										<li key={`category-link-item-${idx}`}>
											<Link
												onClick={() =>
													onClickLink(linkItem.link, linkItem.title)
												}
												href={linkItem.link}
												target={openBlank(linkItem.link)}>
												{linkItem.title}
											</Link>
										</li>
									))}
								</ul>
							</div>
						))}
					</CategoryLinkList>
					<Collapse
						collapsible="header"
						ghost
						expandIcon={() => <Icon icon={ArrowDown} size={Sizes.small} />}>
						{categoryLinkList.map((category, idx) => (
							<Panel header={category.title} key={idx}>
								<ul>
									{category.links.map((linkItem, idx) => (
										<li key={`category-link-item-${idx}`}>
											<Link
												onClick={() =>
													onClickLink(linkItem.link, linkItem.title)
												}
												href={linkItem.link}
												target={openBlank(linkItem.link)}>
												{linkItem.title}
											</Link>
										</li>
									))}
								</ul>
							</Panel>
						))}
					</Collapse>
				</FooterTop>
				<FooterBottom>
					<CompanyInfo>
						<p>
							(주)북아이피스ㅣ대표 윤미선, 김관백 | 사업자 등록번호 493-88-01679
							<span className={'block_m'}>|</span> 통신판매업 신고
							제2021-서울서초-0156호 <span className={'block_m'}>|</span>
							<br /> 저작권대리중개업 신고 제1496호 | 본사: {SolvookAddress} <br />
							기업부설연구소: {SolvookAddress} | 이메일 문의 : support@bookips.com |
							전화 문의 : 02-2606-4990
						</p>
						<p className={'copyright'}>
							ⓒ Copyright 2024. BookIPs All Rights Reserved.
						</p>
					</CompanyInfo>
					<CompanyLink>
						<ul>
							{policyLinkList.map((linkItem, idx) => (
								<li key={`company-link-${idx}`}>
									<Link
										href={linkItem.link}
										target={openBlank(linkItem.link)}
										onClick={() => onClickLink(linkItem.link, linkItem.title)}>
										{linkItem.title}
									</Link>
								</li>
							))}
						</ul>
						<div className={'social-box'}>
							<Link
								passHref
								href={EXTERNAL_SOLVOOK_POST}
								onClick={() => onClickLink(EXTERNAL_SOLVOOK_POST, '쏠북 포스트')}
								target={'_blank'}
								rel={'noreferrer'}>
								<Icon
									icon={NaverPost}
									size={Sizes.xmedium}
									color={theme.new.colors.sol_gray_700}
								/>
								쏠북 포스트
							</Link>
							<Link
								passHref
								onClick={() => onClickLink(EXTERNAL_SOLVOOK_BLOG, '쏠북 블로그')}
								href={EXTERNAL_SOLVOOK_BLOG}
								target={'_blank'}
								rel={'noreferrer'}>
								<Image
									src={'/static/assets/icon_blog.svg'}
									alt={'icon image'}
									width={20}
									height={20}
								/>
								쏠북 블로그
							</Link>
						</div>
					</CompanyLink>
				</FooterBottom>
			</div>
		</FooterViewWrapper>
	)
}

const FooterViewWrapper = styled.footer`
	display: flex;
	padding: 40px;
	border-top: solid 1px ${(props) => props.theme.new.colors.sol_gray_100};

	> div {
		max-width: 1200px;
		width: 100%;
		margin: 0 auto;
	}

	a {
		color: ${(props) => props.theme.new.colors.sol_gray_500};
	}

	@media (max-width: 768px) {
		padding: 16px;
	}
`
const FooterTop = styled.div`
	display: flex;
	justify-content: space-between;
	padding-bottom: 28px;

	.ant-collapse {
		display: none;
		border: none;
		border-bottom: 1px solid ${(props) => props.theme.new.colors.sol_gray_100};

		.ant-collapse-content-box {
			padding: 0;
		}

		.ant-collapse-header {
			border-top: 1px solid ${(props) => props.theme.new.colors.sol_gray_100};
			flex-direction: row-reverse;

			.ant-collapse-expand-icon {
				padding-inline-end: 0;
			}
		}
	}

	.ant-collapse-header-text {
		color: ${(props) => props.theme.new.colors.sol_gray_900};
		font-size: 14px;
		font-weight: 700;
		line-height: 22px;
	}

	.ant-collapse-item-active {
		.ant-collapse-expand-icon {
			transform: rotate(180deg);
		}
	}

	@media (max-width: 768px) {
		flex-direction: column;
		.ant-collapse {
			display: block;
		}

		li {
			padding: 12px;
		}
	}
`
const HelpCenter = styled.div`
	p {
		font-size: 20px;
		font-weight: 700;
		margin-bottom: 16px;
		white-space: nowrap;
	}

	ul {
		display: flex;
		flex-direction: column;
		gap: 12px;
	}

	li {
		display: flex;

		p {
			margin-bottom: 0;
		}

		button {
			width: 124px;
			padding: 9px 0;

			font-weight: 600;
			color: ${(props) => props.theme.new.colors.sol_gray_500} !important;
			border: 1px solid ${(props) => props.theme.new.colors.sol_gray_200} !important;
			border-radius: 6px;
			height: 100%;
			margin-right: 12px;

			span {
				font-size: 14px;
			}
		}

		:first-child {
			button {
				color: ${(props) => props.theme.new.colors.sol_blue_400} !important;
			}
		}

		p {
			font-size: 12px;
			color: ${(props) => props.theme.new.colors.sol_gray_500};
			font-weight: 400;
			line-height: 1.5;
		}
	}

	@media (max-width: 1200px) {
		margin-right: 32px;
	}
	@media (max-width: 768px) {
		margin-bottom: 20px;
		margin-right: 0;
		li {
			padding: 0;

			p {
				margin-bottom: 0;
			}
		}
	}
`
const CategoryLinkList = styled.div`
	display: flex;
	width: 100%;
	max-width: 800px;
	font-size: 14px;

	> div {
		width: 100%;
		flex: 1;
		display: flex;
		flex-direction: column;

		p {
			font-weight: 700;
			margin-bottom: 12px;
		}

		ul {
			display: flex;
			flex-direction: column;
			gap: 12px;

			li {
				height: 24px;

				a {
					line-height: 22px;
					vertical-align: sub;
				}
			}
		}
	}

	@media (max-width: 768px) {
		display: none;
	}
`
const FooterBottom = styled.div`
	padding-top: 24px;
	border-top: 1px solid ${(props) => props.theme.new.colors.sol_gray_100};
	display: flex;
	justify-content: space-between;
	@media (max-width: 768px) {
		border-top: none;
		padding-top: 0;
		flex-direction: column-reverse;
	}
`
const CompanyInfo = styled.div`
	line-height: 1.5;

	p {
		font-size: 12px;
		color: ${(props) => props.theme.new.colors.sol_gray_500};
	}

	.copyright {
		font-size: 12px;
		margin-top: 16px;
		color: ${(props) => props.theme.new.colors.sol_gray_300};
	}

	.block_m {
		display: none;
	}

	@media (max-width: 1200px) {
		p {
			max-width: 350px;
		}

		.block_m {
			display: block;
			font-size: 0;
		}

		.copyright {
			margin-bottom: 16px;
		}

		br {
			display: none;
		}
	}
	@media (max-width: 768px) {
		border: none;

		.info {
			display: none;
		}
	}
`
const CompanyLink = styled.div`
	display: flex;
	flex-direction: column;
	gap: 28px;
	align-items: flex-end;

	ul {
		display: flex;
	}

	li {
		border-right: 1px solid ${(props) => props.theme.new.colors.sol_gray_100};
		padding: 0 12px;
		font-size: 14px;
		//line-height: 22px;
	}

	li:last-child {
		border-right: none;
		padding-right: 0;
	}

	.social-box {
		display: flex;
		gap: 12px;

		a {
			padding: 8px 12px;
			font-size: 14px;
			background-color: ${(props) => props.theme.new.colors.sol_gray_50};
			border-radius: 6px;
			line-height: 22px;
			align-items: center;
			justify-content: center;
			display: flex;

			i,
			img {
				margin-right: 4px;
			}
		}
	}

	@media (max-width: 768px) {
		align-items: flex-start;
		div {
			margin-bottom: 20px;
		}
	}
`
