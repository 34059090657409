import React from 'react'
import {
	CoreInfoSection,
	SearchListItemContainer,
	SubInfoSection,
} from './SearchListItemComponents'
import { DataTypesWithString } from '../../api/product'
import { SearchProductItem } from '../../api/search'
import { priceWithStringFormatter } from '@/lib/utils'
import { mixpanelEvent } from '@/lib/mixpanels/mixpanel'

export interface SearchListItemWithChipsProps {
	product: SearchProductItem
}

export const SearchListItemView: React.FC<SearchListItemWithChipsProps> = ({ product }) => {
	const coreInfoProps = {
		productId: product.productId,
		title: product.title,
		author: product?.attributes?.handout?.author,
		thumbImg: product?.thumbImg,
		questionNo: product.attributes?.handout?.questionNo,
		avgReviewRating: product?.avgReviewRating && parseFloat(product?.avgReviewRating),
		price: priceWithStringFormatter(product.price),
		editable: product?.attributes?.handout?.editable,
		isCouponAvailable: product?.isCouponAvailable,
	}
	const subInfoProps = {
		relatedBook:
			product?.attributes?.handout?.relatedBooks?.length > 0 &&
			product?.attributes.handout.relatedBooks[0],
		unit: product?.attributes?.handout?.unit,
		dataTypes: product?.attributes?.handout?.dataTypes
			.map((item) => DataTypesWithString[item])
			.join(', '),
	}
	return (
		<SearchListItemContainer
			href={`/products/${product.productId}`}
			onClick={() => {
				mixpanelEvent('Search Item Clicked', {
					'Product ID': product.productId,
					'Product Title': product.title,
					'Coupon Badge': product.isCouponAvailable,
					'Edit Badge': product?.attributes?.handout?.editable,
					'Review Badge': product.avgReviewRating,
				})
			}}
			coreInfoSection={<CoreInfoSection {...coreInfoProps} />}
			subInfoSection={<SubInfoSection {...subInfoProps} />}
		/>
	)
}
