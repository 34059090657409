import { mixpanelEvent } from '@/lib/mixpanels/mixpanel'
import { numberFormatter } from '@/lib/utils'
import { Button, ConfirmDialog } from '@bookips/solvook-ui-library'
import { DialogProps, DrawerProps } from '@mui/material'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useCallback, useState } from 'react'
import { HandoutCoupon, postUserCoupon } from 'src/api/coupon'
import { formatDateToDot } from 'src/utils/formatting'
import styled from 'styled-components'
import FilledButton from '../common/Button/FilledButton'
import AnchorDrawer from '../modal/AnchorDrawer'
import Coupon from './Coupon'
import SimpleDialog from './SimpleDialog'

interface CouponDialogProps extends DialogProps {
	handleClose: () => void
	handoutCoupons: Array<HandoutCoupon>
}

interface CouponDrawerProps extends DrawerProps {
	handleClose: () => void
	handoutCoupons: Array<HandoutCoupon>
}

const CouponContents = ({ handoutCoupons }: { handoutCoupons: Array<HandoutCoupon> }) => {
	//자 여기에서 실행하면 안될것 같지만 일단 기능부터
	const queryClient = useQueryClient()
	const postGetCouponMutation = useMutation(postUserCoupon)

	const [dialogOpen, setDialogOpen] = useState(false)
	const [errorMessage, setErrorMessage] = useState('')

	const onGetCouponClick = useCallback(
		async (id: string, name: string) => {
			try {
				const response = await postGetCouponMutation.mutateAsync(id)
				queryClient.invalidateQueries(['myCoupons'])
				//mx
				mixpanelEvent('Coupon Issued', {
					'Coupon ID': id,
					'Coupon Name': name,
				})

				if (response.status === 404) {
					// Coupon not found
					setDialogOpen(true)
					setErrorMessage('쿠폰 정보가 존재하지 않아요.')
				}
				if (response.status === 409) {
					// Already received
					setDialogOpen(true)
					setErrorMessage('이미 발급된 쿠폰이예요.')
				}
			} catch (error) {
				const { status } = error.response
				if (status === 404) {
					// Coupon not found
					setDialogOpen(true)
					setErrorMessage('쿠폰 정보가 존재하지 않아요.')
				}
				if (status === 409) {
					// Already received
					setDialogOpen(true)
					setErrorMessage('이미 발급된 쿠폰이예요.')
				}
			}
		},
		[postGetCouponMutation],
	)

	return (
		<CouponContainer>
			{errorMessage && (
				<ConfirmDialog
					open={dialogOpen}
					severity="caution"
					closeDialog={() => setDialogOpen(false)}
					title={errorMessage}
					footer={
						<ButtonOneBox>
							<Button
								label="확인"
								variant="solid"
								onClick={() => setDialogOpen(false)}
							/>
						</ButtonOneBox>
					}
				/>
			)}
			<CouponCount>
				상품쿠폰 <span>{handoutCoupons.length}장</span>
			</CouponCount>
			<CouponList>
				{handoutCoupons.map((coupon, index) => (
					<Coupon key={index}>
						<Coupon.Content>
							<div>
								<Coupon.Title>{coupon.name}</Coupon.Title>
								<Coupon.MaxDiscount>
									{`최대 ${numberFormatter(coupon.discountMaximumPrice)}원 할인`}
								</Coupon.MaxDiscount>
								{coupon?.minimumPrice && (
									<Coupon.Condition>
										{numberFormatter(coupon.minimumPrice)}원 이상 자료에
										사용가능
									</Coupon.Condition>
								)}
							</div>
							<Coupon.ExpireDate>
								{coupon.expiredAfterDay
									? `받기 후 ${coupon.expiredAfterDay}일간 사용 가능`
									: coupon.expiredAt &&
									  formatDateToDot(coupon.expiredAt) + '까지'}
							</Coupon.ExpireDate>
						</Coupon.Content>
						<Coupon.Possession
							status={coupon.hasBeenReceived}
							onHandleClick={() => {
								if (coupon.hasBeenReceived === false)
									onGetCouponClick(coupon.id, coupon.name)
							}}
						/>
					</Coupon>
				))}
			</CouponList>
		</CouponContainer>
	)
}

export const AdditionalCouponDialog = ({
	open,
	handleClose,
	handoutCoupons,
}: CouponDialogProps) => {
	return (
		<SimpleDialog
			open={open}
			onClose={() => handleClose()}
			title="할인쿠폰 받기"
			subParagraph="해당 상품에 적용가능한 쿠폰만 표시돼요."
			scroll="paper"
			footer={
				<ButtonBox>
					<FilledButton type="text" variant="solid" onClick={() => handleClose()}>
						확인
					</FilledButton>
				</ButtonBox>
			}>
			<DialogContents>
				<CouponContents handoutCoupons={handoutCoupons} />
			</DialogContents>
		</SimpleDialog>
	)
}

export const AdditionalCouponDrawer = ({
	open,
	anchor,
	handleClose,
	handoutCoupons,
}: CouponDrawerProps) => {
	return (
		<AnchorDrawer
			anchor={anchor ?? 'bottom'}
			open={open}
			onClose={() => handleClose()}
			title="할인쿠폰 받기">
			<AnchorDrawer.Contents>
				<AnchorDrawer.Title>할인쿠폰 받기</AnchorDrawer.Title>
				<ExplainText>해당 상품에 적용가능한 쿠폰만 표시돼요.</ExplainText>
				<ScrollBody>
					<CouponContents handoutCoupons={handoutCoupons} />
				</ScrollBody>
			</AnchorDrawer.Contents>
			<ButtonBox>
				<FilledButton type="text" variant="solid" onClick={() => handleClose()}>
					확인
				</FilledButton>
			</ButtonBox>
		</AnchorDrawer>
	)
}
export const ExplainText = styled.p`
	font-size: 16px;
	font-style: normal;
	font-weight: 400;
	line-height: 24px; /* 150% */
	letter-spacing: -0.16px;
	color: ${(props) => props.theme.new.colors.sol_gray_700};
`

const CouponCount = styled.p`
	font-size: 16px;
	font-style: normal;
	font-weight: 600;
	line-height: 24px; /* 150% */
	letter-spacing: -0.16px;

	span {
		color: ${(props) => props.theme.new.colors.sol_blue_500};
	}
`
const DialogContents = styled.div``
const CouponContainer = styled.div`
	display: flex;
	height: 100%;
	flex-direction: column;
	gap: 12px;

	overflow-y: auto;
`
const CouponList = styled.ul`
	display: flex;

	flex-direction: column;
	gap: 12px;
`

const ButtonBox = styled.div`
	display: flex;
	justify-content: flex-end;
	padding-top: 16px;

	padding: 16px 28px 28px 28px;
	background-color: ${(props) => props.theme.new.colors.sol_dim_white_90};

	@media (max-width: 768px) {
		padding: 16px;

		button {
			width: 100%;
		}
	}

	button {
		font-size: 16px;
		font-style: normal;
		font-weight: 600;
		line-height: 24px; /* 150% */
		letter-spacing: -0.16px;
		padding: 12px 20px;
	}
`
export const ScrollBody = styled.div`
	height: 100%;
	max-height: calc(100vh - 224px);
	overflow-y: auto;
`

const ButtonOneBox = styled.div`
	display: flex;
	justify-content: end;
	align-items: center;
	margin-top: 28px;

	@media (max-width: 768px) {
		button {
			width: 100%;
		}
	}
`
