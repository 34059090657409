import React from 'react'
import { SearchTextField } from '@/components/common/TextField/searchTextField'
import { styled as muiStyled } from '@mui/material'
import { SearchRecommendDialog } from '@/components/SearchRecommend/SearchRecommendDialog'
import { useSearchWithSearchDialog } from '@/components/Search/useSearchWithSearchTerms'

export interface SearchFormDataProps {
	keyword: string
}

export type SearchFormProps = {}
export const SearchRequestForm: React.FC<SearchFormProps> = ({}) => {
	const {
		openSearchDialog,
		recommendSearchTermList,
		currentSearchTerm,
		isOpenSearchDialog,
		closeSearchDialog,
		searchTermValue,
		onClearSearchTerm,
		onChangeSearchTerm,
		handleSelectedSubject,
		selectedSubject,
		onSubmitSearchTerm,
		onSelectRecommendSearchTerm,
	} = useSearchWithSearchDialog()

	return (
		<SearchFormContainer>
			<SearchTextField
				readOnly={true}
				onClickForm={openSearchDialog}
				placeholder={recommendSearchTermList.placeHolder}
				name={'keyword'}
				value={currentSearchTerm}
			/>
			<SearchRecommendDialog
				isOpen={isOpenSearchDialog}
				onClose={closeSearchDialog}
				modalValue={searchTermValue}
				onClear={onClearSearchTerm}
				onChange={onChangeSearchTerm}
				currentSubject={selectedSubject}
				handleCurrentSubject={handleSelectedSubject}
				onSubmit={onSubmitSearchTerm}
				onClick={onSelectRecommendSearchTerm}
				recommendSearchTermList={recommendSearchTermList}
			/>
		</SearchFormContainer>
	)
}

const SearchFormContainer = muiStyled('div')(({ theme }) => ({
	flex: '1',
	width: '100%',
	display: 'flex',
	justifyContent: 'center',
	// transform: 'translateX(7px)',
	[theme.breakpoints.down('lg')]: {
		transform: 'translateX(0)',
		flex: ' 1 1 auto',
		alignItems: 'center',
		justifyContent: 'center',
		display: 'flex',
	},
	form: {
		width: '400px',
		[theme.breakpoints.down('sm')]: {
			width: '100%',
		},
	},
}))
