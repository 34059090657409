import { otherApiClient } from '.'
export interface PostBooksOrderResponse {
	message: string
	statusCode?: number
	error?: string
}

export interface UniversityBannerItem {
	bgColor: string // hex color 배경색
	imageUrlPc: string
	imageUrlTablet: string
	imageUrlMobile: string
	link: string
}
export interface getUniversityAdBannerResponse {
	items: Array<UniversityBannerItem>
}
export const PostRegenerateLink = async (orderId: string) => {
	const { data } = await otherApiClient.market.post<PostBooksOrderResponse>(
		`/ebooks/regenerate-link`,
		{ orderId },
	)
	return data
}

// 대학생용 홈에서 사용하는 배너 api 입니다
export const getUniversityAdBanner = async () => {
	const { data } = await otherApiClient.market<getUniversityAdBannerResponse>(
		`/ebooks/univ/banners`,
	)
	return data
}
