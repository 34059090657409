import { SearchFilteredProductEmpty } from '@/components/Search/SearchFilteredProductEmpty'
import { SearchProductEmpty } from '@/components/Search/SearchProductEmpty'
import { SkeletonListItem } from '@/components/Search/SkeletonListItem'
import React from 'react'
import styled from 'styled-components'
import { IGetSearchProductsRes } from '../../api/search'
import { SearchListItemView } from './SearchListItemView'

export interface SearchListProps {
	list: {
		pages: Array<IGetSearchProductsRes>
	}
	isLoading: boolean
	isFetchingNextPage: boolean
	hasNextPage: boolean
	emptyStatus: 'FILTER' | 'ITEM'
}

export const SearchProductListView: React.FC<SearchListProps> = ({
	list,
	isLoading,
	isFetchingNextPage,
	hasNextPage,
	emptyStatus,
}) => {
	if (isLoading)
		return (
			<NewSearchListWrapper>
				{[...Array(10)].map((_, index) => (
					<SkeletonListItem key={index} />
				))}
			</NewSearchListWrapper>
		)
	if (emptyStatus === 'FILTER') {
		return <SearchFilteredProductEmpty />
	}
	if (emptyStatus === 'ITEM') return <SearchProductEmpty />
	return (
		<>
			<NewSearchListWrapper>
				{list.pages.map((page, idx) => {
					return (
						<React.Fragment key={`search-list-data-${idx}`}>
							{page?.data.map((product) => {
								if (!!!product) return null
								return (
									<SearchListItemView key={product.productId} product={product} />
								)
							})}
						</React.Fragment>
					)
				})}
			</NewSearchListWrapper>
			{isFetchingNextPage && hasNextPage && (
				<NewSearchListWrapper>
					{[...Array(2)].map((_, index) => (
						<SkeletonListItem key={index} />
					))}
				</NewSearchListWrapper>
			)}
		</>
	)
}
//@fixme: 추후삭제 (알라딘 굿노트 사용중)
export const SearchListWrapper = styled.div`
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;
	background-color: #fff;
	@media (max-width: 768px) {
		display: flex;
		margin: 0 -16px;
		padding: 0;
		gap: 0;
	}
`
export const NewSearchListWrapper = styled.div`
	background-color: ${(props) => props.theme.new.colors.sol_gray_0};
	justify-content: space-between;
	flex-wrap: wrap;
	gap: 20px;
	display: grid;
	grid-template-columns: repeat(auto-fill, minmax(420px, 1fr));

	@media (max-width: 768px) {
		display: flex;
		margin: 0 -16px;
		padding: 0;
		gap: 0;
	}
`
