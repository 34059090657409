import { Skeleton } from 'antd'
import React from 'react'
import styled from 'styled-components'

export interface SkeletonListItemProps {}

export const SkeletonListItem: React.FC<SkeletonListItemProps> = ({}) => {
	const active = true
	const buttonShape = 'default'
	const size = 'default'
	const block = false
	const avatarShape = 'square'
	return (
		<SkeletonListItemWrapper className={'item-wrapper'}>
			<Skeleton.Avatar active={active} size={size} shape={avatarShape} />
			<div className={'contents'}>
				<div className={'content-top'}>
					<Skeleton.Input active={active} size={size} />
					<Skeleton.Button
						active={active}
						size={size}
						shape={buttonShape}
						block={block}
					/>
				</div>

				<div className={'content-bottom'}>
					<Skeleton.Input active={active} size={size} />
					<div className={'tags-wrapper'}>
						<Skeleton.Button
							active={active}
							size={size}
							shape={buttonShape}
							block={block}
						/>
						<Skeleton.Button
							active={active}
							size={size}
							shape={buttonShape}
							block={block}
						/>
						<Skeleton.Button
							active={active}
							size={size}
							shape={buttonShape}
							block={block}
						/>
						<Skeleton.Button
							active={active}
							size={size}
							shape={buttonShape}
							block={block}
						/>
					</div>
				</div>
			</div>
		</SkeletonListItemWrapper>
	)
}

const SkeletonListItemWrapper = styled.div`
	width: 100%;
	display: flex;
	padding: 28px;
	padding-right: 69px;
	background-color: #fff;
	min-height: 201px;
	border-radius: 6px;
	box-shadow: rgba(0, 0, 0, 0.08) 0px 1px 2px 0px, rgba(0, 0, 0, 0.04) 0px 3px 3px 0px;
	@media (max-width: 1200px) {
		padding: 16px;
		padding-right: 83px;
		width: 100%;
	}

	.ant-skeleton.ant-skeleton-element .ant-skeleton-avatar {
		width: 120px;
		height: 145px;
		background-color: #e7e7e9;
		border-radius: 4px;
		@media (max-width: 1200px) {
			width: 78px;
			height: 94px;
		}
	}

	.contents {
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		margin-left: 16px;
		width: 100%;

		.content-top {
			display: flex;
			flex-direction: column;
			gap: 8px;
			@media (max-width: 1200px) {
				gap: 4px;
			}

			.ant-skeleton.ant-skeleton-element {
				.ant-skeleton-input {
					height: 28px;
					width: 100%;
					background-color: #e7e7e9;
					border-radius: 4px;
					@media (max-width: 1200px) {
						height: 20px;
					}
				}

				.ant-skeleton-button {
					height: 20px;
					width: 91px;
					background-color: #e7e7e9;
					border-radius: 4px;
					@media (max-width: 1200px) {
						height: 16px;
						width: 103px;
					}
				}
			}
		}

		.content-bottom {
			gap: 8px;
			display: flex;
			flex-direction: column;

			.ant-skeleton.ant-skeleton-element {
				.ant-skeleton-input {
					height: 28px;
					width: 119px;
					background-color: #e7e7e9;
					border-radius: 4px;
					@media (max-width: 1200px) {
						height: 20px;
						width: 86px;
						min-width: 86px;
						margin-bottom: 18px;
					}
				}
			}

			.tags-wrapper {
				display: flex;
				gap: 6px;
				@media (max-width: 1200px) {
					display: none;
				}

				.ant-skeleton.ant-skeleton-element {
					&:nth-last-child(2) {
						display: none;
					}

					.ant-skeleton-button {
						height: 20px;
						width: 65px;
						background-color: #e7e7e9;
						border-radius: 4px;
					}
				}
			}
		}
	}
`
