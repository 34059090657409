import { AxiosError } from 'axios'

export * from './ICommand'
export * from './RestfulApiService'

export const isAxiosError = (error: any): error is AxiosError => !!error.isAxiosError

export interface DefaultResponse {
	message: string
	statusCode?: number
	error?: string
}
