import { themeGet } from '@styled-system/theme-get'
import { createGlobalStyle } from 'styled-components'

export const GlobalStyles = createGlobalStyle`
  * {
    box-sizing: inherit;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
     font-family: 'Pretendard', sans-serif;
    &::before,
    &::after {
      box-sizing: inherit;
    }
  }

  ::selection {
    background: ${themeGet('primary.0', '#008489')};
    color: ${themeGet('color.1', '#ffffff')};
  }

  html {
    box-sizing: border-box;
    -ms-overflow-style: scrollbar;
  }

  html,
  html a,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  a,
  p,
  li,
  dl,
  th,
  dt,
  input,
  textarea,
  div {
    color: ${(props) => props.theme.new.colors.sol_gray_900};
    //font-family: 'Pretendard', 'Noto Sans KR', sans-serif;
    font-family: 'Pretendard', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    //text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.004);
    -webkit-tap-highlight-color: rgba(0,0,0,0);
    -webkit-tap-highlight-color: transparent;
  }

  //@font-face {
	//font-family: 'Noto Sans KR';
  //  src: url('/static/fonts/NotoSansKR-Regular.woff2') format('woff2'),
  //  url('/static/fonts/NotoSansKR-Regular.woff') format('woff'),
  //  url('/static/fonts/NotoSansKR-Regular.otf') format('otf');
  //  font-weight: 400;
  //  font-style: normal;
  //}

  //@font-face {
  //  font-family: 'Pretendard';
  //  src: url('/static/fonts/Pretendard-Regular.woff2') format('woff2'),
  //  url('/static/fonts/Pretendard-Regular.woff') format('woff');
  //  font-weight: 400;
  //  font-style: normal;
  //}

  //@font-face {
  //  font-family: 'Pretendard';
  //  src: url('/static/fonts/Pretendard-Bold.woff2') format('woff2'),
  //  url('/static/fonts/Pretendard-Bold.woff') format('woff');
  //  font-weight: 800;
  //  font-style: normal;
  //}

  //@font-face {
  //  font-family: 'Pretendard';
  //  src: url('/static/fonts/Pretendard-ExtraBold.woff2') format('woff2'),
  //  url('/static/fonts/Pretendard-ExtraBold.woff') format('woff');
  //  font-weight: 900;
  //  font-style: normal;
  //}

  body {
    margin: 0;
    padding: 0;
    overflow-x: hidden !important;
    //font-family: 'Pretendard', 'Noto Sans KR', sans-serif;
    font-family: 'Pretendard', sans-serif;
    -webkit-tap-highlight-color: transparent;
  }

  ul {
    margin: 0;
    padding: 0;
  }

  li {
    list-style-type: none;
  }

  a {
    text-decoration: none;
    cursor: pointer;
  }
  p {
    margin:0;
  }
  button {
    cursor: pointer;
  }
  strong {
    font-weight: 700;
  }
  img {
    max-width: 100%;
    height: auto;
  }
  em {
    font-style: normal;
  }

  #__next{
	height: 100%;
  }

  #root,
  #__next {
    > .ant-layout {
      max-height: 100vh;
      overflow: hidden;

      /* hotel submission style  */ 
      .hotel-submission-form {
        .ant-progress-outer {
          position: fixed;
          z-index: 9;
          left: 0;
          top: auto;
        }
        .gm-style {
          > input[type="text"] {
            left: 9px !important;
            top: 46px !important;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
          }
        }
   
        .hotel-form-location {
          .ant-card-head-title {
            font-size: 16px;
            line-height: 18px;
            font-weight: 700;
            color: ${themeGet('text.0', '#2C2C2C')};
          }
          .ant-card-body p {
            display: flex;
            color: ${themeGet('text.2', '#777777')};
            justify-content: flex-start;
            strong {
              color: ${themeGet('text.0', '#2C2C2C')};
              width: 30%;
            }
          }
        }
      }
    }
  }
  
  
  // antd button common
  .ant-btn{
	box-sizing: border-box;
    outline: none;
    //position: relative;
    //display: inline-block;
    font-size: 14px;
    //height: 32px;
    font-weight: 400;
    white-space: nowrap;
    text-align: center;
    padding: 4px 15px;
    //border-radius: 2px;
    background-image: none;
    background-color: transparent;
    border: 1px solid transparent;
    cursor: pointer;
    transition: all 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
    user-select: none;
    touch-action: manipulation;
    line-height: 1.5714285714285714;
    color: rgba(0, 0, 0, 0.88);
	
	&.ant-btn-block{
	  width: 100%;
	}
  }

  .ant-btn-primary{
    color: #fff;
    background-color: #6857E5;
    box-shadow: 0 2px 0 rgba(13, 66, 134, 0.19);
  }

  .ant-btn-default{
    background-color: #ffffff;
    border-color: #d9d9d9;
    box-shadow: 0 2px 0 rgba(0, 0, 0, 0.02);
  }
  
  .ant-btn-background-ghost{
    color: #6857E5;
    background-color: transparent;
    border-color: #6857E5;
    box-shadow: none;
	
	.anticon + span{
	  margin-left: 8px;
	}
  }

  
  // channel.io mobile
  div[data-ch-testid='launcher'] {
    @media (max-width: 768px) {
      bottom: 68px !important;
    }
  }

  [data-ch-testid='launcher'] {
    @media (max-width: 768px) {
      bottom: 68px !important;
    }
  }

  .kpPUDB {
    @media (max-width: 768px) {
      bottom: 68px !important;
    }
  }
  
  /*------------------------------------------*/ 
  // style for validtaion text
  /*------------------------------------------*/
  .ant-form-item-explain{
    height: 20px !important;

    @media (max-width: 1200px){}
    @media (max-width: 768px){
	  height: 24px !important;
	  
	  li{
		margin-top: 4px !important;
	  }
	}
  }
  

  /*------------------------------------------*/ 
  // style for transparent sticky header
  /*------------------------------------------*/
  .isHeaderSticky {
    .sticky-inner-wrapper {
      .navbar {
        &.is_transparent {
          background-color: ${themeGet('color.1', '#ffffff')};
          box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
          .ant-menu {
            li {
              a {
                color: ${themeGet('text.0', '#2C2C2C')};
                &.active {
                  border-bottom: 3px solid ${themeGet('primary.0', '#008489')};
                }
              }
              &.ant-menu-item:active, .ant-menu-submenu-title:active {
              	background-color: ${themeGet('color.2', 'transparent')};
              }
            }
          }
          .auth_menu {
            .ant-menu {
              li {
                &:last-child {
                  a {
                    color: ${themeGet('color.1', '#ffffff')};
                  }
                }
              }
            }
          }
        }
      }
      nav.transparent {
        background-color: ${themeGet('color.1', '#ffffff')};
        box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);

        .hamburg-btn {
          > span {
            background-color: ${themeGet('text.0', '#2C2C2C')};
          }
        }
      }
    }
  }

  
  /*------------------------------------------*/ 
  // style for input
  /*------------------------------------------*/
  .ant-input{
	box-shadow: none !important
  }

  
  /*------------------------------------------*/ 
  // style for date-picker
  /*------------------------------------------*/
  .ant-picker{
	box-shadow: none !important
  }
  

  /*------------------------------------------*/ 
  // style for navbar profile / account menu
  /*------------------------------------------*/
  .ant-popover {
    &.profile_menu {
      z-index: 9999;
    }
    .ant-popover-content {
      .ant-popover-inner {
        .ant-popover-inner-content {
          ul.ant-menu  {
            border: 0;
            &.account_menu {
              li {
                color: ${themeGet('text.0', '#2C2C2C')};
                font-size: 16px;
                line-height: 18px;
                font-weight: 400;
                height: auto;
                transition: color 0.2s ease-in-out;
                &.ant-menu-item-selected,
                &.ant-menu-item-active {
                  background-color: transparent;
                }
                a {
                  padding: 8px 0;
                  color: ${themeGet('text.0', '#2C2C2C')};
                  transition: color 0.2s ease-in-out;
                  &:hover {
                    color: ${themeGet('primary.0', '#008489')};
                  }
                  &.active {
                    font-weight: 700;
                    color: ${themeGet('primary.0', '#008489')};
                  }
                }
                button {
                  padding: 0;
                  border: 0;
                  cursor: pointer;
                  padding: 8px 0;
                  background-color: transparent;
                  transition: color 0.2s ease-in-out;
                  &:hover {
                    color: ${themeGet('primary.0', '#008489')};
                  }
                  &:focus {
                    outline: none;
                  }
                }
              }
            }
          }
        }
      }
    }
	&.infoPopup-price{
	 .ant-popover-content{
	  .ant-popover-arrow{
	    &:before{
		  background-color: ${themeGet('color.0', '#1B1B29')};
	    }
	  }
	  .ant-popover-inner{
	    padding: 0 16px;
	    height: 40px;
	    line-height: 40px;
	    background-color: ${themeGet('color.0', '#1B1B29')};
	    .ant-popconfirm-inner-content{
		 .ant-popconfirm-message-title{
			color: #fff;
	     }
	    }
	  }
	 }
	}
	&.confirmCart{
	  .ant-popover-content {
		.ant-popover-arrow{
		  height: 18px;
		  
		  &:before{
			background-color: ${themeGet('color.0', '#1B1B29')};
		  }
		}
		  .ant-popover-inner {
			padding: 18px 28px 16px;
			border-radius: 3px;
			background-color: ${themeGet('color.0', '#1B1B29')};
			
			.ant-popconfirm-inner-content {
			  .ant-popconfirm-message{
				margin-bottom: 4px;
				
				.ant-popconfirm-message-icon{
				  display: none;
				}
				.ant-popconfirm-message-title{
				  font-size: 20px;
				  color: #fff;
				  margin-left: 0;
				}
			  }
			  .ant-popconfirm-buttons{
				.ant-btn{
				  justify-content: center;
				  width: 100%;
				  color: #fff;
				  margin: 0;
				  background-color: ${themeGet('color.0', '#1B1B29')};
				}
				
				.confirmBtn{
                  display: flex;
				  align-items: center;
				  flex-direction: row-reverse;
				  font-size: 18px;
				  
				  &:before{
					display: block;
					content: '';
					position: absolute;
					bottom: -16px;
					left: -28px;
					width: 238px;
					height: 94px;
                    z-index: 1;
				  }
				  
				  img{
					display: inline-block;
					width: 18px;
					height: 18px;
				  }
				}
				.closeBtn{
                  position: absolute;
				  top: 0;
				  right: 0;
				  width: 28px;
				  height: 28px;
				  font-size: 0;
                  z-index: 5;
				  border: 0;
				  background: url('/static/assets/ico-close-white.svg') no-repeat center center;
				  background-size: 16px;
				}
			  }
			  
			}
		  }
		}
	}
  }

  /*------------------------------------------*/ 
  // style for date range picker
  /*------------------------------------------*/
  .DateRangePicker {
    .DateRangePickerInput {
      .DateRangePicker_picker {
        .DayPicker_weekHeader {
          .DayPicker_weekHeader_ul {
            .DayPicker_weekHeader_li {
              color: ${themeGet('text.0', '#2C2C2C')};
              small {
                font-size: 13px;
              }
            }
          }
        }
        .DayPicker_focusRegion {
          .CalendarMonthGrid {
            .CalendarMonth {
              .CalendarMonth_caption {
                font-size: 16px;
                color: ${themeGet('text.0', '#2C2C2C')};
              }
            }
          }
        }
        .DayPickerNavigation {
          .DayPickerNavigation_button {
            &:focus {
              outline: none;
            }
          }
        }
        .DayPickerKeyboardShortcuts_buttonReset {
          &:focus {
            outline: none;
          }
        }
      }
    }
  }

  /*------------------------------------------*/ 
  // style for ant tooltip
  /*------------------------------------------*/
  .ant-tooltip {
    font-size: 13px;
    font-weight: 700;
    &.ant-tooltip-placement-top .ant-tooltip-arrow {
      border-top-color: ${themeGet('primary.0', '#008489')};
    }
    .ant-tooltip-inner {
      box-shadow: none;
      text-align: center;
      border-radius: 4px;
      min-height: 26px;
      padding: 4px 10px;
      background-color: ${themeGet('primary.0', '#008489')};
    }

    #library-item-title-tooltip {
      margin-top: -10px;
      margin-left: 40px;
      padding: 8px 12px;
      background-color: rgba(28, 31, 46, 0.50);
      font-size: 14px;
      font-weight: 400;
      line-height: 22px; /* 157.143% */
      letter-spacing: -0.14px;
      white-space: nowrap;

      @media (max-width: 768px) {
        margin-left: 0;
        white-space: normal;
      }
    }
    &:has(#library-item-title-tooltip) {
      max-width: 90vw;

      .ant-tooltip-arrow {
        display: none
      }
    }
  }

  /*------------------------------------------*/ 
  // style for dropdown
  /*------------------------------------------*/
  .ant-select-dropdown {
	padding: 0;
	margin-top: -4px;
	border-radius: 0;
    z-index: 99999;
	
    .ant-select-item{
      border-radius: 0;
    }
    .ant-select-dropdown-menu {
      .ant-select-dropdown-menu-item {
        padding: 12px 12px;
        line-height: 1;
        &.ant-select-dropdown-menu-item-active {
          background-color: rgba(0, 132, 137, 0.1);
        }
      }
    }
	
	// small type select box
	&.small-type-select-popup{
	  & > div{
		margin-top: -4px;
	  }

      .ant-select-item{
		font-size: 12px;
	  }
	}
  }
  .ant-select-dropdown .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
    background-color:#efeff0;
  }
  /*------------------------------------------*/ 
  // style for date-picker 
  /*------------------------------------------*/
  .ant-picker-dropdown{
    z-index: 99999;
  }

  /*------------------------------------------*/ 
  // here fix height issue for RoomGuest 
  /*------------------------------------------*/
  .view_with__popup {
    &.room_guest__component {
      &.active {
        min-height: 54px;
      }
      &.alt {
        .popup_container {
          #popup {
            > div {
              > div {
                padding: 0;
              }
            }
          }
        }
        &.active {
          min-height: inherit;
        }
      }
    }
  }

  /*------------------------------------------*/ 
  // ant dropdown and social share menu style 
  /*------------------------------------------*/
  .ant-dropdown {
	padding-top: 8px;
	
	.ant-dropdown-menu {
	  padding: 4px 0;
	  border-radius: 0;
      box-shadow: 0 9px 28px 8px rgba(0, 0, 0, 0.05), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 3px 6px -4px rgba(0, 0, 0, 0.12);
	  
	  .ant-dropdown-menu-item {
		font-size: 16px;
		color: ${themeGet('text.0', '#1C1F2E')};
		line-height: 24px;
		padding: 4px 24px;
	  }
	}
	&.util_dropdown {
		.ant-dropdown-menu-title-content {
						text-align: center;
          				line-height: 32px;
		}
	
	}
    &.social_share_menu {
      z-index: 9999 !important;
      
      .ant-menu {
        padding: 7px 0;
        min-width: 150px;
        border: 0;
        border-radius: 4px;
        box-shadow: 0 2px 8px rgba(0,0,0,0.15);
    
        .ant-menu-item {
          margin: 0;
          padding: 0;
          height: inherit;
          line-height: inherit;
          > div {
            padding: 15px 20px;
            color: ${themeGet('text.0', '#2C2C2C')};
            svg {
              margin-right: 8px;
            }
          }

          &:hover {
            background-color: ${themeGet('color.2', '#F7F7F7')};
          }
        }
      }
    }

	&.mobile-gnb-dropdown{
	  width: 100%;
	  padding-top: 0;
	  
	  .ant-dropdown-menu{
		padding:16px 0;
		box-shadow: none;
        .ant-dropdown-menu-item-group {
          margin-left: 18px;
          .ant-dropdown-menu-item-group-title {
            font-size: 16px;
          }
        }
		.ant-dropdown-menu-item{
		  padding: 0 32px;
		  
		  &:nth-of-type(5){
			padding-bottom: 16px;
			margin-bottom: 16px;
			border-bottom: solid 1px ${themeGet('border.0', '#F3F3F#')};
			 a {
				 font-weight: 600;
				 color: ${(props) => props.theme.new.colors.green};
			}
		  }
		  
		  a, span{
			font-size: 16px;
			line-height: 1.5;
			color: ${themeGet('text.0', '#1B1B29')};
			padding: 4px 0;
		  }
		  
		  a{
            display: block;
		  }
		  
		  .ant-btn{
            display: block;
			width: 100%;
			text-align: left;
			padding: 0;
			border: none;
			box-shadow: none;
			background-color: transparent;
			
			span{
			  text-shadow: none;
			}
		  }
		}
	  }

      @media (max-width: 768px) {
      }
      @media (max-width: 1200px) {
      }
	}
  }

  /*------------------------------------------*/
  // ant button style 
  /*------------------------------------------*/
  .ant-btn{
    outline: none !important;
    box-shadow: none !important;

    &[ant-click-animating-without-extra-node='true']::after{
      display: none !important;
    }
  }


  /*------------------------------------------*/
  // ant upload style 
  /*------------------------------------------*/
  .ant-upload-wrapper{
	.ant-tooltip{
	  display: none;
	}
  }


  /*------------------------------------------*/
  // ant modal and drawer style 
  /*------------------------------------------*/
  .ant-drawer,
  .ant-modal-mask,
  .ant-modal-wrap  {
    z-index: 1005 
  }
  .ant-drawer {
    &.filter_drawer {
      pointer-events: none;
      .ant-drawer-mask {
        display: none;
      }
      &.ant-drawer-bottom {
        &.ant-drawer-open {
          .ant-drawer-content-wrapper {
            box-shadow: none;
          }
        }
      }
      .ant-drawer-content-wrapper {
        height: calc(100vh - 152px) !important;
        pointer-events: all;
        .ant-drawer-wrapper-body {
          height: 100%;
          .ant-drawer-body {
            height: 100%;
            padding-top: 0;
            overflow: hidden;
          }
        }
      }
    }
  }

  .ant-modal-wrap {
    .ant-modal-content {
      box-shadow: 0 1px 10px rgba(0,0,0,0.16);
    }
    &.review_modal {
      .ant-modal {
        max-width: 1200px;
        box-sizing: border-box;
        @media only screen and (max-width: 1200px) {
          padding-left: 30px;
          padding-right: 30px;
        }
        @media only screen and (max-width: 767px) {
          top: 0;
          padding: 0;
          margin-top: 0;
          margin-bottom: 0;
        }
        .ant-modal-body {
          padding-left: 60px;
          padding-right: 60px;
          padding-bottom: 60px;
          padding-top: 30px;
          @media only screen and (max-width: 1200px) {
            padding: 40px 30px;
          }
        }
      }
      .image_uploader{
        display: flex;
        flex-direction: row-reverse;
        justify-content: flex-end;
        @media only screen and (max-width: 667px) {
          flex-wrap: wrap;
        }
        .ant-upload{
          @media only screen and (max-width: 667px) {
            margin-bottom: 10px;
          }
          &.ant-upload-drag{
            border: 0;
            border-radius: 0;
            background: transparent;
            .ant-upload{
              padding: 0;
              display: block;
              width: auto;
              height:auto;
            }
          }
          &.ant-upload-drag-hover{
            .image-drag-area{
              border-color: #48bdc1;
            }
          }
        }
        .ant-upload-list{
          float: none;
          display: flex;
          .ant-upload-list-item{
            width: 125px;
            height: 125px;
            border-radius: 4px;
          }
        }
        .ant-upload-drag-container{
          display: block;
        }
      }
    }
  }

  .ant-modal-wrap{
    &.main-description-popup{
      .ant-modal-content{
        padding: 0;
        border-radius: 2px;
      }
    }
  }
  	.mobile-filter-slider {
			.ant-drawer-wrapper-body {
				.ant-drawer-header {
					padding: 12px 16px;
					border-bottom: 1px solid ${themeGet('border.3', '#E8E8EA')};
                  .ant-drawer-header-title {
                    line-height: 1.5;
                    .title {
                      display: flex;
                      align-items: center;
                      justify-content: space-between;
                      line-height: 1.5;
                      p {
                        margin: 0;
                        line-height: 1.5;
                        
                      }
                      img {
                        cursor: pointer;
                        filter: brightness(0%);
                      }
                    }
                    .ant-drawer-close {
                      display: none;
                    }
                  }
				}
			  
				.ant-drawer-body {
					padding: 0;
				}
			}
	}
  /*------------------------------------------*/ 
  // map info window style 
  /*------------------------------------------*/
  .gm-style-iw-c {
    box-shadow: none !important;
  }
  .gm-style-iw {
    padding: 0 !important;
    border-radius: 4px !important;
    width: 270px !important;
    z-index: 1;
    .gm-style-iw-d {
      overflow-y: auto !important;
      overflow-x: hidden !important;
      max-width: 100% !important;
    }
    .info_window_card {
      width: 270px;
      margin-bottom: 0;
      > div {
        > img {
          width: 100%;
          height: 190px;
          object-fit: cover;
          border-bottom-left-radius: 0;
          border-bottom-right-radius: 0;
        }
      }
      .content_wrapper {
        padding: 10px 15px 20px;
      }
    }
    button[title="Close"].gm-ui-hover-effect {
      width: 26px !important;
      height: 26px !important;
      padding: 6px !important;
      border-radius: 50%;
      margin: 0 !important;
      top: 14px !important;
      right: 11px !important;
      opacity: 1;
      align-items: center;
      display: inline-flex !important;
      background-color: ${themeGet('color.1', '#ffffff')} !important;
      box-shadow: 0 3px 6px rgba(0,0,0,0.16);
      > img {
        margin: 0 !important;
      }
    }
  }

/*------------------------------------------*/
// Image Gallery Modal style
/*------------------------------------------*/
.ant-modal-wrap {
  &.image_gallery_modal {
    .ant-modal {
      top: 50px;
      .ant-modal-content{
        box-shadow: none;
        padding: 0 30px;
        background: transparent;
        @media only screen and (max-width: 480px) {
          padding: 0 20px;
        }
        .ant-modal-body {
          max-width: 1200px;
          padding: 0;
          margin: 0 auto; 
          @media only screen and (max-width: 1440px) {
            position: relative;
          }
        }
      }
      .image_gallery_close {
        background: transparent;
        border: 0;
        width: 60px;
        height: 60px;
        position: absolute;
        top: -20px;
        right: 40px;
        &:focus,
        &:hover {
          outline: none;
        }
        @media only screen and (max-width: 1440px) {
          top: 0;
          right: 0;
          svg {
            path {
              opacity: 0.8;
              fill: ${themeGet('color.1', '#ffffff')};
              transition: all 0.3s ease;
            }
          }
          &:hover {
            svg {
              path {
                opacity: 1;
              }
            }
          }
        }
      }
    }
  }
}

/*------------------------------------------*/ 
// style for Increment Decrement Component
/*------------------------------------------*/
.quantity {
  button.btn {
    svg {
      fill: ${themeGet('primary.0', '#008489')};
    }
  }
  input[type="number"],
  input[type="number"].qnt-input {
    color: ${themeGet('text.0', '#2C2C2C')};
  }
}

/*------------------------------------------*/ 
// style for mobile Header Component
/*------------------------------------------*/
.mobile-header {
  &.ant-drawer {
    z-index: 10000;
  }
  .ant-drawer-body {
    padding: 0;
    .auth-menu {
      border: 0;
      display: flex;
      margin: 0 0 30px;
      padding: 25px 20px;
      align-items: center;
      background-color: ${themeGet('color.2', '#F7F7F7')};
      li {
        height: auto;
        line-height: 1;
        padding: 0;
        margin: 0;
        &.ant-menu-item-selected,
        &.ant-menu-item-active {
          background-color: transparent;
        }
        a {
          display: inline-flex;
          align-items: center;
          justify-content: center;
          padding: 12px 15px;
          border-radius: 4px;
          font-size: 16px;
          font-weight: 700;
          color: ${themeGet('text.0', '#2C2C2C')};
        }
        &:last-child {
          a {
            color: ${themeGet('color.1', '#ffffff')};
            background-color: ${themeGet('primary.0', '#008489')};
            transition: opacity 0.2s ease;
            &:hover {
              opacity: 0.9;
            }
          }
        }
      }
    }
    .main-menu {
      border: 0;
      padding-top: 30px;
      li {
        padding: 0;
        &.ant-menu-item-selected,
        &.ant-menu-item-active {
          background-color: transparent;
        }
        a {
          font-size: 16px;
          padding: 0 31px;
          border-left: 4px solid transparent;
          color: ${themeGet('text.0', '#2C2C2C')};
          &.active {
            font-weight: 700;
            border-color: ${themeGet('primary.0', '#008489')};
            color: ${themeGet('primary.0', '#008489')};
          }
        }
        button {
          display: block;
          text-align: left;
          width: 100%;
          border: 0;
          padding: 0 32px;
          cursor: pointer;
          background-color: transparent;
          color: ${themeGet('text.0', '#2C2C2C')};
          transition: color 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
          &:hover {
            color: ${themeGet('primary.0', '#008489')};
          }
          &focus {
            outline: 0;
          }
        }
        &:hover {
          a {
            color: ${themeGet('primary.0', '#008489')};
          }
        }
      }
    }
  }
}

/*------------------------------------------*/ 
// style for google map dropdown
/*------------------------------------------*/
.pac-container {
  border: 0;
  z-index: 99999;
  padding: 5px 0;
  margin-top: 0;
  border-radius: 4px;
  box-shadow: 0 15px 46px -10px rgba(26, 26, 29, 0.3);
  @media only screen and (max-width: 991px) {
    margin-top: 0;
  }
  &::after {
    display: none;
  }
  .pac-item {
    border-top: 0;
    line-height: 30px;
    padding: 10px 14px;
    cursor: pointer;
    font-size: 13px;
    color: ${themeGet('text.1', '#909090')};
    .pac-icon {
      margin-top: 6px;
    }
    .pac-item-query {
      font-size: 16px;
      font-weight: 600;
      color: ${themeGet('text.0', '#2C2C2C')};  
      .pac-matched {
        font-weight: 700;
        color: ${themeGet('color.0', '#000000')};
      }
    } 
    &:hover {
      background-color: ${themeGet('color.2', '#F7F7F7')};
    }
  }
}

/*------------------------------------------*/ 
// style for reservation form
/*------------------------------------------*/
.reservation_modal {
  .ant-modal {
    width: 100% !important;
    max-width: 450px;
    padding: 0 15px;
  }
  .ant-modal-content {
    box-shadow: none;
    .ant-modal-body {
      padding: 0;
      position: relative;
      .reservation_sidebar {
        box-shadow: 0 1px 10px rgba(0,0,0,0.16);
        header {
          padding-top: 50px;
          padding-bottom: 20px;
          border-color: ${themeGet('border.0', '#EBEBEB')};
          @media only screen and (max-width: 375px) {
            padding-top: 30px;
          }
        }
        .DateInput__small {
          width: 112px;
        }
        input,
        .DateRangePickerInput {
          padding: 0 9px;
        }
        footer {
          border-color: ${themeGet('border.0', '#EBEBEB')};
        }
      }
      > button.close {
        border: 0;
        padding: 0;
        top: 15px;
        right: 15px;
        height: auto;
        line-height: 1;
        position: absolute;
        font-size: 32px;
        background-color: transparent;
        color: ${themeGet('text.1', '#909090')};
        transition: all 0.3s ease;
        @media only screen and (max-width: 375px) {
          top: 10px;
          right: 10px;
          font-size: 25px;
        }
        &:hover,
        &:focus {
          outline: none;
          color: ${themeGet('text.0', '#2C2C2C')};
        }
        &::after {
          display: none;
        }
      }
    }
  }
}

.reservation_sidebar {
  padding: 0 !important;
  background-color: ${themeGet('color.1', '#ffffff')};
  header {
    margin-bottom: 29px;
    padding: 25px 30px 26px 30px;
    border-bottom: 1px solid ${themeGet('border.0', '#EBEBEB')};
    @media only screen and (max-width: 375px) {
      padding: 25px 15px 26px 15px;
    }
    a {
      &:hover {
        color: #31b8bd;
      }
    }
  }

  p {
    color: ${themeGet('text.0', '#2C2C2C')};
    font-size: 16px;
    font-weight: 400;
    a {
      color: ${themeGet('primary.0', '#008489')};
      font-weight: 700;
      &:hover,
      &:focus {
        outline: 0;
        color: #008489d1;
        text-decoration: underline;
      }
    }
  }

  footer {
    padding: 25px 30px 28px 30px;
    margin-top: 29px;
    border-top: 1px solid ${themeGet('border.0', '#EBEBEB')};
    @media only screen and (max-width: 375px) {
      padding: 20px 15px 25px 15px;
    }

    p {
      margin-bottom: 0;
    }
  }
  
  // channel talk
  #ch-shadow-root-wrapper {
    .launcherIcon {
      @media (max-width: 768px){
      	margin-bottom: 63px;
	  }
    }
  }
  
  @media (max-width: 1200px){}
  @media (max-width: 768px){}
}

.ant-modal {
	&.modal-over-time {
	  .ant-modal-root:has(&) {
		height: 0;
		
		.ant-modal-mask {
		  display: none;
		}
		
		.ant-modal-wrap{
		  top: unset;
		  bottom: 40px;
		  right: unset;
		  left: 40px;
		  overflow: visible;
		  
		  .ant-modal{
			top: unset;
			width: 325px;
			padding: 0;
			
			.ant-modal-content{
			  border-radius: 4px;
              box-shadow: 0 15px 25px 0 rgba(0, 0, 0, 0.35);
			}
			
			.panel_wrapper{
			  min-width: 325px;
			  padding: 24px;
			text-align: left;
			  
			  .panel_header{
				align-items: flex-start;
				padding: 0;
				
				.panel_desc{
				  font-size: 14px;
				  text-align: left;
				}
			  }
			  
			  .panel_footer{
				padding: 0;
				margin-top: 28px;
				
				.ant-row{
				  flex-direction: column;
				  
				  .ant-col{
					max-width: 100%;
					margin: 0;
					
					.ant-btn{
					  height: 40px;
					  padding: 9px 16px;
					  
					  &:not(.ant-btn-background-ghost){
						border-color: ${(props) => props.theme.new.colors.gray200};
						background-color: ${(props) => props.theme.new.colors.white} !important;
						
						span{
                          color: ${(props) => props.theme.new.colors.gray700};
						}
					  }
					  
					  span{
						font-size: 14px;
					  }
					}
				  }
				}
			  }
			}
		  }
		}
	  }
	}
}

.ReactQueryDevtoolsPanel{
	div {
		color: #FFFF;
	}
}
  #overlay {
    margin: 0 auto;
  }
.ellipsis {
overflow: hidden;
text-overflow: ellipsis;
white-space: nowrap;
}

.swiper-scrollbar {
display: none;
}
`
