import { PaginationInput } from '@/lib/pagination'
import { IPagination, otherApiClient } from '.'
import { toQueryStringCamel } from '../utils/misc'
import { Curriculums, SourceType, Subjects } from './product'

export interface PatchAddPassRequest {
	code: string
}

export interface PatchAddPassResponse {
	membershipId: number
	remainCount: number
	remainAmount: number
	startedAt: string
	expiredAt: string
}

export interface GetMembershipsRequest {
	status?: string
	subjects?: Array<Subjects>
	curriculums?: Array<string>
	publishers?: Array<string>
	authors?: []
	productTypes?: []
	productId: string
	productPrice?: number
	membershipId?: number
}

export const ActionType = {
	view3minute: 'view_3minute',
	print: 'print',
} as const
export const ItemType = {
	handout: 'handout',
	studio: 'studioHandout',
} as const
export type ActionType = typeof ActionType[keyof typeof ActionType]
export type ItemType = typeof ItemType[keyof typeof ItemType]

export interface PostMembershipLogRequest {
	itemId: string //sourceId or studioHandoutId
	itemType: ItemType
	membershipId?: string //handout만 필수
	actionType: ActionType
}

export interface MembershipLogResponse {
	message: string
	statusCode: number
}

export const enum PassCategory {
	Solvookplus = 'solvookplus',
	Ticket = 'ticket',
	Subscription = 'solvookplus-subscribe',
}

export const PassCategoryWithString = {
	[PassCategory.Solvookplus]: '쏠북 플러스',
	[PassCategory.Ticket]: '이용권',
} as const

export interface GetPassProductsRequest extends PaginationInput {
	category?: PassCategory
	subjectCode?: Subjects
	id?: number
	hide?: boolean
}

export interface GetPassProductsResponse {
	pagination: IPagination
	data: UseTicketProductInfo[]
}

export interface GeUserPassesRequest {
	status?: 'active' | 'expired'
	subjects?: Array<Subjects>
	curriculums?: Array<Curriculums>
	publishers?: Array<string>
	authors?: Array<string>
	productTypes?: Array<SourceType>
	productId?: string
	productPrice?: number
	membershipId?: string
}

export const ExpiredReasonWithString = {
	COUNT: '사용횟수 소진',
	AMOUNT: '포인트 소진',
	PERIOD: '유효기간 만료',
	REFUND: '환불 만료',
} as const
export type ExpiredReason = keyof typeof ExpiredReasonWithString

export interface GetUserPassesResponse {
	userId: number
	membershipIdBySubject: string
	membershipTicketIdBySubject: string
	solvookpluses: Array<Ticket>
	tickets: Array<Ticket>
}

export interface UseTicketProductInfo {
	id: number
	title: string
	subjectCode: Subjects
	price: number
	originPrice: number
	period?: number
	discountRate: number
	availableSubjects: Array<Subjects>
	availableCurriculums: Array<Curriculums>
	availablePublishers: Array<string>
	availablePublisherNames: Array<string>
	availableAuthors: Array<string>
	availableAuthorNames: Array<string>
	availableCount?: number
	availableAmount?: number
	availableMinPrice?: number
	availableMaxPrice?: number
	unavailablePublisherNames?: Array<string>
	unavailablePublishers?: Array<string>
	unavailableAuthors?: Array<string>
	unavailableAuthorNames?: Array<number>
	category: PassCategory
	categoryTitle: string
	type?: string
	description?: string
	benefits?: Array<string>
	order?: number
}

enum UnUsableReason {
	SUBJECT = '이용가능 과목 조건 불일치',
	PUBLISHER = '이용가능 출판사 조건 불일치',
	CURRICULUM = '이용가능 교육과정 조건 불일치',
	AUTHOR = '이용가능 저자 조건 불일치',
	PRODUCT_TYPE = '이용가능 상품 유형 조건 불일치',
	PRICE = '이용가능 가격 조건 불일치',
}

export interface Ticket {
	membershipId: number
	code: string
	productInfo: UseTicketProductInfo
	remainCount: number
	remainAmount: number | null
	registeredAt: string
	isExpired: boolean
	expiredReason?: ExpiredReason
	remainDay?: number
	startedAt: string
	expiredAt: string
	refundeAt?: string
	usable: boolean
	unUsableReasons?: Array<UnUsableReason>
}

export interface GetMembershipResponse {
	userId: number
	membershipIdBySubject: {}
	membershipTicketIdBySubject: {}
	solvookpluses: Array<Ticket>
	tickets: Array<Ticket>
}

export interface PostMembershipSubscribeRequest {
	membershipProductId: number
}

export interface PostMembershipSubscribeResponse {
	orderId: string
	payUrl: string
}

export const postMembershipLog = async (params: PostMembershipLogRequest) => {
	const { data } = await otherApiClient.market.post<MembershipLogResponse>(
		`/membership-use-logs`,
		{ ...params },
	)
	return data
}
export const getPassProducts = async (params: GetPassProductsRequest) => {
	const { data } = await otherApiClient.market<GetPassProductsResponse>(
		`/memberships/products${toQueryStringCamel(params)}`,
	)
	return data
}
export const getPassOneProduct = async (id: number) => {
	const { data } = await otherApiClient.market<UseTicketProductInfo>(
		`/memberships/products/${id}`,
	)
	return data
}

export const getUserPasses = async (params: GeUserPassesRequest) => {
	const { data } = await otherApiClient.market<GetUserPassesResponse>(
		`/memberships/${toQueryStringCamel(params)}`,
	)
	return data
}

export const getMemberships = async (params: GetMembershipsRequest) => {
	const { data } = await otherApiClient.market.get<GetMembershipResponse>('/memberships', {
		params,
	})
	return data
}
export const patchAddPass = async (param: PatchAddPassRequest) => {
	const { data } = await otherApiClient.market.patch<PatchAddPassResponse>(
		'/memberships/tickets',
		param,
	)
	return data
}
export const postMembershipSubscribe = async (params: PostMembershipSubscribeRequest) => {
	const { data } = await otherApiClient.market.post<PostMembershipSubscribeResponse>(
		`/memberships/subscription`,
		params,
	)
	return data
}

export const getCancelMembership = async () => {
	const { data } = await otherApiClient.market.get<{
		imageUrl: string
	}>('/memberships/subscription/cancel')
	return data
}
