import { numberFormatter } from '@/lib/utils'
import Image from 'next/image'
import { useRouter } from 'next/router'
import { useMemo } from 'react'
import styled from 'styled-components'

import { CouponTag, EditableTag } from '@/components/Tags'
import { mixpanelEvent } from '@/lib/mixpanels/mixpanel'
import { RankingItemDTO } from '../../api/main'
import { useMainPageStore } from '../../store/main'
import RatingWithText from '../common/RatingWithText'
import { usePreviewDialogStore } from '../Search/PreviewDialogProvider'

interface BookInfoWrapperProps {
	positiontype: BookInfoPositionType
}

export type TagType = 'default' | 'primary'
type BookInfoPositionType = 'selectedBook' | 'curation'

interface BookInfoProps {
	book: RankingItemDTO
	index: number
	positionType: BookInfoPositionType
	onClick?: () => void
}

export const BookCard: React.FC<BookInfoProps> = ({ book, index, positionType, onClick }) => {
	const { openPreviewDialog } = usePreviewDialogStore()
	const subject = book?.subject || useMainPageStore((state) => state.subject)
	//과목이 수학일 경우 단원태그만 보여줌
	const isMath = useMemo(() => subject === 'MA', [subject])
	const onClickPreviewBtn = async (e) => {
		openPreviewDialog({ id: book.id.toString() })
		mixpanelEvent('Preview Button Clicked', {
			bookId: book.id,
			bookTitle: book.title,
			Proposition: '쏠북메인',
		})
	}
	const router = useRouter()
	const goToProductDetail = (id) => {
		router.push(`/products/${id}`)
		onClick && onClick()
	}
	return (
		<BookInfoWrapper positiontype={positionType} className={'book-info-wrapper'}>
			<div className="thumb-container">
				<div className={'thumb-wrapper'} onClick={() => goToProductDetail(book.id)}>
					{positionType === 'selectedBook' && (
						<span className="index-label">{index + 1}</span>
					)}
					<Image
						alt={'selected-book-image'}
						width={100}
						height={100}
						src={book.imageUrl}
					/>
					{book?.isEditable && (
						<BookCardEditableTag positionType={positionType}>
							편집가능
						</BookCardEditableTag>
					)}
				</div>
				<div className={'preview-btn'} onClick={() => onClickPreviewBtn(book.id)}>
					샘플 보기
				</div>
			</div>
			<div className={'contents-wrapper'} onClick={() => goToProductDetail(book.id)}>
				<div className={'book-tag-wrapper'}>
					{book.unit && <BookTag tagType={'primary'}>{book.unit}</BookTag>}
					{!isMath && book.category.tag && (
						<BookTag tagType={'default'}>{book.category.tag}</BookTag>
					)}
					{!isMath && book.category.plus > 0 && (
						<BookTag tagType={'default'}>+{book.category.plus}</BookTag>
					)}
				</div>
				<p className={'title'}>{book.title}</p>
				<p
					className={`author ${!book.labName && 'non-clickable'}`}
					onClick={(e) => {
						e.stopPropagation()
						router.push(`/@${book.labName}`)
					}}>
					{book.author}
				</p>
				<p className={'price'}>
					{numberFormatter(book.price)}원
					{book?.isCouponAvailable && <BookCardCouponTag>할인쿠폰</BookCardCouponTag>}
				</p>
				{book.rating && book.rating >= 3 && <RatingWithText rating={book.rating} />}
			</div>
		</BookInfoWrapper>
	)
}

const BookInfoWrapper = styled.div<BookInfoWrapperProps>`
	display: flex;
	flex-direction: ${(props) => (props.positiontype === 'selectedBook' ? 'row' : 'column')};
	position: relative;
	//height: 100%;
	gap: 16px;
	cursor: pointer;

	.book-tag-wrapper {
		display: flex;
		gap: 4px;
	}

	.thumb-container {
		position: relative;
	}

	.thumb-wrapper {
		position: relative;
		width: 136px;
		height: 136px;
		background-color: ${(props) => props.theme.new.colors.sol_gray_0};
		display: flex;
		align-items: center;
		justify-content: center;
		border-radius: 6px;

		:hover {
			transition: all 0.3s ease-in-out;
			background-color: ${(props) => props.theme.new.colors.sol_gray_50};
		}

		img {
			box-shadow: 3px 3px 12px 0px rgba(28, 31, 46, 0.06);
			border-radius: 2px;
			//width: 92px;
		}

		.index-label {
			position: absolute;
			left: 0;
			top: 0;
			background-color: rgba(169, 175, 199, 0.2);
			color: ${(props) => props.theme.new.colors.sol_gray_500};
			text-align: center;
			font-size: 18px;
			font-weight: 900;
			width: 26px;
			height: 26px;
			border-radius: 6px 0;
		}
	}

	.preview-btn {
		position: absolute;
		bottom: 0;
		border-radius: 4px;
		border: 1px solid ${(props) => props.theme.new.colors.sol_gray_200};
		background-color: #fff;
		color: var(--Gray-gray_500, #6a708a);
		font-size: 14px;
		font-weight: 600;
		line-height: 22px;
		/* width: 110px; */
		text-align: center;
		padding: 4px 12px;
		cursor: pointer;

		:hover {
			transition: all 0.3s ease-in-out;
			background-color: ${(props) => props.theme.new.colors.sol_gray_50};
		}
	}

	.contents-wrapper {
		flex: 1;
		align-items: flex-start;
		display: flex;
		flex-direction: column;

		p {
			line-height: 1.5;
			text-align: left;
		}

		.title {
			margin-top: 8px;
			-webkit-line-clamp: 2;
			white-space: normal;
			overflow: hidden;
			text-overflow: ellipsis;
			display: -webkit-box;
			-webkit-box-orient: vertical;
			color: ${(props) => props.theme.new.colors.sol_gray_700};
		}

		.author {
			margin-top: 4px;
			color: ${(props) => props.theme.new.colors.sol_gray_500};
			font-size: 14px;

			&.non-clickable {
				pointer-events: none;
			}

			:hover:not(.non-clickable) {
				text-decoration: underline;
			}
		}

		.price {
			color: ${(props) => props.theme.new.colors.sol_gray_800};
			font-size: 20px;
			font-weight: 700;
			line-height: 26px;
			margin-top: 16px;
			display: flex;
			align-items: center;
		}
	}

	@media (max-width: 768px) {
		.book-tag-wrapper {
			gap: 5px;
		}

		.thumb-wrapper {
			width: 108px;
			height: 108px;
			border-radius: 4px;

			img {
				width: 56px;
			}

			.index-label {
				font-size: 12px;
				width: 18px;
				height: 18px;
				line-height: 1.5;
			}
		}

		.contents-wrapper {
			.title {
				font-size: 14px;
			}

			.author {
				font-size: 14px;
				line-height: 22px;
			}

			.price {
				font-size: 16px;
				margin-top: 8px;

				> span {
				}
			}
		}

		.preview-btn {
			display: none;
		}
	}
`
const BookTag = styled.div<{ tagType: TagType }>`
	background-color: ${(props) =>
		props.tagType === 'primary'
			? props.theme.new.colors.sol_indigo_0
			: props.theme.new.colors.sol_gray_0};
	color: ${(props) =>
		props.tagType === 'primary'
			? props.theme.new.colors.sol_indigo_500
			: props.theme.new.colors.sol_gray_500};
	font-weight: 400;
	padding: 2px 8px;
	border-radius: 4px;
	line-height: 22px;
	font-size: 14px;
	@media (max-width: 768px) {
		font-size: 12px;
		line-height: 22px;
		padding: 2px 6px;
	}
`
const BookCardCouponTag = styled(CouponTag)`
	margin-left: 8px;
`
const BookCardEditableTag = styled(EditableTag)<{
	positionType: BookInfoPositionType
}>`
	bottom: ${(props) => props.positionType === 'selectedBook' && '8px'};
	top: ${(props) => props.positionType === 'curation' && '8px'};
	left: 8px;
`
