import { useRouter } from 'next/router'
import { useCallback, useMemo } from 'react'
import useSearchParams from '@/hooks/useSearchParams'

export interface UsePaginationNew {
	onChangePage: (page: number) => void
	offset: number
	currentPage: number
	totalPage: number
}

export const usePagination = ({
	limit = 24,
	totalCount,
}: {
	limit?: number
	totalCount?: number
}): UsePaginationNew => {
	const { push, query, asPath, pathname } = useRouter()
	const searchParams = useSearchParams()
	const onChangePage = useCallback(
		(page: number) => {
			searchParams.delete('page')
			searchParams.append('page', page.toString())
			push({ pathname, query: searchParams.toString() }, undefined, {
				shallow: true,
				scroll: true,
			})
		},
		[query],
	)
	const offset = useMemo(() => {
		const page = Number(query.page) || 1
		return limit * (page - 1)
	}, [query])
	const currentPage = Number(query.page) || 1
	const totalPage = useMemo(() => {
		return Math.ceil(totalCount / limit)
	}, [totalCount])

	return { onChangePage, offset, currentPage, totalPage }
}
