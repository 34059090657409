import { mixpanelEvent } from '@/lib/mixpanels/mixpanel'
import ArrowDownIcon from '@/static/assets/arrow/ico-arrow-down.svg'
import ArrowRightIcon from '@/static/assets/arrow/ico-arrow-right.svg'
import { useMemo } from 'react'
import usePassStore from 'src/store/pass'
import styled from 'styled-components'
import { Col, ExpandButton, InActiveTitle, InfoSubText, InfoWarningText, RowParents } from '.'
import { useMembershipStore } from './store/passStore'
import usePassList from './usePassList'

const PassNotAvailable = () => {
	const passStatus = useMembershipStore()
	const { unavailable, availablePass } = usePassList()

	const openPassInfoModal = usePassStore((state) => state.openPassInfoModal)

	//2개 이상일 경우 숨김처리
	const notAvailaberCondition = useMemo(() => {
		return availablePass.length > 0 && unavailable.length > 2
	}, [unavailable, availablePass])

	// 0~2 이하일 경우 보여줌
	//unabvailable만 있을때도 보여줘야함.
	const checkShowUnavailable = useMemo(() => {
		if (availablePass.length > 0 && unavailable.length > 0 && unavailable.length < 3) {
			return true
		}
		if (availablePass.length === 0 && unavailable.length > 0) {
			return true
		}
		return false
	}, [unavailable])
	return (
		<>
			{passStatus.isShowNotAvailablePass === false && notAvailaberCondition && (
				<InActived
					role="button"
					onClick={() => {
						passStatus.setState('isShowNotAvailablePass', true)
					}}>
					<Col>
						<InActiveTitle>이용불가 {unavailable.length}개</InActiveTitle>
					</Col>
					<ExpandButton>
						<ArrowDownIcon />
					</ExpandButton>
				</InActived>
			)}
			<DisabledRowBox
				className={`${
					(passStatus.isShowNotAvailablePass || checkShowUnavailable) && 'is-show'
				}`}>
				{unavailable.map((pass, _) => (
					<RowParents
						className="disabled"
						key={pass.membershipId}
						onClick={() => {
							mixpanelEvent('Product Detail Button Clicked', {
								//사용할 수 없는 이용권 상세보기 mx-2
								'Product ID': pass.productInfo.id,
								'Product Categories': pass.productInfo.category,
								'Product Title': pass.productInfo.title,
								'Product Price': pass.productInfo.price,
								'Button UI': 'Arrow',
							})
							openPassInfoModal({
								passInfo: pass.productInfo,
								userPassInfo: pass,
							})
						}}>
						<Col>
							<InActiveTitle>{pass.productInfo.title}</InActiveTitle>
							<InfoSubText>
								<InfoWarningText>사용 조건에 맞지 않는 자료</InfoWarningText>
							</InfoSubText>
						</Col>
						<ExpandButton>
							<ArrowRightIcon />
						</ExpandButton>
					</RowParents>
				))}
			</DisabledRowBox>
		</>
	)
}

export const DisabledRowBox = styled.div`
	display: none;

	&.is-show {
		display: flex;
		flex-direction: column;
		gap: 12px;
		transition: display 0.3s ease-in-out;
	}
`
export const InActived = styled.div`
	display: flex;
	justify-content: space-between;
	padding: 16px;
	border: 1px solid ${(props) => props.theme.new.colors.gray100};
	background-color: #fff;

	border-radius: 6px;

	p {
		color: ${(props) => props.theme.new.colors.gray500};
		font-weight: 400;
	}

	&.close {
		display: none;
		transition: display 0.2s ease-in-out;
	}
`
export default PassNotAvailable
