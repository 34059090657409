import { useBooksQuery } from '@/hooks/useBooksQuery'
import { usePagination } from '@/hooks/usePaginationNew'
import { mixpanelEvent } from '@/lib/mixpanels/mixpanel'
import { numberFormatter } from '@/lib/utils'
import useWindowDimensions from '@/lib/web-ui/useWindowDimensions'
import { EBOOKS_UNIVERSITY_PRODUCT } from '@/settings/constant'
import { Pagination } from '@bookips/solvook-ui-library'
import { Typography, styled, useTheme } from '@mui/material'
import { useQuery } from '@tanstack/react-query'
import { Fragment, useMemo } from 'react'
import { QueryKey, getBooksProducts } from 'src/api/booksProducts'
import { SearchListWrapper } from '../Search/SearchProductListView'
import { SkeletonListItem } from '../Search/SkeletonListItem'
import EbookInfoCard from './EbookInfoCard'
import NotFoundEbook from './NotFoundEbook'

const TABLE_ROW_LENGTH_LIMIT = 50

const UniversityEbookList = () => {
	const theme = useTheme()
	const { isMobile } = useWindowDimensions()
	const { getFilteredQuery, searchParams, keyword, query } = useBooksQuery()

	const { data: universityEbookList, isLoading } = useQuery(
		['universityEbookList', query],
		() =>
			getBooksProducts({
				productType: 'ebookUniv',
				offset: offset,
				limit: TABLE_ROW_LENGTH_LIMIT,
				[QueryKey.KEYWORD]: searchParams.get(QueryKey.KEYWORD),
				[QueryKey.DEPARTMENT]: searchParams.getAll(QueryKey.DEPARTMENT),
				[QueryKey.TYPE]: searchParams.getAll(QueryKey.TYPE),
			}),
		{
			retry: 3,
		},
	)

	const { offset, ...paginationProps } = usePagination({
		limit: TABLE_ROW_LENGTH_LIMIT,
		totalCount: universityEbookList?.pagination.total,
	})

	// 검색을 했을 때만, 마지막 컬럼에 희망교재신청 컴포넌트가 붙습니다.
	const isNotFoundEbook = useMemo(() => {
		return keyword || Object.keys(getFilteredQuery).length > 0
	}, [keyword, getFilteredQuery])

	const countCheckEvenOdd = useMemo(() => {
		return isNotFoundEbook && universityEbookList?.pagination.total % 2 === 0
	}, [universityEbookList?.pagination])

	if (isLoading)
		return (
			<SearchListWrapper>
				{[...Array(10)].map((_, index) => (
					<SkeletonListItem key={index} />
				))}
			</SearchListWrapper>
		)
	return (
		<>
			<EbookList>
				<TypoCount>
					총
					<Typography variant="regular16" color={theme.palette.secondary.main}>
						{` ${numberFormatter(universityEbookList?.pagination.total)}권`}
					</Typography>
				</TypoCount>
				<ListBox isDataTotalEven={countCheckEvenOdd}>
					{universityEbookList.data.map((product, idx) => (
						<Fragment key={`search-list-data-${idx}`}>
							<EbookInfoCard
								{...{
									id: product.id,
									title: product.title,
									author: product.author,
									thumbImage: product.thumbImg,
									price: product.price,
									originPrice: product.originPrice,
									linkUrl: `${EBOOKS_UNIVERSITY_PRODUCT}/${product.id}`,
									isRental: product.isRental,
								}}
							/>
						</Fragment>
					))}
					{/* 키워드와 필터값이 있고, 그 리스트중 마지막 페이지에만 붙여주기 위한 조건 */}
					{isNotFoundEbook &&
						paginationProps.currentPage === paginationProps.totalPage && (
							<NotFoundEbook />
						)}
					{/* 아얘 검색어와 필터 없을때 (조건을 분리하였습니다.)*/}
					{paginationProps.totalPage === 0 && <NotFoundEbook />}
				</ListBox>
			</EbookList>
			<Pagination
				size={isMobile ? 'medium' : 'large'}
				page={paginationProps.currentPage}
				count={paginationProps.totalPage || 1}
				onChange={(e, page) => {
					paginationProps.onChangePage(page)
					//mx-146 페이지네이션 클릭시
					mixpanelEvent('Pagination UI Clicked', {
						Position: '굿노트 대학교재 검색결과 리스트',
						'page 클릭 시': page,
					})
				}}
			/>
		</>
	)
}

const EbookList = styled('section')(({ theme }) => ({
	width: '100%',
	backgroundColor: 'none',
	margin: '16px 0 32px 0',

	'@media (max-width: 1200px)': {},

	[theme.breakpoints.down('sm')]: {
		backgroundColor: theme.palette.blackAndWhite.white,
		margin: '0 0 20px 0',
	},
}))

const TypoCount = styled(Typography)(({ theme }) => ({
	...theme.typography.regular16,
	color: theme.palette.text.secondary,

	[theme.breakpoints.down('sm')]: {
		width: '100%',
		height: '100%',
		display: 'block',
		padding: '16px 20px 8px 20px',
	},
}))
const ListBox = styled('ul')<{ isDataTotalEven: boolean }>(({ isDataTotalEven, theme }) => ({
	// display: 'flex',
	// flexWrap: 'wrap',
	display: 'grid',
	gridTemplateColumns: '1fr 1fr',

	boxSizing: 'border-box',
	backgroundColor: theme.palette.blackAndWhite.white,

	border: `1px solid ${theme.palette.grey[50]}`,
	margin: '16px 0 0 0',

	'._not_found': {
		gridColumn: isDataTotalEven ? '1 / span 2' : 'auto',
		borderLeft: isDataTotalEven ? 'none' : `1px solid ${theme.palette.grey[50]}`,

		[theme.breakpoints.down('sm')]: {
			gridColumn: 1,
		},
	},

	[theme.breakpoints.down('sm')]: {
		margin: 0,
		gridTemplateColumns: '1fr',
	},
}))
export default UniversityEbookList
