import { priceFormatter } from '@/lib/utils'
import { Typography, styled, useTheme } from '@mui/material'
import Image from 'next/image'
import Link from 'next/link'
import { PropsWithChildren } from 'react'

export interface CardInfoItem {
	id: number
	thumbImage: string
	title: string
	author: string
	price: number
	originPrice: number
	linkUrl: string
	isRental?: boolean
}

const EbookInfoCard = ({
	id,
	thumbImage,
	title,
	author,
	price,
	originPrice,
	linkUrl,
	isRental,
	children,
}: PropsWithChildren<CardInfoItem>) => {
	const theme = useTheme()

	return (
		<CardBox>
			<Link href={linkUrl}>
				<ImgFigure>
					<Image src={thumbImage} width={348} height={459} alt="ebook image thumbnail" />
				</ImgFigure>
				<InfoBox>
					<RowText>
						{isRental ? (
							<Typography className={`_is rental`} variant="subtitle2">
								12개월 대여
							</Typography>
						) : (
							<Typography className={`_is permanent`} variant="subtitle2">
								영구소장
							</Typography>
						)}
						<ItemTitle variant="subtitle1">{title}</ItemTitle>
						<ItemTextOneLine variant="body2" color={theme.palette.text.secondary}>
							{author}
						</ItemTextOneLine>
					</RowText>
					<RowPrice>
						<Typography variant="h6">{`${priceFormatter(price)}원`}</Typography>
						{originPrice !== price && (
							<Typography variant="regular14" color={theme.palette.text.disabled}>
								{`${priceFormatter(originPrice)}원`}
							</Typography>
						)}
					</RowPrice>
				</InfoBox>
			</Link>
			{children}
		</CardBox>
	)
}

const CardBox = styled('li')(({ theme }) => ({
	// width: '50%',
	height: '200px',
	padding: '24px',

	a: {
		width: '100%',
		height: '100%',
		display: 'flex',
		gap: '20px',
	},

	'&:nth-of-type(odd)': {
		borderBottom: `1px solid ${theme.palette.grey[50]}`,
	},
	'&:nth-of-type(even)': {
		borderLeft: `1px solid ${theme.palette.grey[50]}`,
		borderBottom: `1px solid ${theme.palette.grey[50]}`,
	},
	'@media (max-width: 1200px)': {},

	[theme.breakpoints.down('sm')]: {
		width: '100%',
		height: '100%',
		padding: '20px',
	},
}))

const ImgFigure = styled('figure')(({ theme }) => ({
	width: '116px',
	height: '100%',
	position: 'relative',
	// backgroundColor: theme.palette.grey[100],
	flexShrink: 0,

	img: {
		width: '100%',
		height: '100%',
		border: `1px solid ${theme.palette.grey[50]}`,

		objectFit: 'cover',
	},

	[theme.breakpoints.down('sm')]: {
		width: '78px',
		height: '102px',
	},
}))

const InfoBox = styled('ol')(({ theme }) => ({
	padding: 0,
	display: 'flex',
	flexDirection: 'column',
	justifyContent: 'space-between',

	margin: 0,

	[theme.breakpoints.down('sm')]: {
		overflow: 'hidden',
		gap: '16px',
	},
}))
const RowText = styled('li')(({ theme }) => ({
	display: 'flex',
	flexDirection: 'column',
	gap: '6px',

	'._is': {
		width: 'fit-content',
		display: 'block',
		borderRadius: '4px',

		padding: '2px 8px',
	},
	'.rental': {
		color: '#326EE5',
		backgroundColor: theme.palette.blue[0],
	},
	'.permanent': {
		color: theme.palette.mint[500],
		backgroundColor: theme.palette.mint[0],
	},
}))
const RowPrice = styled('li')(({ theme }) => ({
	display: 'flex',
	gap: '8px',
	alignItems: 'center',
	span: {
		textDecoration: 'line-through',
	},
}))

const ItemTitle = styled(Typography)(({ theme }) => ({
	wordBreak: 'break-word',
	textOverflow: 'ellipsis',
	overflow: 'hidden',
	display: '-webkit-box',
	'-webkit-line-clamp': '2',
	'-webkit-box-orient': 'vertical',
}))

const ItemTextOneLine = styled(Typography)(({ theme }) => ({
	// whiteSpace: 'nowrap',
	// overflow: 'hidden',
	// textOverflow: 'ellipsis',

	wordBreak: 'break-word',
	textOverflow: 'ellipsis',
	overflow: 'hidden',
	display: '-webkit-box',
	'-webkit-line-clamp': '1',
	'-webkit-box-orient': 'vertical',
}))
export default EbookInfoCard
