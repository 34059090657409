import { GuidTooltip } from '@/components/tooltip/GuidTooltip'
import useLoginRedirect, { REDIRECT_QUERY } from '@/hooks/useLoginRedirect'
import { mixpanelEvent } from '@/lib/mixpanels/mixpanel'
import { REGISTRATION_PAGE } from '@/settings/constant'
import { Button } from '@bookips/solvook-ui-library'
import { useTheme } from '@mui/material'
import { useRouter } from 'next/router'
import { useState } from 'react'
import { LogoutMenuWrapper } from './GNB.style'

interface LogoutMenuProps {
	onClick?: () => void
}

const LogoutMenu: React.FC<LogoutMenuProps> = () => {
	const router = useRouter()
	const theme = useTheme()
	const currentPathname = router.asPath
	const [isVisibleTooltip, setIsVisibleTooltip] = useState(false)
	const { redirectToLogin } = useLoginRedirect()

	return (
		<LogoutMenuWrapper className={'gnb-logout-menu'}>
			<GuidTooltip isVisibleTooltip={isVisibleTooltip} placement="right">
				지금 가입하면 50% 할인!
			</GuidTooltip>
			<Button
				variant="strokeSecondary"
				label="로그인"
				sx={{ color: theme.palette.text.primary }}
				onClick={() => {
					redirectToLogin()
					mixpanelEvent('GNB Button Clicked', {
						//mx-44 gnb 버튼 클릭 시
						'Button Name': '로그인',
					})
				}}
			/>

			<Button
				variant="soft"
				onClick={() => {
					router.push({
						pathname: `${REGISTRATION_PAGE}`,
						query: currentPathname.length > 1 && { [REDIRECT_QUERY]: currentPathname },
					})
					mixpanelEvent('GNB Button Clicked', {
						//mx-44 gnb 버튼 클릭 시
						'Button Name': '회원가입',
					})
				}}
				label="회원가입"
			/>
		</LogoutMenuWrapper>
	)
}

export default LogoutMenu
