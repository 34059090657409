import { ScrollBody } from '@/components/Coupons/AdditionalCouponContents'
import SimpleDialog from '@/components/Coupons/SimpleDialog'
import AnchorDrawer from '@/components/modal/AnchorDrawer'
import { numberFormatter } from '@/lib/utils'
import useWindowDimensions from '@/lib/web-ui/useWindowDimensions'
import { Divider, Typography } from '@mui/material'
import { PropsWithChildren } from 'react'
import { UseTicketProductInfo } from 'src/api/membership'
import { SourceTypeWithString } from 'src/api/product'
import { formatDateToDot } from 'src/utils/formatting'
import styled, { useTheme } from 'styled-components'
import { SubscribeInformationProps, usePassInfoFormatting } from '../hooks/usePassInfoFormatting'

interface DialogProps {
	open: boolean
	onClose: () => void
	title?: string
	subParagraph?: string
	footer?: React.ReactNode
	contentInfo?: SubscribeInformationProps
}
export const SubscribeDialog = ({
	open,
	onClose,
	footer,
	contentInfo,
	children,
}: PropsWithChildren<DialogProps>) => {
	//데이터에 의존할 수 있는 구조가 좋을지, 아니면 사용처에서 그려주는 구조가 좋을지..?

	const { isMobile } = useWindowDimensions()

	if (!contentInfo) return null

	if (isMobile)
		return (
			<AnchorDrawer anchor={'bottom'} open={open} onClose={() => onClose()}>
				<AnchorDrawer.Contents>
					<AnchorDrawer.Title>{contentInfo.title}</AnchorDrawer.Title>
					<InfoHeader subscribeInfo={contentInfo} />
					<ScrollBody>
						<InfoBody subscribeInfo={contentInfo} />
					</ScrollBody>
				</AnchorDrawer.Contents>
				{footer}
			</AnchorDrawer>
		)
	return (
		<SimpleDialog
			open={open}
			onClose={onClose}
			scroll="paper"
			title={contentInfo.title}
			subParagraph={<InfoHeader subscribeInfo={contentInfo} />}
			footer={footer}>
			<Divider />
			<InfoBody subscribeInfo={contentInfo} />
		</SimpleDialog>
	)
}
const InfoHeader = ({ subscribeInfo }: { subscribeInfo: SubscribeInformationProps }) => {
	const theme = useTheme()
	return (
		<SubParagraphBox>
			<Typography variant="regular16" sx={{ color: theme.new.colors.sol_gray_500 }}>
				총 사용가능 횟수: 조건 내 무제한 사용
			</Typography>
			<Typography variant="regular16" sx={{ color: theme.new.colors.sol_gray_500 }}>
				사용 기간: 구독 해지시까지 사용가능
			</Typography>
			{
				// 정기결제 다음 결제일, 결제 예정 금액
				subscribeInfo.expiredAt && subscribeInfo.price && (
					<>
						<Typography
							variant="regular16"
							sx={{ color: theme.new.colors.sol_gray_500 }}>
							{`다음 결제일 : ${formatDateToDot(subscribeInfo.expiredAt)}`}
						</Typography>
						<Typography
							variant="regular16"
							sx={{ color: theme.new.colors.sol_gray_500 }}>
							{`결제 예정 금액: ${numberFormatter(subscribeInfo.price)}원`}
						</Typography>
					</>
				)
			}
		</SubParagraphBox>
	)
}
const InfoBody = ({ subscribeInfo }: { subscribeInfo: UseTicketProductInfo }) => {
	const dataFormatting = usePassInfoFormatting(subscribeInfo)
	return (
		<ContentBody>
			<ContentGridBox>
				<Typography variant="semibold16">이용권 사용범위</Typography>
				<GridRow>
					<PrefixText variant="regular16">과목</PrefixText>
					<Typography variant="regular16">{dataFormatting.subjectList}</Typography>
				</GridRow>
				<GridRow>
					<PrefixText variant="regular16">과정</PrefixText>
					<Typography variant="regular16">{dataFormatting.curriculumList}</Typography>
				</GridRow>
				{dataFormatting.hasUnavailableAuthor ? (
					<GridRow>
						<PrefixText variant="regular16">제외 저자</PrefixText>
						<Typography variant="regular16">
							{dataFormatting.unavailableAuthorList}
						</Typography>
					</GridRow>
				) : (
					<GridRow>
						<PrefixText variant="regular16">저자</PrefixText>
						<Typography variant="regular16">{dataFormatting.authorList}</Typography>
					</GridRow>
				)}

				{dataFormatting.hasUnavailablePublisher ? (
					<GridRow>
						<PrefixText variant="regular16">제외 출판사</PrefixText>
						<Typography variant="regular16">
							{dataFormatting.unavailablePublisherList}
						</Typography>
					</GridRow>
				) : (
					<GridRow>
						<PrefixText variant="regular16">출판사</PrefixText>
						<Typography variant="regular16">{dataFormatting.publisherList}</Typography>
					</GridRow>
				)}
				<GridRow>
					<PrefixText variant="regular16">가격</PrefixText>
					<Typography variant="regular16">{`${numberFormatter(
						dataFormatting.availableMinPrice,
					)}원~
								${numberFormatter(dataFormatting.availableMaxPrice)}원 사이의 수업자료`}</Typography>
				</GridRow>
				<GridRow>
					<PrefixText variant="regular16">상품종류</PrefixText>
					<Typography variant="regular16">{SourceTypeWithString['handout']}</Typography>
				</GridRow>
			</ContentGridBox>
			<Divider />

			<ContentGridBox>
				<Typography variant="semibold16">이용권 상세정보</Typography>
				<ul>
					{dataFormatting.descriptionList.map((des, idx) => (
						<li key={'description-' + idx}>
							<Typography variant="regular14">{des}</Typography>
						</li>
					))}
				</ul>
			</ContentGridBox>
		</ContentBody>
	)
}

const SubParagraphBox = styled.div`
	width: 100%;
	display: flex;
	flex-direction: column;

	span.MuiTypography-root {
		padding: 0;
	}
`

const ContentBody = styled.div`
	span.MuiTypography-root {
		padding: 0;
	}

	.MuiDialogContent-root {
		padding: 0 28px;
	}
`
const ContentGridBox = styled.div`
	display: flex;
	flex-direction: column;
	gap: 8px;

	padding: 28px 0;

	li {
		list-style: initial;
		margin-inline-start: 24px;
		color: ${(props) => props.theme.new.colors.sol_gray_500};
	}
`

const GridRow = styled.div`
	display: grid;
	grid-template-columns: 1fr 3.5fr;
`
const PrefixText = styled(Typography)`
	color: ${({ theme }) => theme.new.colors.sol_gray_300};
`
