import { useAuthStore } from '@/lib/auth/AuthStore'
import { useCallback, useEffect } from 'react'
import { getAccessToken } from 'src/api'
import { UserInfo, getMe } from 'src/api/getMe'

const useAuth = () => {
	const { isInitializing, authenticate } = useAuthStore()

	const load = useCallback(async () => {
		const token = getAccessToken()
		let result

		try {
			if (token) {
				result = await getMe(token)
			}
		} catch (err) {
			console.error(err)
		}

		// console.log(result?.user)

		authenticate(result?.user as UserInfo)
	}, [authenticate])

	useEffect(() => {
		if (isInitializing) {
			load()
		}
	}, [isInitializing, load])

	return { reloadAuth: load }
}

export default useAuth
