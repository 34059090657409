import * as React from 'react'
import { FC, PointerEventHandler, useCallback, useRef, useState } from 'react'
import Paper from '@mui/material/Paper'
import InputBase from '@mui/material/InputBase'
import Divider from '@mui/material/Divider'
import IconButton from '@mui/material/IconButton'
import Icon from '@/components/icon/Icon'
import { Close, Search, Sizes } from '@/components/icon/IconList'
import useWindowDimensions from '@/lib/web-ui/useWindowDimensions'
import { Portal } from '@mui/material'
import useSnackbar, { defaultSnackbarProps, IconWithSnackbarMessage, Snackbar } from '../Snackbar'

interface TextFieldProps {
	onClickForm?: (e: React.MouseEvent<HTMLElement, MouseEvent>) => void
	onSubmit?: (e: React.FormEvent<HTMLFormElement>) => void
	placeholder?: string
	name?: string
	value?: string
	onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void
	onBlur?: () => void
	onClear?: () => void
	onSearchClick?: (e: React.MouseEvent<HTMLElement, MouseEvent>) => void
	autoFocus?: boolean
	readOnly?: boolean
}

interface InputRef {
	element: HTMLInputElement | HTMLTextAreaElement
	focusTimeout: NodeJS.Timeout | null
}

export const SearchTextField: FC<TextFieldProps> = ({
	onClickForm,
	onSubmit,
	placeholder,
	onBlur,
	onClear,
	value,
	onChange,
	onSearchClick,
	autoFocus,
	...props
}) => {
	const { isMobile } = useWindowDimensions()
	const { isShowSnackbar, hideSnackbar, showSnackbar } = useSnackbar()
	const [focused, setFocused] = useState(false)
	const inputRef = useRef<InputRef>({
		element: null,
		focusTimeout: null,
	})
	const handleFocus = useCallback(() => {
		clearTimeout(inputRef.current.focusTimeout)
		setFocused(true)
	}, [])

	const handleBlur = useCallback(() => {
		inputRef.current.focusTimeout = setTimeout(() => {
			setFocused(false)
		}, 100)

		if (onBlur) {
			onBlur()
		}
	}, [onBlur])

	const handleClear = useCallback<PointerEventHandler<HTMLButtonElement>>(() => {
		if (onClear) {
			onClear()
		}
		if (inputRef.current.element) {
			inputRef.current.element.focus()
		}
	}, [onClear])
	const handleOnChange = useCallback(
		(e: React.ChangeEvent<HTMLInputElement>) => {
			if (onChange) {
				if (e.target.value.trim().length === 0) {
					showSnackbar()
					return
				}
				onChange(e)
			}
		},
		[onChange],
	)
	const handleOnSubmit = useCallback(
		(e: React.FormEvent<HTMLFormElement>) => {
			e.preventDefault()
			if (onSubmit) {
				if (value.trim().length === 0) {
					showSnackbar()
					return
				}
				onSubmit(e)
			}
		},
		[onSubmit, value],
	)
	const handleOnSearchClick = useCallback(
		(e: React.MouseEvent<HTMLElement, MouseEvent>) => {
			e.preventDefault()
			if (onSearchClick) {
				if (value.trim().length === 0) {
					showSnackbar()
					return
				}
				onSearchClick(e)
			}
		},
		[onSearchClick, value],
	)
	return (
		<Paper
			onClick={onClickForm}
			onSubmit={handleOnSubmit}
			onFocus={handleFocus}
			onBlur={handleBlur}
			component="form"
			className={'search-request-form '.concat(focused || value?.length ? 'active' : '')}
			sx={(theme) => {
				return {
					p: '12px 12px 12px 20px ',
					display: 'flex',
					alignItems: 'center',
					borderRadius: '24px',
					height: '48px',
					backgroundColor: 'rgba(169, 175, 199, 0.2)',
					'&.active': {
						backgroundColor: '#fff',
						border: `1px solid ${theme.palette.grey[700]}`,
						transition: 'all 0.2s',
					},
					input: {
						p: 0,
						...theme.typography.body1,
					},
				}
			}}>
			<InputBase
				{...props}
				onChange={onChange}
				autoFocus={autoFocus}
				ref={inputRef}
				value={value}
				autoComplete={'off'}
				sx={{ flexGrow: 1 }}
				placeholder={placeholder}
			/>
			{value?.length > 0 && (
				<>
					<IconButton
						type={'button'}
						onClick={handleClear}
						color="primary"
						sx={(theme) => {
							return {
								p: '8px',
								i: {
									backgroundColor: theme.palette.grey[800],
									borderRadius: '50%',
									padding: '2px',
								},
							}
						}}>
						<Icon
							icon={Close}
							size={isMobile ? Sizes.xxsmall : Sizes.xsmall}
							color={'#fff'}
						/>
					</IconButton>

					<Divider sx={{ height: 12, m: 1 }} orientation="vertical" />
				</>
			)}

			<IconButton onClick={handleOnSearchClick} sx={{ p: '8px' }} type={'submit'}>
				<Icon icon={Search} size={isMobile ? Sizes.small : Sizes.xmedium} />
			</IconButton>
			<Portal>
				<Snackbar
					{...defaultSnackbarProps}
					open={isShowSnackbar}
					onClose={hideSnackbar}
					autoHideDuration={2000}
					message={<IconWithSnackbarMessage text={'검색어를 1글자 이상 입력해주세요.'} />}
				/>
			</Portal>
		</Paper>
	)
}
