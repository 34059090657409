import { PayMethod } from '@/lib/iamport'
import dayjs from 'dayjs'
import { create } from 'zustand'
import { CurriculumsWithString, SubjectsWithString } from '../api/product'
import { PassCategory, Ticket, UseTicketProductInfo } from '../api/membership'
import { mixpanelEvent } from '@/lib/mixpanels/mixpanel'

export interface IMappedPassProduct {
	id: number
	subjectList: string
	periodDescription: string
	curriculumList: string
	authorList: string
	publisherList: string
	descriptionList: string[]
	title?: string
	period?: number
	remainDay?: number
	remainCount?: number
	expiredAt?: string
	startedAt?: string
	availableMinPrice?: number
	availableMaxPrice?: number
	availableCount?: number
	isSolvookplus: boolean
	isNoPriceLimit: boolean
	isNoCountLimit: boolean
	hasUnavailablePublisher: boolean
	hasUnavailableAuthor: boolean
	unavailableAuthorList: string
	unavailablePublisherList: string
}

export interface PassState {
	isPassInfoModal: boolean
	selectedPass: IMappedPassProduct
	purchasePass: UseTicketProductInfo
	passPayMethod: PayMethod
}

export interface PassActions {
	closePassInfoModal: () => void
	openPassInfoModal: (pass: { passInfo: UseTicketProductInfo; userPassInfo?: Ticket }) => void
	setPurchasePass: (pass: UseTicketProductInfo) => void
	setPassPayMethod: (payMethod: PayMethod) => void
}

const usePassStore = create<PassState & PassActions>((set) => ({
	isPassInfoModal: false,
	selectedPass: null,
	purchasePass: null,
	passPayMethod: 'card',
	setPassPayMethod: (payMethod) => set({ passPayMethod: payMethod }),
	setPurchasePass: (pass) => set({ purchasePass: pass }),
	closePassInfoModal: () => {
		set({ isPassInfoModal: false })
		set({ selectedPass: null })
	},
	openPassInfoModal: (pass) => {
		// pass를 맵핑
		const { passInfo } = pass
		const subjectList = passInfo.availableSubjects
			.map((item) => SubjectsWithString[item])
			.join(', ')
		const periodDescription = () => {
			return `${passInfo.period}일`
		}
		const curriculumList = passInfo.availableCurriculums
			.map((item) => CurriculumsWithString[item])
			.join(', ')
		const hasUnavailableAuthor = !!passInfo.unavailableAuthorNames
		const authorList = hasUnavailableAuthor ? '' : passInfo.availableAuthorNames.join(', ')
		const unavailableAuthorList = hasUnavailableAuthor
			? passInfo.unavailableAuthorNames.join(', ')
			: ''
		const hasUnavailablePublisher = !!passInfo.unavailablePublisherNames
		const publisherList = hasUnavailablePublisher
			? ''
			: passInfo.availablePublisherNames.join(', ')

		const unavailablePublisherList = hasUnavailablePublisher
			? passInfo.unavailablePublisherNames.join(', ')
			: ''
		const descriptionList = passInfo.description ? passInfo.description.split('\n') : []
		const isSolvookplus = passInfo.category === PassCategory.Solvookplus
		const expiredAt = () => {
			if (!pass.userPassInfo) return null
			return dayjs(pass.userPassInfo.expiredAt).format('YYYY.MM.DD HH:mm:ss')
		}
		const startedAt = () => {
			if (!pass.userPassInfo) return null
			return dayjs(pass.userPassInfo.startedAt).format('YYYY.MM.DD')
		}
		const mappedPass = {
			id: passInfo.id,
			title: passInfo.title,
			period: passInfo.period,
			remainDay: pass.userPassInfo ? pass.userPassInfo.remainDay : null,
			expiredAt: expiredAt(),
			startedAt: startedAt(),
			availableMinPrice: passInfo.availableMinPrice,
			availableMaxPrice: passInfo.availableMaxPrice,
			availableCount: passInfo.availableCount,
			subjectList,
			periodDescription: periodDescription(),
			curriculumList,
			authorList,
			publisherList,
			descriptionList,
			remainCount: pass.userPassInfo ? pass.userPassInfo.remainCount : null,
			isSolvookplus,
			isNoPriceLimit: !!!passInfo.availableMaxPrice,
			isNoCountLimit: passInfo.availableCount === 999999 || !!!passInfo.availableCount,
			hasUnavailablePublisher,
			hasUnavailableAuthor,
			unavailableAuthorList,
			unavailablePublisherList,
		}
		set({ selectedPass: mappedPass })
		set({ isPassInfoModal: true })
		mixpanelEvent('Product Detail Popup Viewed', {
			'Product Categories': 'pass',
			'Product ID': passInfo.id,
			'Product Title': passInfo.title,
			'Product Price': passInfo.price,
		})
	},
}))
export default usePassStore
