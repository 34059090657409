import { styled } from '@mui/material/styles'
import React from 'react'
import Link from 'next/link'
import { Stack } from '@mui/material'
import { Button } from '@bookips/solvook-ui-library'
import Image from '@/components/common/Image'
import ShadowSlideContainer from '../common/ShadowSlideContainer'
import { CouponTag } from '../Tags'
import { usePreviewDialogStore } from './PreviewDialogProvider'
import { SearchRatingWithText } from '../common/RatingWithText'
import { mixpanelEvent } from '@/lib/mixpanels/mixpanel'
import { ChipItem } from './Chips'

interface SearchListItemContainerProps {
	href: string
	coreInfoSection: React.ReactNode
	subInfoSection?: React.ReactNode
	onClick?: (e: React.MouseEvent<HTMLAnchorElement>) => void
}

export const SearchListItemContainer = ({
	href,
	coreInfoSection,
	subInfoSection,
	onClick,
}: SearchListItemContainerProps) => {
	return (
		<SearchListItemLI className={'search-list-item-li'}>
			<Link href={href} onClick={onClick}>
				<section>{coreInfoSection}</section>
				{subInfoSection && <section>{subInfoSection}</section>}
			</Link>
		</SearchListItemLI>
	)
}

interface CoreInfoSectionProps {
	thumbImg: string
	productId: number
	questionNo?: number
	avgReviewRating?: number
	price: string
	title: string
	author: string
	isCouponAvailable?: boolean
	editable?: boolean
}

export const CoreInfoSection = ({
	thumbImg,
	productId,
	questionNo,
	avgReviewRating,
	price,
	title,
	author,
	isCouponAvailable,
	editable,
}: CoreInfoSectionProps) => {
	const { openPreviewDialog } = usePreviewDialogStore()
	const onClickPreviewButton = (e) => {
		e.preventDefault()
		e.stopPropagation()
		openPreviewDialog({
			id: productId.toString(),
		})
		mixpanelEvent('Preview Button Clicked', {
			bookId: productId,
			bookTitle: title,
			Proposition: '검색페이지',
		})
	}
	return (
		<CoreInfoStack>
			<ThumbArea
				src={thumbImg}
				width={184}
				height={260}
				previewButton={true}
				onClickPreviewButton={onClickPreviewButton}
			/>
			<InfoArea
				infoTop={
					<>
						<ChipsContainer className={'chips-container'}>
							{questionNo > 0 && (
								<ChipItem bgColor={'#F0F4FC'} color={'#326EE5'}>
									{questionNo}
									문항
								</ChipItem>
							)}
							{editable && (
								<ChipItem bgColor={'#F0F7F5'} color={'#29876E'}>
									편집가능
								</ChipItem>
							)}
							{avgReviewRating && avgReviewRating >= 3 && (
								<ChipItem bgColor={'#F7F8FA'} color={''}>
									<SearchRatingWithText rating={avgReviewRating} />
								</ChipItem>
							)}
						</ChipsContainer>
						<Title className={'title'}>{title}</Title>
						<Author className={'author'}>{author}</Author>
					</>
				}
				infoBottom={
					<>
						<Price>{price}</Price>
						{isCouponAvailable && <CouponTag>쿠폰</CouponTag>}
					</>
				}
			/>
		</CoreInfoStack>
	)
}

interface SubInfoSectionProps {
	relatedBook?: string
	unit?: string
	dataTypes?: string
}

export const SubInfoSection = ({ relatedBook, unit, dataTypes }: SubInfoSectionProps) => {
	return (
		<SubInfoStack>
			{relatedBook && <SubInfoItem>{relatedBook}</SubInfoItem>}
			{unit && <SubInfoItem>{unit}</SubInfoItem>}
			{dataTypes && <SubInfoItem>{dataTypes}</SubInfoItem>}
		</SubInfoStack>
	)
}
export const SubInfoStack = ({ children }) => {
	return (
		<SubInfoContainer
			onClick={(e) => {
				//스크롤 영역으로 클릭이벤트 전파 방지
				e.preventDefault()
				e.stopPropagation()
			}}>
			<ShadowSlideContainer>{children}</ShadowSlideContainer>
		</SubInfoContainer>
	)
}

interface ThumbAreaProps {
	src: string
	width: number
	height: number
	onClickPreviewButton?: (e: React.MouseEvent<HTMLButtonElement>) => void
	previewButton?: boolean
}

export const ThumbArea = ({ src, width, height, onClickPreviewButton, previewButton }) => {
	return (
		<ThumbContainer>
			<div className={'thumb-img-container'}>
				<Image src={src} width={width} height={height} alt={'thumb-img'} />
			</div>
			{previewButton && (
				<Button
					variant={'strokeSecondary'}
					sx={(theme) => {
						return {
							padding: '6px 12px',
							...theme.typography.subtitle2,
						}
					}}
					onClick={onClickPreviewButton}>
					샘플 보기
				</Button>
			)}
		</ThumbContainer>
	)
}

interface InfoAreaProps {
	infoTop: React.ReactNode
	infoBottom: React.ReactNode
}

export const InfoArea = ({ infoTop, infoBottom }) => {
	return (
		<Stack
			sx={(theme) => {
				return {
					justifyContent: 'space-between',
					[theme.breakpoints.down('sm')]: {
						gap: '16px',
					},
				}
			}}
			direction={'column'}>
			<Stack
				className={'into-top'}
				sx={{
					'.chip': {
						marginTop: '8px',
					},
					'.title + .author': {
						marginTop: '4px',
					},
				}}
				direction={'column'}>
				{infoTop}
			</Stack>
			<Stack
				className={'info-bottom'}
				direction={'row'}
				spacing={'6px'}
				alignItems={'center'}>
				{infoBottom}
			</Stack>
		</Stack>
	)
}

const SearchListItemLI = styled('li')`
	position: relative;
	width: 100%;
	background-color: #fff;
	min-height: 201px;
	border-radius: 6px;
	box-shadow: rgba(0, 0, 0, 0.08) 0px 1px 2px 0px, rgba(0, 0, 0, 0.04) 0px 3px 3px 0px;

	a {
		display: flex;
		flex-direction: column;
		height: 100%;
	}

	@media (max-width: 768px) {
		border-radius: 0;
		border-bottom: 1px solid #e1e4ed;
	}
`

const CoreInfoStack = styled(Stack)(({ theme }) => {
	return {
		padding: '20px',
		gap: '20px',
		flexDirection: 'row',
		[theme.breakpoints.down('sm')]: {
			paddingBottom: '16px',
			gap: '16px',
		},
	}
})

const ThumbContainer = styled(Stack)`
	gap: 6px;

	& .thumb-img-container {
		height: 130px;
		width: 92px;
		max-width: none;
		border-radius: 4px;
		overflow: hidden;
		display: flex;
		box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.08), 0px 0px 1px 0px rgba(0, 0, 0, 0.04);

		img {
			object-fit: contain;
			margin: 0 auto;
			max-width: 100%;
			display: block;
			max-height: 100%;
		}
	}

	@media (max-width: 768px) {
		& .thumb-img-container {
			height: 96px;
			width: 68px;
		}

		button {
			display: none;
		}
	}
`

export const Title = styled('p')(({ theme }) => {
	return {
		color: theme.palette.text.title,
		textOverflow: 'ellipsis',
		overflow: 'hidden',
		display: '-webkit-box',
		WebkitLineClamp: '2',
		WebkitBoxOrient: 'vertical',
		...theme.typography.subtitle1,
	}
})
export const Author = styled('p')(({ theme }) => {
	return {
		color: theme.palette.text.secondary,
		...theme.typography.body1,
	}
})
export const Price = styled('p')(({ theme }) => {
	return {
		color: theme.palette.text.primary,
		...theme.typography.h5,
		[theme.breakpoints.down('sm')]: {
			...theme.typography.h6,
		},
	}
})
export const ChipsContainer = styled('div')`
	display: flex;
	gap: 6px;
`

export const SubInfoContainer = styled(Stack)(({ theme }) => {
	return {
		borderRadius: '0 0 6px 6px',
		backgroundColor: theme.palette.grey[0],
		flexDirection: 'row',
		overflowX: 'auto',
		'span + span': {
			'::before': {
				content: '"|"',
				color: theme.palette.grey[100],
				margin: '0 8px',
			},
		},
		'& ::-webkit-scrollbar': {
			display: 'none',
		},
		'>div >div': {
			padding: '12px 20px',
			display: 'flex',
			height: '44px',
		},
		[theme.breakpoints.down('sm')]: {
			margin: '0 20px 20px 20px',
			borderRadius: '4px',
			'>div >div': {
				padding: '8px 12px',
				height: '36px',
			},
		},
	}
})
export const SubInfoItem = styled('span')(({ theme }) => {
	return {
		color: theme.palette.text.secondary,
		whiteSpace: 'nowrap',
		...theme.typography.body2,
	}
})
