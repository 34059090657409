import Link from 'next/link'
import React from 'react'
import styled from 'styled-components'
import { PASSES_LANDING } from '@/settings/constant'
import Icon from '@/components/icon/Icon'
import { Bookmark, Sizes } from '@/components/icon/IconList'

export interface MembershipStatusLinkProps {
	children?: React.ReactNode
	onClick?: (e: React.MouseEvent<HTMLAnchorElement>) => void
}

export const PromotionLink: React.FC<MembershipStatusLinkProps> = ({ children, onClick }) => {
	//FIXME 멤버십 오픈 전까지 도서 홍보링크로 사용
	return (
		<MembershipStatusLinkWrapper href={PASSES_LANDING} onClick={onClick}>
			<Icon icon={Bookmark} size={Sizes.xmedium} />
			<p>이용권 구매</p>
			{children}
		</MembershipStatusLinkWrapper>
	)
}
export const MembershipStatusLinkWrapper = styled(Link)`
	font-size: 14px;
	display: flex;
	font-weight: 600;
	align-items: center;
	line-height: 22px;
	margin-bottom: 12px;
	padding: 4px 16px;
	background-color: ${(props) => props.theme.new.colors.sol_indigo_50};
	border-radius: 58px;
	color: ${(props) => props.theme.new.colors.sol_indigo_500};

	i {
		margin-right: 2px;
	}

	p {
		color: ${(props) => props.theme.new.colors.sol_indigo_500};
	}

	:hover {
		transition: background-color 0.3s ease-in-out;
		background-color: ${(props) => props.theme.new.colors.sol_indigo_200};
	}

	@media (max-width: 768px) {
		display: flex;
		justify-content: space-between;
		border-radius: 6px;
		margin-bottom: 24px;
		padding: 9px 12px;
	}
`
