import { userLogout } from '@/lib/mixpanels/mixpanel'
import { AuthUserType, Claims, OpenID } from '@bookips/solvook-api-shared'
import * as Sentry from '@sentry/react'
import Cookies from 'js-cookie'
import { observer, useLocalObservable } from 'mobx-react-lite'
import React, { createContext, useContext } from 'react'
import { ReactChannelIO } from 'react-channel-plugin'
import { cookieOptions } from 'src/api'
import { isInWebview } from 'src/utils/misc'
import { UserInfo } from '../../api/getMe'
import useAuth from '@/hooks/useAuth'

export interface AuthInfo {
	initialized: boolean
	isInitializing?: boolean
	userType: AuthUserType
	verifyMethod?: 'idToken' | 'session'
	user?: UserInfo
	// userInfo?: UserRecord
	// isAnonymous: boolean
}

export interface AuthUser extends OpenID {
	[key: string]: string | number | Claims
}

const createAuthStore = (initialValues: AuthInfo) => {
	return {
		user: initialValues.user || ({} as UserInfo),
		initialized: initialValues.initialized || false,
		isInitializing: initialValues.isInitializing || true,
		userType: initialValues.userType || 'user',
		verifyMethod: initialValues.verifyMethod || 'session',
		// updateUserFromToken: (decodedIdToken: UserInfo) => {
		//     this.user = decodedIdToken
		// },

		authenticate(user?: UserInfo) {
			if (user) {
				this.updateUser(user)
			} else {
				this.user = {}
			}

			this.isInitializing = false
		},
		updateUser(user: UserInfo) {
			this.user = user
			Sentry.configureScope((scope) => {
				scope.setUser({
					id: user.uid,
					email: user?.email,
					username: user?.displayName,
				})
			})
		},
		async logout() {
			userLogout()
			this.user = {} as UserInfo
			Cookies.remove('accessToken', { ...cookieOptions })
			Sentry.configureScope((scope) => scope.setUser(null))
		},
		get isLoggedIn(): boolean {
			return !!this.user?.uid
		},
		get currentUser(): UserInfo {
			return this.user
		},
		redirectUrlAfterLogout(): string {
			return '/'
		},
	}
}

export type AuthStoreType = ReturnType<typeof createAuthStore>

export const AuthContext = createContext<AuthStoreType | null>(null)

export const AuthProvider: React.FC<{
	children: React.ReactNode
	initialValues: AuthInfo
}> = ({
	children,
	initialValues = {
		initialized: false,
		isInitializing: true,
		userType: 'unknown',
	},
}) => {
	const store = useLocalObservable<AuthStoreType>(() => createAuthStore(initialValues))
	return (
		<AuthContext.Provider value={store}>
			<ChannelIOWrapper>{children}</ChannelIOWrapper>
		</AuthContext.Provider>
	)
}

const ChannelIOWrapper: React.FC<{
	children: React.ReactNode
}> = observer(({ children }) => {
	const CHANNEL_ID_PLUGIN_KEY = process.env.NEXT_PUBLIC_CHANNEL_ID_PLUGIN_KEY
	const CHANNEL_USE_AUTO_BOOT =
		!isInWebview() && process.env.NEXT_PUBLIC_CHANNEL_USE_AUTOBOOT === 'true'
	const { user, isLoggedIn, isInitializing } = useAuthStore()
	useAuth()

	return !isInitializing ? (
		<ReactChannelIO
			pluginKey={CHANNEL_ID_PLUGIN_KEY}
			language="ko"
			autoBoot={CHANNEL_USE_AUTO_BOOT}
			{...(isLoggedIn &&
				user?.id && {
					memberId: `${user.id}`,
					profile: { name: user.displayName },
				})}>
			{children}
		</ReactChannelIO>
	) : null
})

export const useAuthStore = () => {
	const store = useContext(AuthContext)
	if (!store) {
		// this is especially useful in TypeScript so you don't need to be checking for null all the time
		throw new Error('useStore must be used within a StoreProvider.')
	}
	return store
}
